<template>
  <v-container fluid class="pa-0">
    <v-row fill-height class="row">
      <v-col cols="12" sm="6" md="6" class="common">
        <v-card outlined flat class="logIn-card common">
          <v-icon v-if="!isMobile" class="my-6">
            <img
              src="/favicon.ico"
              :alt="enums.titles.PRODUCT"
              class="custom-icon"
            />
          </v-icon>
          <h3 class="pb-8">{{ title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="restorePassword()"
          >
            <v-row>
              <v-col cols="12" class="pb-0 logIn-input">
                <v-text-field
                  label="Contraseña nueva"
                  id="new-password"
                  autocomplete="new-password"
                  :type="showNewPassword ? 'text' : 'password'"
                  v-model.trim="newPassword"
                  :rules="rules.password.concat(rules.whiteSpaceNotAllowed)"
                  tabindex="1"
                  ref="textFieldNueva"
                  variant="outlined"
                  density="compact"
                  class="label-align"
                >
                  <v-icon
                    class="eye-icon"
                    @click="showNewPassword = !showNewPassword"
                    id="v-icon-eye-show-password"
                    tabindex="-1"
                  >
                    {{ showNewPassword ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </v-text-field>
              </v-col>
              <!-- Repetir contraseña -->
              <v-col cols="12" class="pb-0 logIn-input">
                <v-text-field
                  label="Repetir contraseña nueva"
                  ref="textFieldRepeat"
                  :type="showRepeatPassword ? 'text' : 'password'"
                  required
                  v-model.trim="repeatPassword"
                  id="new-password2"
                  tabindex="2"
                  autocomplete="new-password"
                  variant="outlined"
                  density="compact"
                  class="label-align"
                  :rules="
                    newPassword != null
                      ? rules.required.concat([
                          rules.samePassword(newPassword, repeatPassword),
                        ])
                      : rules.required
                  "
                >
                  <v-icon
                    class="eye-icon"
                    @click="showRepeatPassword = !showRepeatPassword"
                    id="v-icon-eye-show-password"
                    tabindex="-1"
                  >
                    {{ showRepeatPassword ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="btn-row common">
              <v-col cols="6" sm="6" md="6" class="py-0">
                <v-btn
                  :disabled="!valid || isLoading"
                  :loading="isLoading"
                  type="submit"
                  color="primary"
                  tabindex="4"
                  id="entrar-v-btn"
                  class="getIn-btn"
                >
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <p class="mt-4">
          {{ enums.titles.PRODUCT }} un producto de
          <a href="https://www.macena.com.ar/" target="_blank" color="primary">
            Macena
          </a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "ForgottedPassword",
  data() {
    return {
      userId: null,
      valid: false,
      isLoading: false,
      title: "Restaurar contraseña",
      enums: enums,
      rules: rules,
      newPassword: null,
      repeatPassword: null,
      showNewPassword: false,
      showRepeatPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  created() {
    this.userId = this.$route.query.userId;
    this.$router.replace({ path: "/restaurar-contrasena" }); // Reemplazo la path para que no aparezca el parámetro en la ruta
  },
  methods: {
    ...mapActions({
      changePassword: "password/changePassword",
      setAlert: "user/setAlert",
    }),
    async restorePassword() {
      this.isLoading = true;
      const data = {
        userId: this.userId,
        newPassword: this.newPassword,
      };
      try {
        const response = await this.changePassword(data);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Contraseña restaurada con éxito.",
          });
          this.$router.push("/login");
        }
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.custom-icon {
  width: 65px;
  height: 65px;
  filter: grayscale(100%);
}
.custom-logo {
  width: 150px;
  height: 150px;
}
.logo {
  font-size: 90px;
}
.row {
  height: 102vh;
  justify-content: center;
}
.common {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.left-column {
  color: white;
  padding-top: 30px;
}
.logIn-card {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 40px 40px 30px;
}
.logIn-input {
  padding: 0px;
  width: 150px;
  margin-bottom: 10px;
}
.forgottedPassword-input {
  padding: 0px;
  width: 100px;
  margin-bottom: 10px;
}
.btn-row {
  margin-bottom: 2px;
}
.getIn-btn {
  min-width: 110px;
  height: 40px;
  padding: 2px;
}
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.centered-alert {
  width: auto;
  text-align: center;
  padding: 10px;
}

.label-align {
  text-align: left;
}
</style>
