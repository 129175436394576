<template>
    <v-card>
      <v-container class="pb-0">
        <v-card-title class="pt-0 pl-1">
          <span class="text-h5"
            > {{ title }} </span
          >
        </v-card-title>
        <v-card-text class="pb-0">{{ subtitle }}</v-card-text>
        <v-card-actions class="pb-0 px-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="cancelConfirm"
            size="small"
            id="v-btn-cancel-unsaved-changes"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            variant="flat"
            @click="confirm"
            size="small"
            :disabled="isLoading"
            :loading="isLoading"
            id="v-btn-acept-unsaved-changes"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </template>
  
  <script>
  import enums from "@/utils/enums/index";
  
  export default {
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        enums: enums,
        title: enums.messages.UNSAVED_CHANGES_TITLE,
        subtitle: enums.messages.UNSAVED_CHANGES_SUBTITLE,
      };
    },
    methods: {
      cancelConfirm() {
        this.$emit("confirmar-cancelado");
      },
      confirm() {
        this.$emit("confirmar");
      },
    },
  };
  </script>