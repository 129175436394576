<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>
    <v-card-text class="text-left" v-if="!passwordRestored">
      {{ text }}
    </v-card-text>

    <!-- Nueva contraseña -->
    <v-card-text class="text-center" v-if="passwordRestored">
      <v-card-subtitle v-if="passwordRestored" class="justify-center">
        {{ sendNewPassword }}
      </v-card-subtitle>

      <v-tooltip location="left">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="elevation-2 my-3"
            ref="x"
            @click="copyToClipboard()"
            append-icon="mdi-content-copy"
            color="primary"
            variant="text"
            id="btn-copy"
          >
            {{ newPassword }}
          </v-btn>
        </template>
        Copiar nueva contraseña
      </v-tooltip>
      <v-card-subtitle v-if="passwordRestored" class="justify-center">
        {{ recommendChangePassword }}
      </v-card-subtitle>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        variant="text"
        color="primary"
        @click="closeModal(false)"
        id="btn-close"
      >
        {{ passwordRestored ? "Cerrar" : "Cancelar" }}
      </v-btn>
      <v-btn
        variant="flat"
        color="primary"
        v-if="!passwordRestored"
        @click="confirmRestorePassword()"
        :loading="isLoading"
        id="btn-confirm"
        >Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index";

export default {
  name: "RestaurarContraseña",
  props: {
    userId: { type: Number, required: true },
  },
  data() {
    return {
      newPassword: [],
      passwordRestored: false,
      isLoading: false,
      text: enums.messages.GENERATE_PASSWORD,
      sendNewPassword: enums.messages.SEND_NEW_PASSWORD,
      recommendChangePassword: enums.messages.RECOMMEND_CHANGE_PASSWORD,
      title: enums.titles.RESTAURAR_CONTRASEÑA,
      copyIcon: enums.icons.COPY_ICON,
    };
  },
  created() {
  },
  methods: {
    ...mapActions({
      restorePassword: "user/restorePassword",
      setAlert: "user/setAlert",
    }),
    async confirmRestorePassword() {
      this.isLoading = true;
      try {
        const response = await this.restorePassword(this.userId);
        if (response.status === 200) {
          this.title = "Contraseña restaurada";
          this.passwordRestored = true;
          this.setAlert({
            type: "success",
            message: "Contraseña restaurada con éxito.",
          });
          this.newPassword = response.data;
        }
      } catch (error) {
        console.error("Error al restaurar contraseña.", error);
      }
      this.isLoading = false;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.newPassword);
      this.setAlert({
        type: "success",
        message: "Contraseña copiada.",
      });
    },
    closeModal(reload) {
      this.$emit("closeDialog", reload);
    },
    confirm() {
      this.$emit("restorePassword");
    },
  },
};
</script>

<style></style>
