<template>
  <v-container class="mobile-container">
    <v-card>
      <v-card-title class="px-4 mt-2">
        <v-row>
          <v-col cols="10" class="pb-0">
            <v-card-title
              class="px-0 text-h5"
              :class="isMobile ? 'mt-3' : 'mt-2'"
            >
              Estados - Prescripción Nº {{ solicitudItem.numero }}
            </v-card-title>
          </v-col>
          <v-col cols="2" class="pb-0">
            <v-card-actions class="pb-0 px-0">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                variant="text"
                :icon="closeIcon"
                @click="closeDialog"
                id="v-btn-close-estado-solicitud"
              >
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="elevation-1 ma-3">
        <v-card-text class="py-0 my-0">
          <v-row>
            <v-col cols="12" class="mx-0 px-0">
              <v-data-table-virtual
                v-if="isMobile"
                :headers="headers"
                :items="estadoSolicitud"
                :mobile="isMobile"
                :hide-default-header="isMobile"
                item-value="name"
                :height="estadoSolicitud?.length > 1 ? 400 : null"
                class="pa-3"
                :items-per-page="isMobile ? 1 : 10"
                :loading="isLoading"
                ><template v-slot:top> </template>
              </v-data-table-virtual>
              <v-data-table
                :headers="headers"
                :items="estadoSolicitud"
                v-else
                :hide-default-header="isMobile"
                item-value="name"
                class="pa-3"
                :items-per-page="isMobile ? 1 : 10"
                :loading="isLoading"
                ><template v-slot:top> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index";

export default {
  props: {
    solicitudItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      estadoSolicitud: [],
      enums: enums,
      closeIcon: enums.icons.CLOSE_ICON,
      isLoading: false,
      headers: [
        {
          title: "Estado",
          sortable: false,
          key: "estado",
        },
        {
          title: "Fecha inicio",
          sortable: false,
          key: "fechaInicio",
        },
        {
          title: "Fecha fin",
          sortable: false,
          key: "fechaFin",
        },
        {
          title: "Usuario",
          sortable: false,
          key: "usuario",
        },
      ],
    };
  },
  created() {
    this.loadEstadoSolicitud();
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      getEstadoSolicitud: "solicitud/getEstadoSolicitud",
      setAlert: "user/setAlert",
    }),
    async loadEstadoSolicitud() {
      this.isLoading = true;
      const solicitudId = this.solicitudItem.id;
      try {
        const response = await this.getEstadoSolicitud(solicitudId);
        this.estadoSolicitud = response;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
