const messages = Object.freeze({
  ADD_SUCCESS: "Registro agregado correctamente",
  UPDATE_SUCCESS: "Registro actualizado correctamente",
  DELETE_SUCCESS: "Registro eliminado correctamente",
  DELETE_TEXT: "¿Eliminar este registro?",
  ADD_ERROR: "Error en la creacion",
  UPDATE_ERROR: "Error al actualizar",
  DELETE_ERROR: "Error al eliminar",
  SAVE_CONFIRM: "¿Desea guardar los datos?",
  RETURN_CONFIRM: "¿Desea volver atrás?",
  RETURN_HOME_CONFIRM: "¿Desea volver a la página principal?",
  SYSTEM_ERROR: "Se produjo un error. Intente nuevamente.",
  ACCESS_DENIED: "Acceso denegado.",
  FARMACIAS_DELETE_SUBTITLE: "¿Desea eliminar la farmacia?",
  EMISORES_DELETE_SUBTITLE: "¿Desea eliminar el emisor?",
  FINANCIADORES_DELETE_SUBTITLE: "¿Desea eliminar el financiador?",
  USUARIOS_DELETE_SUBTITLE: "¿Desea eliminar el usuario?",
  GRUPOS_DE_USUARIOS_DELETE_SUBTITLE: "¿Desea eliminar el grupo de usuario?",
  DELETE_DIALOG_SUBTITLE: "¿Desea eliminar este registro?",
  DELETE_DIALOG_TITLE: "Eliminar",
  ANULAR_SOLICITUD_MESSAGE: "¿Desea anular la prescripción?",
  PAGE_NOT_FOUND: "Página no encontrada",
  GO_TO_HOME: "Ir al inicio",
  GENERATE_PASSWORD: "Se generará una contraseña aleatoria.",
  SEND_NEW_PASSWORD: "Copiar contraseña y mandarla al usuario correspondiente.",
  RECOMMEND_CHANGE_PASSWORD:
    "Recomiende cambiar la contraseña al iniciar sesión.",
  AFILIADOS_NOT_FOUND: "No se encontraron afiliados",
  ENTER_MINIMUM_CARACTERS_SEARCH: "Ingrese DNI o Nº afiliado",
  DATOS_ENVIO_MESSAGE: "Datos de envío",
  UNSAVED_CHANGES_TITLE: "Cambios sin guardar",
  UNSAVED_CHANGES_SUBTITLE: "Si confirma se perderán los cambios no guardados.",
  USUARIOS_EMISORES_DELETE_SUBTITLE: "¿Desea eliminar el usuario emisor?",
  CONFIRM_DISPENSA: "¿Desea dispensar el medicamento?",
});

export default messages;
