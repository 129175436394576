import helperDate from "@/utils/helpers/dateTimePicker";

const rules = {
  required: [(v) => !!v || "Campo requerido"],
  password: [
    (v) =>
      (!!v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(v)) ||
      "Min. 8 caracteres con al menos una mayúscula y un número",
  ],
  whiteSpaceNotAllowed: (v) => {
    let tieneEspacios = v.indexOf(" ") > 0;
    return !tieneEspacios || "No se permiten espacios en blanco";
  },
  samePassword: (firstPassword, secondPassword) => {
    return firstPassword == secondPassword || "Las contraseñas no coinciden";
  },
  maxLength: (value, limit) => {
    return (value) =>
      !value || `${value}`.length <= limit || `Máximo ${limit} caracteres`;
  },
  minLength: (value, limit) => {
    return (value) =>
      !value || `${value}`.length >= limit || `Mínimo ${limit} caracteres`;
  },
  numeric: (v) => {
    return !v || /^\d*$/.test(v) || "Solo se permiten números";
  },
  validUrl: [
    (v) => {
      const pattern = /^(https?:\/\/)[\da-z.-]+(:\d+)?(\/[^\s]*)?$/;
      return pattern.test(v) || "Ingrese una URL válida";
    },
  ],
  emailRules: [
    (v) =>
      !v ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      "Email debe ser válido",
  ],
  nameRules: [
    (v) =>
      !v ||
      /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s']+$/.test(v) ||
      "Nombre contiene caracteres no válidos",
  ],
  nameRulesWithNumber: [
    (v) =>
      !v ||
      /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0123456789\s']+$/.test(v) ||
      "Nombre contiene caracteres no válidos",
  ],
  telephoneRules: [
    (v) =>
      !v || /^[0-9]+$/.test(v) || "Teléfono  contiene caracteres no válidos.",
  ],
  validDateFormat: [
    (v) => {
      const pattern =
        /^((((0?[1-9]|1\d|2[0-8])\/(0?[1-9]|1[0-2]))|((29|30)\/(0?[13-9]|1[0-2]))|(31\/(0?[13578]|1[02])))\/(19|[2-9]\d)\d{2})$|^(29\/0?2\/(19|[2-9]\d)(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96))$/;
      return pattern.test(v) || "Formato de fecha no válido";
    },
  ],
  dateSelectedInRange: (date, minDate, maxDate) => {
    const dateFormatted = new Date(date?.setHours(0, 0, 0, 0));
    const minDateFormatted = new Date(minDate);
    const maxDateFormatted = new Date(maxDate);
    if (date && minDate && maxDate) {
      return (
        (dateFormatted >= minDateFormatted &&
          dateFormatted <= maxDateFormatted) ||
        "Rango no válido"
      );
    }

    return true;
  },
  validDateRange: (fechaDesde, fechaHasta) => {
    const selectedDateDesde = new Date(
      helperDate.convertDateFormat(fechaDesde)
    );
    const selectedDateHasta = new Date(
      helperDate.convertDateFormat(fechaHasta)
    );

    if (fechaDesde && fechaHasta) {
      return (
        new Date(selectedDateDesde) <= new Date(selectedDateHasta) ||
        "Rango no válido"
      );
    }

    return true;
  },
  validNumberRule: [
    (v) =>
      !v ||
      /^(?:[1-9]\d{0,2}|0)$/.test(v) ||
      "Ingrese un número válido entre 0 y 999",
  ],
  validNumberRule99: [
    (v) =>
      !v ||
      /^(?:[1-9]|[1-9]\d)$/.test(v) ||
      "Ingrese un número válido entre 1 y 99",
  ],
  validateCUITCUIL: (cuit) => {
    if (cuit.length < 12) return "CUIT/CUIL no válido";
    let genero = cuit.split("-")[0];
    let cuitNro = cuit.replaceAll("-", "").split("").map(Number);
    const verificador = cuitNro.pop();
    const codes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

    let multiplicados = cuitNro.map((item, index) => item * codes[index]);
    let sumaTotal = multiplicados.reduce((a, b) => a + b, 0);
    let resto = sumaTotal % 11;
    let resultado;

    if (resto == 0) {
      resultado = 0;
    } else if (resto == 1) {
      if (genero == 20) {
        resultado = 9;
      } else if (genero == 27) {
        resultado = 4;
      } else {
        return "CUIT/CUIL no válido";
      }
    } else {
      resultado = 11 - resto;
    }

    return resultado == verificador || "CUIT/CUIL no válido";
  },
};

export default rules;
