<template>
  <v-container class="py-0 my-0">
    <PageHeader :title="pageTitle" :path="goToPath" />
    <v-row class="px-3">
      <v-col :cols="isMobile ? 12 : 8" class="py-0 pl-0" :class="isMobile ? 'pr-0' : ''">
        <v-select
          label="Grupos de usuarios"
          v-model="selectedGrupo"
          variant="outlined"
          density="compact"
          @update:model-value="loadPermisos()"
          :items="gruposList"
          return-object
          item-value="id"
          item-title="value"
          clearable
          id="v-select-gruposList"
        ></v-select>
      </v-col>
      <v-col :cols="isMobile ? 10 : 2" class="pt-0" :class="isMobile ? 'pl-0' : ''">
        <v-btn
          color="primary"
          variant="flat"
          id="v-btn-actualizar"
          :loading="isSavingActualizar"
          :disabled="!selectedGrupo || isLoadingItems"
          class="mr-2"
          @click="savePermisosGrupo"
          >Guardar</v-btn
        >
      </v-col>
      <v-col :cols="isMobile ? 2 : 2" class="pt-0 pr-0" :class="isMobile ? 'pl-0' : ''">
        <v-btn
          color="primary"
          variant="flat"
          class="to-right"
          id="v-btn-nuevo"
          @click="openEditDialog()"
          >Nuevo
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="ml-0 pl-0">
      <PermisoList
        :allPermisos="permisosList"
        :isLoadingItems="isLoadingItems"
        :disableCheck="!selectedGrupo"
        @emitSeleccionarPermiso="seleccionarPermiso"
        @emitOpenEditDialog="openEditDialog"
        @emitOpenDeleteDialog="openDeleteDialog"
      />
    </v-card>

    <v-dialog :max-width="isMobile ? '95%' : '30%'" v-model="dialogDelete" persistent>
      <DeleteDialog
        v-if="dialogDelete"
        title="permiso"
        :isLoading="isLoadingDelete"
        :subtitle="deleteSubtitle"
        @eliminar-confirmado="borrarPermiso"
        @eliminar-cancelado="closeDeleteDialog"
      />
    </v-dialog>
    <v-dialog :max-width="isMobile ? '95%' : '50%'" v-model="editPermisoDialog" persistent>
      <EditPermisos
        :permisoItem="permisoItem"
        :permisosListItems="permisosListItems"
        @closeDialog="closeEditDialog"
        @reloadPermisos="loadPermisos()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import PermisoList from "@/components/modules/permisos/PermisoList.vue";
import EditPermisos from "@/components/modules/permisos/EditPermisos.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "permisos-page",
  components: {
    PageHeader,
    PermisoList,
    DeleteDialog,
    EditPermisos,
  },
  data() {
    return {
      pageTitle: enums.permises.PERMISOS.name,
      goToPath: enums.permises.HOME.path,
      permisosList: [],
      selectedGrupo: null,
      gruposList: [],
      editPermisoDialog: false,
      dialogDelete: false,
      deleteSubtitle: '',
      idToDelete: null,
      isLoadingDelete: false,
      isLoadingItems: false,
      isSavingActualizar: false,
      permisosListItems: [],
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile"
    }),
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
    this.setGrupos();
  },
  methods: {
    ...mapActions({
      getPermisos: "permisos/getPermisos",
      getGruposUsuarios: "taxonomy/getGruposUsuarios",
      deletePermiso: "permisos/deletePermiso",
      savePermisosGrupos: "permisos/savePermisosGrupos",
      setAlert: "user/setAlert",
    }),
    seleccionarPermiso(permisoSeleccionado) {
      permisoSeleccionado.permisoIsFromUserGroup =
        !permisoSeleccionado.permisoIsFromUserGroup;
      if (permisoSeleccionado.permisoPadreId === null)
        this.actualizarHijos(
          permisoSeleccionado,
          permisoSeleccionado.permisoIsFromUserGroup
        );
      else if (
        permisoSeleccionado.permisoPadreId !== null &&
        permisoSeleccionado.permisoIsFromUserGroup == true
      ) {
        this.actualizarPadres(
          permisoSeleccionado,
          permisoSeleccionado.permisoIsFromUserGroup
        );
      }
    },
    actualizarHijos(permiso, valor) {
      permiso.permisoHijos.forEach((hijo) => {
        hijo.permisoIsFromUserGroup = valor;
        if (hijo.permisoHijos.length > 0) {
          this.actualizarHijos(hijo, valor);
        }
      });
    },
    listAllPermisos(permisos) {
      permisos.forEach((item) => {
        this.permisosListItems.push(item);
        if (item.permisoHijos.length > 0) {
          this.listAllPermisos(item.permisoHijos);
        }
      });
      return this.permisosListItems;
    },
    actualizarPadres(permiso, valor) {
      const padre = this.permisosListItems.find(
        (x) => permiso.permisoPadreId === x.permisoId
      );
      padre.permisoIsFromUserGroup = valor;
      if (padre.permisoPadreId !== null) this.actualizarPadres(padre, valor);
    },
    async setGrupos() {
      await this.loadCombo();
      if (this.gruposList?.length > 0) {
        this.selectedGrupo = this.gruposList[0];
        this.loadPermisos();
      }
    },
    async loadCombo() {
      this.gruposList = await this.getGruposUsuarios();
    },
    async loadPermisos() {
      if (!this.selectedGrupo) return;
      try {
        this.isLoadingItems = true;
        this.permisosList = await this.getPermisos(this.selectedGrupo.id);
        this.permisosListItems = [];
        this.listAllPermisos(this.permisosList);
      } catch (error) {
        console.error(error);
      }
      this.isLoadingItems = false;
    },

    openDeleteDialog(id, nombre) {
      this.deleteSubtitle = `¿Desea eliminar el permiso "${nombre}"?`
      this.dialogDelete = true;
      this.idToDelete = id;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    async borrarPermiso() {
      this.isLoadingDelete = true;
      try {
        const resp = await this.deletePermiso(this.idToDelete);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Permiso eliminado con éxito ",
          });
          this.dialogDelete = false;
          this.loadPermisos();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
    buscarPermisosSeleccionados(permisos) {
      let permisosIds = [];
      permisos.forEach((permiso) => {
        if (permiso.permisoIsFromUserGroup) {
          permisosIds.push(permiso.permisoId);
        }
        if (permiso.permisoHijos.length > 0) {
          permisosIds = permisosIds.concat(
            this.buscarPermisosSeleccionados(permiso.permisoHijos)
          );
        }
      });
      return permisosIds;
    },
    async savePermisosGrupo() {
      this.isSavingActualizar = true;
      let permisosIds = [];
      permisosIds = this.buscarPermisosSeleccionados(this.permisosList);
      const payload = {
        PermisosId: permisosIds,
        grupoUsuId: this.selectedGrupo.id,
      };

      try {
        const resp = await this.savePermisosGrupos(payload);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito ",
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.isSavingActualizar = false;
    },
    openEditDialog(item) {
      this.editPermisoDialog = true;
      this.permisoItem = item;
    },
    closeEditDialog() {
      this.editPermisoDialog = false;
      this.loadPermisos();
    },
  },
};
</script>

<style scoped></style>
