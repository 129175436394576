<template>
  <v-container class="pb-0">
    <PageHeader :title="title" :path="goToPath" />
    <v-card class="elevation-1">
      <v-card-text class="py-0 my-0">
        <v-row>
          <v-col cols="12" class="px-0 mx-0">
            <v-data-table
              :headers="usuariosEmisoresHeaders"
              :mobile="isMobile"
              :hide-default-header="isMobile"
              class="pa-3"
              :items="usuariosEmisoresItems"
              :search="search"
              :loading="dataTableIsLoading"
            >
              <template v-slot:[`item.deshabilitado`]="{ item }">
                <v-icon small :color="item.deshabilitado ? 'red' : 'green'">
                  {{ item.deshabilitado ? crossIcon : checkIcon }}
                </v-icon>
              </template>
              <template v-slot:top>
                <v-toolbar flat style="background-color: transparent">
                  <v-row>
                    <v-col
                      cols="9"
                      lg="8"
                      sm="7"
                      align-self="center"
                      class="buscador pl-0"
                    >
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                        variant="underlined"
                        clearable
                        class="ml-5"
                        style="width: auto"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col
                      class="d-flex align-center"
                      :class="isMobile ? 'mt-4' : ''"
                      style="justify-content: end"
                      cols="2"
                      lg="2"
                      md="2"
                      sm="3"
                    >
                      <v-btn
                        color="primary"
                        variant="flat"
                        @click="openModalEditUsuarioEmisor()"
                      >
                        Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="changeUserStatus(item)"
                      id="v-icon-edit-state"
                    >
                      {{
                        item.deshabilitado ? enableUserIcon : disableUserIcon
                      }}
                    </v-icon>
                  </template>
                  <span>{{
                    item.deshabilitado
                      ? "Habilitar usuario"
                      : "Deshabilitar usuario"
                  }}</span>
                </v-tooltip>
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="openModalEditUsuarioEmisor(item)"
                      id="v-icon-edit-medico-emisor"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar usuario financiador</span>
                </v-tooltip>
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openDeleteDialog(item)"
                      v-bind="props"
                      id="v-icon-delete-medico-emisor"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar usuario emisor</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
  <v-dialog
    v-if="editUsuarioEmisorModal"
    v-model="editUsuarioEmisorModal"
    :max-width="isMobile ? '95%' : '55%'"
    persistent
  >
    <EditUsuarioEmisor
      :tiposPrestadorItems="tiposPrestadorItems"
      :tiposDocItems="tiposDocItems"
      :usuarioEmisorItem="usuarioEmisorItem"
      :financiadorId="financiadorId"
      @closeDialog="closeDialog($event)"
    />
  </v-dialog>
  <v-dialog
    :max-width="isMobile ? '95%' : '50%'"
    v-model="dialogDelete"
    persistent
  >
    <DeleteDialog
      v-if="dialogDelete"
      title="usuarios emisores"
      :subtitle="deleteSubtitle"
      :isLoading="isLoadingDelete"
      @eliminar-confirmado="confirmDelete"
      @eliminar-cancelado="closeDeleteDialog"
    />
  </v-dialog>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layout/PageHeader.vue";
import EditUsuarioEmisor from "@/components/modules/financiadores/EditUsuarioEmisor.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";

export default {
  name: "GestionUsuariosEmisores",
  components: {
    EditUsuarioEmisor,
    PageHeader,
    DeleteDialog,
  },
  data() {
    return {
      financiadorId: null,
      deleteSubtitle: enums.messages.USUARIOS_EMISORES_DELETE_SUBTITLE,
      usuariosEmisoresItems: [],
      search: "",
      searchIcon: enums.icons.SEARCH_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      checkIcon: enums.icons.CHECK_ICON,
      crossIcon: enums.icons.CROSS_ICON,
      disableUserIcon: enums.icons.DISABLE_USER_ICON,
      enableUserIcon: enums.icons.ENABLE_USER_ICON,
      dataTableIsLoading: false,
      title: `Gestión de médicos emisores`,
      editIcon: enums.icons.EDIT_ICON,
      goToPath: "/financiadores",
      usuariosEmisoresHeaders: [
        {
          title: "Nombre de usuario",
          sortable: false,
          key: "usuarioNombre",
        },
        {
          title: "Apellido/s y nombre/s",
          sortable: false,
          key: "preNomApe",
        },
        {
          title: "Tipo documento",
          sortable: false,
          key: "preTipoDoc.value1",
        },
        {
          title: "N° documento",
          align: "end",
          sortable: false,
          key: "preNroDoc",
        },
        {
          title: "CUIT",
          sortable: false,
          key: "preCuit",
        },
        {
          title: "Habilitado",
          sortable: false,
          key: "deshabilitado",
          align: "center",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
      usuarioEmisorItem: null,
      editUsuarioEmisorModal: false,
      tiposPrestadorItems: [],
      tiposDocItems: [],
      dialogDelete: false,
      itemToDelete: null,
      isLoadingDelete: false,
      userId: null,
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      getFinanciadorId: "financiador/getFinanciadorId",
      isMobile: "menu/isMobile",
    }),
  },
  created() {
    this.financiadorId = this.getFinanciadorId; // Asigno el dato con el financiador cargado en el state
    this.getUsuariosEmisores();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
    this.loadTaxonomys();
  },
  methods: {
    ...mapActions({
      getTiposPrestadores: "taxonomy/getTiposPrestadores",
      getTiposDocumentos: "taxonomy/getTiposDocumentos",
      getUsuariosEmisoresByFinanciadorId:
        "usuariosEmisores/getUsuariosEmisoresByFinanciadorId",
      deleteUsuarioEmisor: "usuariosEmisores/deleteUsuarioEmisor",
      changeUserEnabledStatus: "usuariosEmisores/changeUserEnabledStatus",
      setAlert: "user/setAlert",
    }),
    async loadTaxonomys() {
      this.tiposPrestadorItems = await this.getTiposPrestadores(
        this.financiadorId
      );
      this.tiposDocItems = await this.getTiposDocumentos();
    },
    async getUsuariosEmisores() {
      this.dataTableIsLoading = true;
      try {
        const response = await this.getUsuariosEmisoresByFinanciadorId(
          this.financiadorId
        );
        this.usuariosEmisoresItems = response;
      } catch (error) {
        console.error("🚀 ~ getUsuariosEmisores ~ error:", error);
      }
      this.dataTableIsLoading = false;
    },
    openDeleteDialog(item) {
      this.dialogDelete = true;
      this.itemToDelete = item;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    async confirmDelete() {
      this.isLoadingDelete = true;
      try {
        const response = await this.deleteUsuarioEmisor({
          preId: this.itemToDelete.preId,
          preXFinId: this.itemToDelete.preXFinId,
          usuarioId: this.itemToDelete.usuarioId,
        });
        if (response?.status === 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito" });
          this.dialogDelete = false;
          this.getUsuariosEmisores();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
    closeDialog(reload) {
      this.editUsuarioEmisorModal = false;
      this.usuarioEmisorItem = null;
      if (reload) this.getUsuariosEmisores();
    },
    openModalEditUsuarioEmisor(item) {
      this.editUsuarioEmisorModal = true;
      this.usuarioEmisorItem = item;
    },
    async changeUserStatus(item) {
      try {
        const response = await this.changeUserEnabledStatus(item.preXFinId);
        if (response)
          this.setAlert({
            type: "success",
            message: `Usuario ${
              item.deshabilitado ? "habilitado" : "deshabilitado"
            } con éxito.`,
          });
        this.getUsuariosEmisores();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .buscador {
    width: 70%;
    flex: none;
  }
}
</style>
