// Styles
import "@mdi/font/css/materialdesignicons.css";
import { es } from 'vuetify/locale'
import "vuetify/styles";
// import {
//   VDataTable,
//   VDataTableServer,
//   VDataTableVirtual,
// } from "vuetify/labs/VDataTable";
// import { VuetifyDateAdapter } from 'vuetify/date/adapters/vuetify'


// Vuetify
import { createVuetify } from "vuetify";
// import Spanish from "vuetify/es5/locale/es";

export default createVuetify({
  // components: {
  //   VDataTable,
  //   VDataTableServer,
  //   VDataTableVirtual,
  // },
  locale: {
    locale: 'es',
    fallback: 'en',
    messages: { es },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#242059", //"#07869A", // Gepresauld "#006666",
          // secondary: "#E0F2F1",
          // accent: "#82B1FF",
          error: "#b30000",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FF9800",
        },
      },
    },
  },
  // date: {
  //   adapter: VuetifyDateAdapter
  // }
  // lang: {
  //   locales: { Spanish },
  //   current: "Spanish",
  // },
});
