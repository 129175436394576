const mutations = {
  setAlertMessage(state, alert) {
    state.alert = alert;
  },
  setAppLoading(state, val) {
    state.appLoading = val;
  },
};

export default mutations;
