<template>
  <v-card class="mobile-container">
    <v-row class="">
      <v-col cols="12" class="pb-0">
        <v-card-actions class="pb-0">
          <span class="text-h5 pl-1">
            Dispensa de medicamentos: Prescripción Nº {{ solicitudHash }}
          </span>
          <v-spacer></v-spacer>
          <v-tooltip text="Cerrar" location="left">
            <template v-slot:activator="{ props }">
              <v-btn
                color="primary"
                v-bind="props"
                class="to-right"
                :icon="closeIcon"
                id="vBtnClose"
                @click="closeDialog"
              >
              </v-btn>
            </template>
          </v-tooltip>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-card class="elevation-1 ma-3">
      <v-row align="center">
        <v-col cols="7">
          <v-card-title>
            <span class="text-h7">
              {{ isMobile ? "A dispensar" : "Medicamentos a dispensar" }}</span
            >
          </v-card-title>
        </v-col>
        <v-col cols="4" class="text-right pa-0" :class="isMobile ? 'pr-2' : ''">
          <v-btn
            :disabled="
              selected.length === 0 ||
              medicamentosNoDispensados.length === 0 ||
              medicamentosNoDispensados.some(
                (med) =>
                  this.selected.includes(med.id) &&
                  med.Editado === true &&
                  med.selectedMedicamento === null
              )
            "
            color="primary"
            variant="flat"
            class="mx-4 mt-4"
            @click="openConfirmDialog = true"
            id="v-btn-dispensar-receta"
          >
            Dispensar
          </v-btn>
        </v-col>
        <v-col cols="1" v-if="!isMobile" class="pa-0 mt-3 text-left">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" color="primary">
                {{ infoIcon }}
              </v-icon>
            </template>
            <span
              >Dispensa total o parcial de los medicamentos seleccionados.</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table-virtual
        class="mobile-style-selection mobile-data-row"
        style="max-height: 350px"
        v-model="selected"
        :items="medicamentosNoDispensados"
        :mobile="isMobile"
        :hide-default-header="isMobile"
        item-value="id"
        item.key="id"
        :headers="isMobile ? headersMobile : headers"
        :loading="isLoading"
        @update:modelValue="selectedUpdate"
        :show-select="!isMobile"
      >
        <template v-slot:[`item.regNom`]="{ item: medNoDispItem }">
          <v-row
            v-if="medNoDispItem.Editado ?? false"
            no-gutters
            align="center"
            justify="center"
            dense
          >
            <v-select
              :label="isMobile ? '' : 'Ingrese un medicamento'"
              v-model="medNoDispItem.selectedMedicamento"
              :style="isMobile ? 'width: 200px' : 'width: 400px'"
              :class="[isMobile ? 'mobile-style pt-10' : 'pt-6']"
              :items="medNoDispItem.medicamentos"
              ref="medicamentos-autocomplete"
              return-object
              variant="outlined"
              density="compact"
              item-title="nombre"
              :disabled="medNoDispItem.medicamentos.length == 0"
              item-value="id"
              persistent-hint
              @click:appendInner="
                (medNoDispItem.Editado = false),
                  (medNoDispItem.medicamentos = []),
                  (medNoDispItem.selectedMedicamento = null),
                  (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)
              "
              @click:clear="
                (medNoDispItem.medicamentos = []),
                  (medNoDispItem.selectedMedicamento = null),
                  (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)
              "
              :menu-props="{ closeOnContentClick: true }"
            >
            </v-select>
            <v-tooltip
              v-if="!isMobile"
              text="Buscar medicamentos"
              location="left"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  color="primary"
                  :icon="searchIcon"
                  :variant="'flat'"
                  @keydown.enter="openModalMedicamentos(medNoDispItem)"
                  :size="'small'"
                  v-bind="props"
                  class="ml-5"
                  @click="openModalMedicamentos(medNoDispItem)"
                >
                </v-btn>
              </template>
            </v-tooltip>
            <v-btn
              v-else
              color="primary"
              variant="flat"
              @keydown.enter="openModalMedicamentos(medNoDispItem)"
              class="mb-2"
              style="font-size: 10px"
              @click="openModalMedicamentos(medNoDispItem)"
              id="v-btn-Buscar-medicamentos"
            >
              Buscar medicamentos
            </v-btn>
            <v-tooltip v-if="!isMobile" location="left">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  size="20"
                  class="pl-4"
                  @click="
                    (medNoDispItem.Editado = false),
                      (medNoDispItem.medicamentos = []),
                      (medNoDispItem.selectedMedicamento = null),
                      (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)
                  "
                  @keydown.enter="(medNoDispItem.Editado = false),
                      (medNoDispItem.medicamentos = []),
                      (medNoDispItem.selectedMedicamento = null),
                      (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)"
                >
                  {{ !isMobile ? closeIcon : "mdi-reload" }}
                </v-icon>
              </template>
              <span>Quitar modificación</span>
            </v-tooltip>
            <v-btn
              v-else
              color="primary"
              variant="flat"
              style="font-size: 10px"
              @click="
                (medNoDispItem.Editado = false),
                  (medNoDispItem.medicamentos = []),
                  (medNoDispItem.selectedMedicamento = null),
                  (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)
              "
              id="v-btn-quitarMod"
            >
              Quitar modificación
            </v-btn>
          </v-row>
          <v-row v-else align="center" justify="center" dense>
            <v-text-field
              :style="isMobile ? 'width: 200px' : 'width: 400px'"
              variant="outlined"
              :class="[isMobile ? 'mobile-style pt-10' : 'pt-6']"
              density="compact"
              @click:appendInner="medNoDispItem.Editado = true"
              readonly
              v-model="medNoDispItem.regNom"
            ></v-text-field>
            <v-tooltip v-if="!isMobile" location="left">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  size="20"
                  @keydown.enter="
                    (medNoDispItem.Editado = true),
                      (medNoDispItem.medicamentos = []),
                      (medNoDispItem.selectedMedicamento = null),
                      (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)
                  "
                  class="pl-0"
                  @click="
                    (medNoDispItem.Editado = true),
                      (medNoDispItem.medicamentos = []),
                      (medNoDispItem.selectedMedicamento = null),
                      (medNoDispItem.cantidad = medNoDispItem.cantidadInicial)
                  "
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar medicamento</span>
            </v-tooltip>
            <v-btn
              v-else
              color="primary"
              variant="flat"
              style="font-size: 10px"
              @keydown.enter="medNoDispItem.Editado = true"
              @click="medNoDispItem.Editado = true"
              id="v-btn-dispensar-receta"
            >
              Editar medicamento
            </v-btn>
          </v-row>
        </template>
        <template v-slot:[`item.cantidad`]="{ item }">
          <v-row align="center" dense no-gutters>
            <v-text-field
              v-model="item.cantidad"
              type="number"
              variant="outlined"
              id="v-text-field-cantidad"
              density="compact"
              :class="'pt-6 pl-0'"
              :style="isMobile ? '' : 'max-width: 60%'"
              min="1"
              :max="item.cantidadInicial"
              @keypress="validateCantidad($event, item)"
            >
            </v-text-field>
          </v-row>
        </template>
      </v-data-table-virtual>
    </v-card>
    <v-card class="elevation-1 ma-3" v-if="medicamentosDispensados?.length > 0">
      <v-row>
        <v-col cols="6">
          <v-card-title class="pl-4">
            <span class="text-h7"
              >{{ isMobile ? "Dispensados" : "Medicamentos dispensados" }}
            </span>
          </v-card-title>
        </v-col>
        <v-col cols="6" class="mt-3">
          <!-- mostrar/ocultar tabla -->
          <v-row class="pb-2 px-4">
            <v-spacer></v-spacer>
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <v-btn
                  variant="text"
                  v-bind="props"
                  :icon="showDataTable ? upArrowIcon : downArrowIcon"
                  @keydown.tab="focusComponent('vBtnClose', 0)"
                  @click="showDataTable = !showDataTable"
                  class="my-2 white--text to-right"
                  id="v-btn-showdatatable"
                >
                </v-btn>
              </template>
              {{
                showDataTable
                  ? "Ocultar medicamentos dispensados"
                  : "Mostrar medicamentos dispensados"
              }}
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <!-- tabla medicamentos dispensados -->
      <v-expand-transition>
        <v-data-table-virtual
          class="overflow-y-auto"
          style="max-height: 250px"
          :mobile="isMobile"
          :hide-default-header="isMobile"
          v-show="showDataTable"
          :items="medicamentosDispensados"
          :headers="headersDispensado"
          item-value="name"
        >
          <template v-slot:[`item.regNomOriginal`]="{ item }">
            <span>
              {{
                item.regNomOriginal?.length > 0 ?? false
                  ? item.regNomOriginal
                  : item.regNom
              }}</span
            >
          </template>
          <template v-slot:[`item.cantidadOriginal`]="{ item }">
            <span>
              {{
                item.cantidadOriginal?.length > 0 ?? false
                  ? item.cantidadOriginal
                  : item.cantidad
              }}</span
            >
          </template>
        </v-data-table-virtual>
      </v-expand-transition>
    </v-card>
  </v-card>
  <v-dialog
    :max-width="isMobile ? '95%' : '25%'"
    v-model="openConfirmDialog"
    @keydown.esc="openConfirmDialog = false"
    persistent
  >
    <ConfirmDialog
      v-if="openConfirmDialog"
      :isLoading="isLoadingDispensa"
      :title="titleDispensar"
      @confirmar="dispensarMedicamento()"
      @confirmar-cancelado="openConfirmDialog = false"
    />
  </v-dialog>
  <v-dialog
    v-if="dialogBusquedaMedicamentos"
    v-model="dialogBusquedaMedicamentos"
    :max-width="isMobile ? '95%' : '70%'"
    persistent
    @keydown.esc="closeModalMedicamentos()"
  >
    <BusquedaAvanzadaMedicamentos
      :financiadorId="financiadorId"
      @closeModalMedicamentos="closeModalMedicamentos()"
      @selectMedicamento="selectMedicamento($event)"
    />
  </v-dialog>
</template>

<script>
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import { focusComponent } from "@/utils/helpers/htmlHelpers";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index";
import BusquedaAvanzadaMedicamentos from "@/components/shared/BusquedaAvanzadaMedicamentos.vue";

export default {
  components: {
    ConfirmDialog,
    BusquedaAvanzadaMedicamentos,
  },
  props: {
    solicitudHash: {
      type: Number,
      required: false,
    },
    financiadorId: {
      type: Number,
      required: false,
    },
  },
  emits: ["closeDialog"],
  data() {
    return {
      openConfirmDialog: false,
      titleDispensar: enums.messages.CONFIRM_DISPENSA,
      medicamentosNoDispensados: [],
      medicamentosDispensados: [],
      eyeIcon: enums.icons.OPEN_EYE_ICON,
      closeEyeIcon: enums.icons.CLOSE_EYE_ICON,
      headersMobile: [
        { title: "Nombre", sortable: false, key: "regNom" },
        { title: "Cantidad", sortable: false, key: "cantidad" },
        {
          title: "",
          value: "data-table-select",
          sortable: false,
        },
      ],
      headers: [
        { title: "Nombre", sortable: false, key: "regNom" },
        { title: "Cantidad", sortable: false, key: "cantidad" },
      ],
      headersDispensado: [
        { title: "Medicamento dispensado", sortable: false, key: "regNom" },
        {
          title: "Cantidad dispensada",
          sortable: false,
          key: "cantidad",
          align: "end",
        },
        {
          title: "Medicamento prescripto",
          sortable: false,
          key: "regNomOriginal",
        },
        {
          title: "Cantidad prescripta",
          sortable: false,
          key: "cantidadOriginal",
          align: "end",
        },
      ],
      selected: [],
      downArrowIcon: enums.icons.DOWN_ARROW_ICON,
      upArrowIcon: enums.icons.UP_ARROW_ICON,
      editIcon: enums.icons.EDIT_ICON,
      closeIcon: enums.icons.CLOSE_ICON,
      infoIcon: enums.icons.SNB_INFO,
      loadingMedicamentos: false,
      editMedicamentoAgregado: false,
      medicamentos: [],
      selectedMedicamento: null,
      showDataTable: false,
      lockButton: false,
      isLoading: false,
      isLoadingDispensa: false,
      isAuthorized: false,
      isAthorizeLoading: false,
      dialogBusquedaMedicamentos: false,
      searchIcon: enums.icons.SEARCH_ICON,
      medicamentoAModificar: null,
    };
  },
  created() {
    this.loadMedicamentoADispensar();
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getMedicamentoADispensar: "solicitud/getMedicamentoADispensar",
      postMedicamentoDispensado: "solicitud/postMedicamentoDispensado",
      fetchMedicamentosByQuery: "taxonomy/fetchMedicamentosByQuery",
    }),
    focusComponent(refName, timeout) {
      focusComponent(refName, timeout);
    },
    validateCantidad(event, item) {
      const char = String.fromCharCode(event.keyCode);
      if (!/[1-9]/.test(char) || char > item.cantidadInicial)
        event.preventDefault();

      const newValue = event.target.value + char;
      const newValueNumber = parseInt(newValue, 10);

      if (newValueNumber < 1 || newValueNumber > item.cantidadInicial)
        event.preventDefault();
    },
    async loadMedicamentoADispensar() {
      this.isLoading = true;
      try {
        const response = await this.getMedicamentoADispensar(
          this.solicitudHash
        );
        this.medicamentosNoDispensados =
          response?.medicamentosNoDispensados.map((x) => {
            return {
              ...x,
              Editado: false,
              medicamentos: [],
              selectedMedicamento: null,
              loadingMedicamentos: false,
              editMedicamentoAgregado: false,
              inputMedicamento: null,
            };
          }) ?? [];
        this.medicamentosDispensados = response?.medicamentosDispensados ?? [];
        this.medicamentosNoDispensados.forEach((medicamento) => {
          medicamento.cantidadInicial = medicamento.cantidad;
        });
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },

    async dispensarMedicamento() {
      this.isLoadingDispensa = true;
      if (this.selected.length === 0) {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar un medicamento para dispensar.",
        });
      }

      this.lockButton = true;
      try {
        const medicamentosSeleccionados = this.medicamentosNoDispensados.filter(
          (medicamento) => this.selected.includes(medicamento.id)
        );
        const data = medicamentosSeleccionados.map((medicamento) => ({
          id: medicamento.id,
          regNro:
            medicamento.Editado ?? false
              ? medicamento.selectedMedicamento.id
              : medicamento.regNro,
          regNom: medicamento.regNom,
          regNroOriginal:
            medicamento.Editado ?? false ? medicamento.regNro : null,
          cantidad: medicamento.cantidad,
          cantidadOriginal: medicamento.cantidadInicial,
        }));

        const response = await this.postMedicamentoDispensado({
          solicitudHash: this.solicitudHash,
          medicamentos: data,
        });
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Medicamento dispensado con éxito",
          });
          this.loadMedicamentoADispensar();
        } else {
          this.setAlert({
            type: "error",
            message: "Error al dispensar medicamento.",
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.lockButton = false;
      this.isLoadingDispensa = false;
      this.openConfirmDialog = false;
    },
    selectedUpdate(newSelected) {
      this.medicamentosNoDispensados.forEach((medicamento) => {
        if (!newSelected.includes(medicamento.id)) {
          medicamento.cantidad = medicamento.cantidadInicial;
        }
      });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    autorizarReceta() {
      this.isAthorizeLoading = true;
      setTimeout(() => {
        this.setAlert({
          type: "success",
          message: "Autorizado con éxito.",
        });
        this.isAuthorized = true;
        this.isAthorizeLoading = false;
      }, 1000);
    },
    closeModalMedicamentos() {
      this.dialogBusquedaMedicamentos = false;
      this.medicamentoAModificar = null;
    },
    openModalMedicamentos(medNoDispItem) {
      this.medicamentoAModificar = medNoDispItem;
      this.dialogBusquedaMedicamentos = true;
    },
    selectMedicamento(medicamento) {
      if (this.medicamentoAModificar) {
        this.medicamentosNoDispensados.forEach((x) => {
          if (x.id === this.medicamentoAModificar.id) {
            x.medicamentos.push(medicamento);
            x.selectedMedicamento = medicamento;
          }
        });
        this.dialogBusquedaMedicamentos = false;
        this.medicamentoAModificar = null;
      } else {
        this.medicamentosNoDispensados.forEach((x) => {
          x.medicamentos.push(medicamento);
          x.selectedMedicamento = medicamento;
        });
        this.dialogBusquedaMedicamentos = false;
      }
      focusComponent("v-text-field-cantidad", 100);
    },
  },
};
/* eslint-disable */
</script>
<style scoped>
.no-upper-case {
  text-transform: none;
}
.graybg {
  background-color: rgb(209, 184, 255);
}
.mobile-style :deep(.v-field__input) {
  width: 200px;
  height: 10px;
  text-overflow: ellipsis;
  font-size: xx-small;
}
.mobile-style :deep(.v-select__selection-text) {
  text-overflow: ellipsis;
  font-size: xx-small;
}
.mobile-style-selection
  :deep(.v-data-table__tr--mobile > .v-data-table__td--select-row) {
  justify-content: start;
  border-block-end: 5px lightgray solid;
}
</style>
