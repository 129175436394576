import titles from './titles';
import permises from './permises'
import modules from './modules/index';
import messages from './messages'
import icons from './icons';
import enums from './enums'

export default {
    titles,
    permises,
    modules,
    icons,
    messages,
    enums
};