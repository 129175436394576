<template>
  <v-app>
    <v-main>
      <SnackBar />
      <v-dialog v-model="isIdle" persistent max-width="35%">
        <SessionTimeoutDialog />
      </v-dialog>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "@/components/layout/SnackBar";
import SessionTimeoutDialog from "@/components/shared/SessionTimeoutDialog.vue";

export default {
  name: "main-page",
  components: { SnackBar, SessionTimeoutDialog },
  data() {
    return {};
  },
  computed: {
    isIdle() {
      if (this.$router.currentRoute.value.name === "login") return false;
      else return this.$store.state.idleVue.isIdle;
    },
  },
  beforeCreate() {},
  created() {
    this.checkIfMobile();
  },
  beforeUnmount() {
  },
  mounted() {},
  watch: {},
  methods: {
    checkIfMobile() {
      this.$store.dispatch("menu/setConfigMobile", screen.height >=  screen.width);
    },
  },
};
</script>

<style lang="scss">
.to-right {
  float: right;
}
.v-card-title {
  white-space: normal;
}
:deep(.text-h5) {
  font-size: 1rem !important ;
}
@media screen and (max-width: 760px) {
  .v-card-title.text-h5 {
    font-size: 1rem !important ;
  }
  :deep(.text-h5) {
    font-size: 1rem !important ;
  }
  .mobile-container {
    .text-h5 {
      font-size: 1rem !important;
    }
    .text-h7 {
      font-size: 1rem !important;
    }
  }
}
</style>
