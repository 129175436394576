<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-container>
      <v-form
        form="submit-form"
        id="submit-form"
        ref="submit-form"
        v-model="isFormValid"
        @submit.prevent="guardarPermiso()"
      >
        <v-row>
          <v-col cols="12" sm="12" md="12" class="py-0">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              variant="outlined"
              density="compact"
              :rules="[rules.required, rules.nameRules, rules.maxLength(nombre, 50)].flat()"
              id="name-text-field-edit-permiso"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="py-0">
            <v-select
              v-model="tipoPermisoSelected"
              label="Tipo de permiso"
              :items="tipoPermisoItems"
              variant="outlined"
              density="compact"
              item-title="value"
              item-value="id"
              :loading="istiposPermisoSelectLoading"
              :rules="rules.required"
              id="tipoPermiso-vSelect-edit-permiso"
            ></v-select>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="py-0">
            <v-autocomplete
              v-model="permisoPadreSelected"
              label="Permiso padre"
              variant="outlined"
              density="compact"
              :items="permisosListItems"
              item-title="permisoNombre"
              item-value="permisoId"
              :loading="isPermisoPadreSelectLoading"
              id="permisoPadre-vAutocomplete-edit-permiso"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="py-0">
            <v-text-field
              v-model="orden"
              label="Orden"
              variant="outlined"
              density="compact"
              :rules="[rules.required, rules.validNumberRule].flat()"
              id="orden-text-field-edit-permiso"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="py-0">
            <v-select
              v-model="servicioSelected"
              label="Servicio"
              :items="serviciosItems"
              variant="outlined"
              density="compact"
              item-title="value"
              item-value="id"
              :loading="isServicioSelectLoading"
              id="servicio-vSelect-edit-permiso"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="py-0 pr-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="closeDialog"
          id="v-btn-close-edit"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          variant="flat"
          form="submit-form"
          :disabled="!isFormValid || isLoadingSave"
          id="v-btn-save-edit"
        >
          <template v-if="isLoadingSave">
            <v-progress-circular
              indeterminate
              color="white"
              size="25"
            ></v-progress-circular>
          </template>
          <template v-else> Guardar </template>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules.js";
import { mapActions } from "vuex";
export default {
  props: {
    permisoItem: {
      type: Object,
      required: false,
    },
    permisosListItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nombre: "",
      tipoPermisoSelected: null,
      tipoPermisoItems: [],
      permisoPadreSelected: null,
      orden: null,
      servicioSelected: null,
      serviciosItems: [],
      formTitle: "",
      isFormValid: false,
      isLoadingSave: false,
      istiposPermisoSelectLoading: false,
      isPermisoPadreSelectLoading: false,
      isServicioSelectLoading: false,
      rules: rules,
    };
  },
  created() {
    this.setSelects();
    this.permisoItem
      ? this.setearPermiso()
      : (this.formTitle = "Nuevo permiso");
  },
  methods: {
    ...mapActions({
      getPermisos: "permisos/getPermisos",
      postPermiso: "permisos/savePermiso",
      getServicios: "taxonomy/getServicios",
      getTipoPermisos: "taxonomy/getTipoPermisos",
      setAlert: "user/setAlert",
    }),
    async setearPermiso() {
      this.formTitle = `Editar permiso – ${this.permisoItem.permisoNombre}`;
      this.nombre = this.permisoItem.permisoNombre;
      this.tipoPermisoSelected = this.permisoItem.permisoTipoId;
      this.permisoPadreSelected = this.permisoItem.permisoPadreId;
      this.orden = this.permisoItem.permisoOrden.toString();
      this.servicioSelected = this.permisoItem.servicioId;
    },
    async getTiposPermisoSelect() {
      this.istiposPermisoSelectLoading = true;
      try {
        this.tipoPermisoItems = await this.getTipoPermisos();
      } catch (error) {
        console.error(
          "hubo un problema en traer los datos permisos padres",
          error
        );
      }
      this.istiposPermisoSelectLoading = false;
    },
    async getServiciosSelect() {
      this.isServicioSelectLoading = true;
      try {
        this.serviciosItems = await this.getServicios();
      } catch (error) {
        console.error("hubo un problema en traer los datos servicios", error);
      }
      this.isServicioSelectLoading = false;
    },
    async setSelects() {
      await Promise.all([
        this.getTiposPermisoSelect(),
        this.getServiciosSelect(),
      ]);
    },
    async guardarPermiso() {
      this.isLoadingSave = true;
      const payload = {
        permisoId: this.permisoItem?.permisoId ?? null,
        permisoNombre: this.nombre,
        permisoCodigo: "",
        permisoOrden: this.orden,
        permisoTipoId: this.tipoPermisoSelected,
        servicioId: this.servicioSelected,
        permisoPadreId: this.permisoPadreSelected,
        permisoIsFromUserGroup: false,
        permisoHijos: [],
      };
      try {
        const resp = await this.postPermiso(payload);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito ",
          });
          this.closeDialog();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingSave = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
