<template>
  <v-list :class="isMobile ? 'py-0 my-0' : ''">
    <template v-for="(permiso, index) in allPermisos" :key="index">
      <v-list-group
        :class="isMobile ? 'ml-0 pl-0' : 'ml-6'"
        subgroup
        v-if="
          permiso.permisoHijos.length > 0 ||
          (permiso.permisoPadreId === null && permiso.permisoHijos.length > 0)
        "
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            class="py-0 pl-0 pr-0"
          >
            <v-list-item-title class="pa-0 ma-0">
              <v-row class="ma-0 pa-0" :no-gutters="isMobile">
                <v-col cols="1" class="pa-0">
                  <v-checkbox-btn
                    class="pa-0 my-0"
                    :model-value="permiso.permisoIsFromUserGroup"
                    @update:model-value="emitSeleccionarPermiso(permiso)"
                    @click.stop
                    :class="{ isDisabled: disableSeletion }"
                  ></v-checkbox-btn>
                </v-col>
                <v-col cols="9" class="pt-2">
                  <p :class="isMobile ? 'pl-2' : 'pl-3'">
                    {{ permiso.permisoNombre }}
                  </p>
                </v-col>
                <v-col cols="2" :class="isMobile ?  'pt-3' : ''">
                  <v-tooltip text="Eliminar permiso" location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        size="small"
                        class="to-right"
                        @click.stop="
                          emitOpenDeleteDialog(
                            permiso.permisoId,
                            permiso.permisoNombre
                          )
                        "
                        v-bind="props"
                        id="v-icon-delete-permiso"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                  </v-tooltip>
                  <v-tooltip text="Editar permiso" location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        size="20"
                        class="to-right"
                        @click.stop="emitOpenEditDialog(permiso)"
                        v-bind="props"
                        id="v-icon-edit-permiso"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item>
        </template>
        <div>
          <PermisoList
            v-if="permiso && permiso.permisoHijos"
            :allPermisos="permiso.permisoHijos"
            :disableCheck="disableSeletion"
            @emitSeleccionarPermiso="emitSeleccionarPermiso"
            @emitOpenEditDialog="emitOpenEditDialog"
            @emitOpenDeleteDialog="emitOpenDeleteDialog"
          />
        </div>
      </v-list-group>
      <!-- si no tiene hijos -->
      <!-- :class="permiso.permisoPadreId === null ? 'ml-0 pl-0' : 'ml-1'" -->
      <v-list-item class="py-0 px-0" v-else>
        <v-list-item-title class="pa-0 ma-0">
          <v-row class="ma-0 pa-0" :no-gutters="isMobile">
            <v-col cols="1" class="pa-0">
              <v-checkbox-btn
                class="pa-0 my-0"
                :model-value="permiso.permisoIsFromUserGroup"
                @update:model-value="emitSeleccionarPermiso(permiso)"
                @click.stop
                :class="{ isDisabled: disableSeletion }"
              ></v-checkbox-btn>
            </v-col>
            <v-col cols="9" class="pt-2">
              <p :class="isMobile ? 'pl-2' : 'pl-0'">
                {{ permiso.permisoNombre }}
              </p>
            </v-col>
            <v-col cols="2" :class="isMobile ?  'pt-3' : ''">
              <v-tooltip text="Eliminar permiso" location="start">
                <template v-slot:activator="{ props }">
                  <v-icon
                    size="20"
                    class="to-right"
                    @click.stop="
                      emitOpenDeleteDialog(
                        permiso.permisoId,
                        permiso.permisoNombre
                      )
                    "
                    v-bind="props"
                    id="v-icon-delete-permiso"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
              </v-tooltip>
              <v-tooltip text="Editar permiso" location="start">
                <template v-slot:activator="{ props }">
                  <v-icon
                    size="20"
                    class="to-right"
                    @click.stop="emitOpenEditDialog(permiso)"
                    v-bind="props"
                    id="v-icon-edit-permiso"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapGetters } from "vuex";
export default {
  props: {
    allPermisos: {
      type: Object,
      required: false,
      default: () => {},
    },
    isLoadingItems: {
      type: Boolean,
      default: false,
    },
    disableCheck: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "emitSeleccionarPermiso",
    "emitOpenEditDialog",
    "emitOpenDeleteDialog",
  ],
  data() {
    return {
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      disableSeletion: false,
    };
  },
  watch: {
    disableCheck(val) {
      this.disableSeletion = val;
    },
    isLoadingItems(val) {
      if (val) {
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
        });
      } else {
        this.$store.dispatch("user/changeAppLoading", {
          status: false,
          text: "",
        });
      }
    },
  },
  computed: {
    ...mapGetters({ isMobile: "menu/isMobile" }),
  },
  methods: {
    emitOpenEditDialog(permiso) {
      this.$emit("emitOpenEditDialog", permiso);
    },
    emitOpenDeleteDialog(permisoId, permisoNombre) {
      this.$emit("emitOpenDeleteDialog", permisoId, permisoNombre);
    },
    emitSeleccionarPermiso(permiso) {
      this.$emit("emitSeleccionarPermiso", permiso);
    },
  },
};
</script>
<style scoped>
.isDisabled {
  color: #a6a5a5b6;
  pointer-events: none;
}
</style>
