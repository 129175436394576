import axios from "@/axios/axios-api";

export default {
  async getPermisos(_, payload) {
    try {
      return (
        await axios.get(
          payload
            ? "/api/permisos/get-permisos?grupoUsuId=" + payload
            : "/api/permisos/get-permisos"
        )
      )?.data;
    } catch (error) {
      console.error(`No se pudo traer los datos, ${error}`);
    }
  },
  async getPermisosByUserGroup(_, payload) {
    try {
      return (
        await axios.get(
          `/api/permisos/get-permisos-by-user-group?grupoUsuId=${payload}`
        )
      )?.data;
    } catch (error) {
      console.error(`No se pudo traer los datos, ${error}`);
    }
  },
  async savePermiso(_, payload) {
    try {
      const res = await axios.post("/api/permisos/save-permiso", payload);
      return res;
    } catch (error) {
      console.error(`No se pudo guardar datos, ${error}`);
    }
  },
  async deletePermiso(_, ID) {
    try {
      const res = await axios.delete(`/api/permisos/delete-permiso/${ID}`);
      return res;
    } catch (error) {
      console.error(`No se pudo elimiar este registro, ${error}`);
    }
  },
  async savePermisosGrupos(_, payload) {
    try {
      const res = await axios.post(
        "/api/permisos/save-permisos-grupos",
        payload
      );
      return res;
    } catch (error) {
      console.error(`No se pudo guardar datos, ${error}`);
    }
  },
};
