<template>
  <v-card>
    <v-container class="pa-0">
      <v-card-title class="pb-0">
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form v-model="isFormValid" ref="form" @submit.prevent="save()">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="pt-2 pb-0">
                <v-text-field
                  label="Nombre de usuario"
                  v-model="name"
                  density="compact"
                  variant="outlined"
                  @keydown.space.prevent
                  :rules="
                    rules.required.concat(
                      [rules.maxLength(name, 50)],
                      rules.nameRulesWithNumber,
                      [rules.whiteSpaceNotAllowed]
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="!usuarioItemProp" class="pt-2 pb-0">
                <v-text-field
                  label="Contraseña"
                  density="compact"
                  variant="outlined"
                  @keydown.space.prevent
                  :type="showPassword ? 'text' : 'password'"
                  v-model="contrasena"
                  :rules="
                    usuarioItemProp
                      ? []
                      : rules.password.concat([rules.whiteSpaceNotAllowed])
                  "
                >
                  <v-icon
                    class="eye-icon"
                    @click="showPassword = !showPassword"
                  >
                    {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-2 pb-0">
                <v-select
                  :items="grupos"
                  density="compact"
                  variant="outlined"
                  label="Grupo de Usuario"
                  v-model="selectedGrupo"
                  :rules="rules.required"
                  return-object
                  item-value="id"
                  item-title="value"
                  @update:model-value="crearCombos"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="emisorComputed" class="pt-2 pb-0">
                <v-select
                  :items="emisores"
                  label="Emisor"
                  density="compact"
                  variant="outlined"
                  v-model="selectedEmisor"
                  :rules="mostrarTodosSelectsComputed ? [] : rules.required"
                  item-value="id"
                  item-title="value"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="financiadorComputed"
                class="pt-2 pb-0"
              >
                <v-select
                  :items="financiadores"
                  label="Financiador"
                  density="compact"
                  variant="outlined"
                  v-model="selectedFinanciador"
                  :rules="mostrarTodosSelectsComputed ? [] : rules.required"
                  item-value="id"
                  item-title="value1"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" v-if="farmaciaComputed" class="pt-2 pb-0">
                <v-select
                  :items="farmacias"
                  label="Farmacia"
                  density="compact"
                  variant="outlined"
                  v-model="selectedFarmacia"
                  :rules="mostrarTodosSelectsComputed ? [] : rules.required"
                  item-value="id"
                  item-title="value"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" :sm="correoColSize" class="pt-2 pb-0">
                <v-text-field
                  label="Email"
                  density="compact"
                  variant="outlined"
                  v-model="correo"
                  :rules="rules.required.concat(rules.emailRules)"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-switch
                  v-model="dobleFactorHabilitado"
                  color="primary"
                  label="Doble factor"
                >
                </v-switch>
              </v-col>
              <v-col cols="4" class="py-0" v-if="dobleFactorHabilitado">
                <v-text-field
                  v-model="dobleFactorDias"
                  label="Doble factor días"
                  persistent-hint
                  variant="outlined"
                  density="compact"
                  min="0"
                  v-mask="'###'"
                  :rules="rules.validNumberRule"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="closeModal(false)">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              :disabled="!isFormValid || lockButton || selectsRequiredComputed"
              :loading="isLoading"
              @click="save()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  name: "EditUsuario",
  props: {
    usuarioItemProp: {
      type: Object,
      required: false,
    },
    emisores: {
      type: Array,
      required: true,
    },
    financiadores: {
      type: Array,
      required: true,
    },
    grupos: {
      type: Array,
      required: true,
    },
    farmacias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formTitle: "",
      name: "",
      correo: "",
      contrasena: "",
      rules: rules,
      selectedEmisor: null,
      selectedFinanciador: null,
      selectedGrupo: null,
      isFormValid: false,
      lockButton: false,
      isLoading: false,
      showPassword: false,
      selectedFarmacia: null,
      crearFarmaciaBan: false,
      crearFinanciadorBan: false,
      crearEmisorBan: false,
      crearAdministradorBan: false,
      dobleFactorHabilitado: false,
      dobleFactorDias: 0,
    };
  },
  created() {
    if (this.usuarioItemProp != null) {
      this.setUsuario();
      this.formTitle = "Editar usuario";
    } else {
      this.formTitle = "Nuevo usuario";
    }
  },
  computed: {
    correoColSize() {
      if (this.usuarioItemProp == null) {
        return this.farmaciaComputed ||
          this.emisorComputed ||
          this.financiadorComputed
          ? "12"
          : "6";
      } else return "6";
    },
    farmaciaComputed() {
      return this.crearFarmaciaBan;
    },
    emisorComputed() {
      return this.crearEmisorBan;
    },
    financiadorComputed() {
      return this.crearFinanciadorBan;
    },
    mostrarTodosSelectsComputed() {
      return (
        this.crearEmisorBan && this.crearFinanciadorBan && this.crearFarmaciaBan
      );
    },
    selectsRequiredComputed() {
      if (this.mostrarTodosSelectsComputed) {
        return (
          this.selectedEmisor === null &&
          this.selectedFinanciador === null &&
          this.selectedFarmacia === null
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      postUsuario: "user/postUsuario",
      setAlert: "user/setAlert",
    }),
    setUsuario() {
      this.name = this.usuarioItemProp.userName;
      this.correo = this.usuarioItemProp.email;
      this.contrasena = this.usuarioItemProp.password;
      this.selectedGrupo = this.usuarioItemProp.grupoUsuario;
      this.crearCombos();
      this.selectedEmisor = this.usuarioItemProp.emisorId;
      this.selectedFinanciador = this.usuarioItemProp.financiadorId;
      this.selectedFarmacia = this.usuarioItemProp.farmaciaId;
      this.dobleFactorHabilitado = this.usuarioItemProp.dobleFactorHabilitado;
      this.dobleFactorDias = this.usuarioItemProp.dobleFactorDias;
    },
    async save() {
      this.lockButton = true;
      this.isLoading = true;
      const data = {
        userId: this.usuarioItemProp?.userId,
        userName: this.name,
        email: this.correo,
        password: this.contrasena,
        emisorId: this.selectedEmisor,
        financiadorId: this.selectedFinanciador,
        grupoUsuario: this.selectedGrupo,
        farmaciaId: this.selectedFarmacia,
        dobleFactorHabilitado: this.dobleFactorHabilitado,
        dobleFactorDias: this.dobleFactorHabilitado ? this.dobleFactorDias : 0,
      };
      try {
        const response = await this.postUsuario(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal(true);
          this.$emit("usuarioGuardado");
        }
      } catch (error) {
        console.error("Error al guardar cambios", error);
      } finally {
        this.isLoading = false;
        this.lockButton = false;
      }
    },
    closeModal(reload) {
      this.$emit("closeDialog", reload);
    },
    crearCombos() {
      switch (this.selectedGrupo?.id) {
        case 1: //Admin
          this.crearEmisorBan =
            this.crearFinanciadorBan =
            this.crearFarmaciaBan =
              false;
          break;
        case 2: // Financiador
          this.crearFinanciadorBan = true;
          this.crearFarmaciaBan = this.crearEmisorBan = false;
          this.selectedFinanciador = null;
          break;
        case 3: // Emisor
          this.crearEmisorBan = true;
          this.crearFinanciadorBan = this.crearFarmaciaBan = false;
          this.selectedEmisor = null;
          break;
        case 4: // Médico emisor
          // No tiene que mostrar nada, a lo sumo despues vemos si mostramos el PreId
          this.crearFinanciadorBan =
            this.crearFarmaciaBan =
            this.crearEmisorBan =
              false;
          break;
        case 5: // Farmacia
          this.crearFarmaciaBan = true;
          this.crearEmisorBan = this.crearFinanciadorBan = false;
          this.selectedFarmacia = null;
          break;

        default:
          this.crearEmisorBan =
            this.crearFinanciadorBan =
            this.crearFarmaciaBan =
              true;
          this.selectedEmisor =
            this.selectedFarmacia =
            this.selectedFinanciador =
              null;

          break;
      }
    },
  },
};
</script>

<style scoped>
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
