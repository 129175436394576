import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import store from "./store";
import enums from "./utils/enums/titles";
import VueTheMask from "vue-the-mask";
import IdleVue from "idle-vue";
// import Vue from 'vue';


loadFonts();
// Stop error resizeObserver
const debounce = function (callback, delay) {
  let tid;

  return function (...args) {
    const ctx = this;

    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

// const eventsHub = new Vue();

const app = createApp(App);
document.getElementById("title-app").innerHTML = enums.PRODUCT;
app
  .use(vuetify)
  .use(router)
  .use(store)
  .use(VueTheMask)
  .use(IdleVue, {
    // eventEmitter: eventsHub,
    store,
    idleTime: 1800000, // 30 minutos
    startAtIdle: false,
  })
  .mount("#app");
