<template>
  <v-card>
    <v-container class="pb-0 mobile-container">
      <v-card-title class="px-0">
        <span class="text-h5">Prestadores de {{ financiadorName }}</span>
      </v-card-title>
      <v-card class="mb-5">
        <v-form
          form="submit-form"
          id="submit-form"
          ref="submit-form"
          @submit.prevent="guardarTiposPrestadoresByFinanciadores()"
        >
          <v-container>
            <v-row :no-gutters="isMobile">
              <v-col :cols="isMobile ? 12 : 3" class="pb-0">
                <v-select
                  v-model="provinciaSelected"
                  label="Provincias"
                  variant="outlined"
                  density="compact"
                  :items="itemsProvincias"
                  item-title="value"
                  item-value="id"
                  :loading="isSelectLoading"
                  :model-value="provinciaSelected"
                  @update:model-value="filtrarTipoPreXProvincia()"
                  id="v-select-ver-provincias"
                  return-object
                ></v-select>
              </v-col>
              <v-col :cols="isMobile ? 12 : 4" class="pb-0">
                <v-autocomplete
                  v-model="tipoPrestadorSelected"
                  multiple
                  clearable
                  variant="outlined"
                  density="compact"
                  label="Tipo prestador"
                  :items="itemsTipoPrestador"
                  item-title="value"
                  item-value="id"
                  :disabled="provinciaSelected == null"
                  :loading="isTipoPrestadorSelectLoading"
                  @update:model-value="handleTipoPrestadoresSelectedChange()"
                  id="v-select-ver-tipoPrestador"
                  return-object
                >
                  <template v-slot:prepend-item>
                    <v-list-item title="Todos">
                      <template v-slot:prepend>
                        <v-checkbox-btn
                          v-model="selectAllTipoPrestadorCheckbox"
                          @update:model-value="toggleSelectAll()"
                        ></v-checkbox-btn>
                      </template>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                      <span>{{ item.title }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ tipoPrestadorSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col :cols="isMobile ? 12 : 2" class="d-flex justify-center">
                <v-btn
                  color="primary"
                  variant="flat"
                  :disabled="!validarSelectsComputed"
                  id="v-btn-add-financiadores"
                  @click="añadirPrestador()"
                >
                  Añadir
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card class="px-5 pt-5">
        <v-row :no-gutters="isMobile">
          <v-col :cols="isMobile ? 6 : 10">
            <v-text-field
              v-model="search"
              :append-icon="searchIcon"
              label="Buscar"
              single-line
              hide-details
              variant="underlined"
              clearable
              id="search-bar-ver-tipoPrestadores"
            ></v-text-field>
          </v-col>
          <v-col :cols="isMobile ? 6 : 2">
            <v-btn
              class="mt-3 d-flex justify-center pb-0"
              color="error"
              variant="outlined"
              @click="eliminarTodosTiposPrestadores"
              v-if="itemsTipoPrestadorDelFinanciador.length != 0"
              id="v-btn-borrar-todos-tipoPrestadores"
            >
              Borrar todos
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="py-0 my-0 px-0 mx-0">
          <v-row :no-gutters="isMobile">
            <v-col cols="12" class="px-0 mx-0">
              <v-data-table
                :items-per-page="5"
                items-per-page-option
                :headers="headers"
                class="pa-3"
                :mobile="isMobile"
                :hide-default-header="isMobile"
                :items="itemsTipoPrestadorDelFinanciador"
                :search="search"
                :loading="isDataTableTipoPrestadorLoading"
                :items-per-page-options="
                isMobile
                  ? [
                      { value: 1, title: '1' },
                      { value: 5, title: '5' },
                      { value: 10, title: '10' },
                    ]
                  : [
                      { value: 10, title: '10' },
                      { value: 25, title: '25' },
                      { value: 50, title: '50' },
                      { value: 100, title: '100' },
                      {
                        value: -1,
                        title: '$vuetify.dataFooter.itemsPerPageAll',
                      },
                    ]
              "
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex justify-end align-center">
                    <v-tooltip text="Eliminar relación" location="left">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          size="20"
                          @click="eliminarTipoPrestador(item)"
                          v-bind="props"
                          id="v-icon-delete-tipoPrestador"
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </template>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          variant="text"
          @click="validateBeforeClose"
          id="v-btn-close-ver-tipoPrestadores"
        >
          Cerrar
        </v-btn>

        <v-btn
          color="primary"
          variant="flat"
          type="submit"
          form="submit-form"
          :disabled="isGuardarTipoPrestadorLoading"
          v-if="permisosTiposPrestadores.canSave"
          id="v-btn-save-tipoPrestadores-financiador"
        >
          <template v-if="isGuardarTipoPrestadorLoading">
            <v-progress-circular
              indeterminate
              color="white"
              size="25"
            ></v-progress-circular>
          </template>
          <template v-else> Guardar </template>
        </v-btn>
      </v-card-actions>
    </v-container>
    <v-dialog :max-width="isMobile ? '95%' : '30%'" v-model="dialogUnsavedChanges" persistent>
      <unsaved-changes-confirm-dialog
        v-if="dialogUnsavedChanges"
        @confirmar="closeDialogs"
        @confirmar-cancelado="closeUnsavedChangesDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import UnsavedChangesConfirmDialog from "@/components/shared/UnsavedChangesConfirmDialog.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    UnsavedChangesConfirmDialog,
  },
  props: {
    financiadorItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      financiadorName: `${this.financiadorItem.financiadorNom}`,
      financiadorId: null,
      provinciaSelected: null,
      itemsProvincias: [],
      provinciaDefault: null,
      tipoPrestadorSelected: [],
      itemsTipoPrestador: [],
      selectAllTipoPrestadorCheckbox: false,
      itemsTipoPrestadorDelFinanciador: [],
      search: "",
      searchIcon: enums.icons.SEARCH_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      isDataTableTipoPrestadorLoading: false,
      isSelectLoading: false,
      isTipoPrestadorSelectLoading: false,
      isGuardarTipoPrestadorLoading: false,
      openDeleteFinanciadorDialog: false,
      dialogUnsavedChanges: false,
      itemsTipoPrestadorDelFinanciadorOriginal: [],
      headers: [
        {
          title: "Provincia",
          sortable: false,
          key: "provincia.value",
        },
        {
          title: "Tipo de prestador",
          sortable: false,
          key: "tipoPreNom",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile",
    }),
    permisosTiposPrestadores() {
      let canSave = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          x.permisoHijos?.forEach((element) => {
            switch (element.permisoCodigo) {
              case enums.permises.GUARDAR_CONFIGURACION_TIPOS_PRESTADORES.code:
                canSave = true;
                break;
              default:
                break;
            }
          });
        });
      }
      return { canSave };
    },
    validarSelectsComputed() {
      return (
        this.tipoPrestadorSelected.length > 0 ||
        (this.provinciaSelected !== null &&
          this.tipoPrestadorSelected.length > 0)
      );
    },
  },
  async created() {
    this.setSelects();
    await this.getTipoDePrestadorDelFinanciador();
    this.itemsTipoPrestadorDelFinanciadorOriginal =
      this.itemsTipoPrestadorDelFinanciador.slice();
  },
  methods: {
    ...mapActions({
      getProvincias: "taxonomy/getProvincias",
      getTiposPrestadores: "taxonomy/getTiposPrestadores",
      postTiposPrestadores: "taxonomy/postTiposPrestadores",
      setAlert: "user/setAlert",
    }),
    toggleSelectAll() {
      this.selectAllTipoPrestadorCheckbox
        ? (this.tipoPrestadorSelected = this.itemsTipoPrestador.slice())
        : (this.tipoPrestadorSelected = []);
    },
    handleTipoPrestadoresSelectedChange() {
      if (this.tipoPrestadorSelected.length === 0)
        this.selectAllTipoPrestadorCheckbox = false;
    },
    async filtrarTipoPreXProvincia() {
      this.tipoPrestadorSelected = [];
      await this.setPrestadores();
      const prestadoresEnProvincia = this.itemsTipoPrestadorDelFinanciador
        ?.filter(
          (item) =>
            item.provincia.id === this.provinciaSelected?.id ??
            this.provinciaDefault
        )
        ?.map((item) => item.tipoPreId);

      this.itemsTipoPrestador = this.itemsTipoPrestador?.filter(
        (item) => !prestadoresEnProvincia.includes(item.id)
      );
    },
    async setSelects() {
      this.isSelectLoading = true;
      this.itemsProvincias = await this.getProvincias();
      this.provinciaDefault = this.itemsProvincias.find(
        (item) => item.id == enums.enums.PROVINCIA_SD_ID
      );
      this.itemsProvincias = this.itemsProvincias.filter(
        (item) => item.id !== enums.enums.PROVINCIA_SD_ID
      );
      this.isSelectLoading = false;
    },
    async setPrestadores() {
      this.isTipoPrestadorSelectLoading = true;
      this.itemsTipoPrestador = await this.getTiposPrestadores(
        this.financiadorItem.financiadorId
      );
      this.isTipoPrestadorSelectLoading = false;
    },
    async getTipoDePrestadorDelFinanciador() {
      this.isDataTableTipoPrestadorLoading = true;
      await this.setPrestadores();

      this.itemsTipoPrestadorDelFinanciador =
        this.financiadorItem?.financiadoresXTipoPrestadores;

      this.itemsTipoPrestadorDelFinanciador.map((item) => {
        item.tipoPre = this.itemsTipoPrestador?.find(
          (x) => x.id === item.tipoPreId
        );
        item.tipoPreNom = this.itemsTipoPrestador?.find(
          (x) => x.id === item.tipoPreId
        ).value;
        return item;
      });
      this.isDataTableTipoPrestadorLoading = false;
    },
    async añadirPrestador() {
      this.tipoPrestadorSelected.forEach((tipoPrestador) => {
        const prestador = {
          provincia: this.provinciaSelected,
          tipoPreId: tipoPrestador.id,
          tipoPreNom: tipoPrestador.value,
        };
        this.itemsTipoPrestadorDelFinanciador.push(prestador);
      });
      this.filtrarTipoPreXProvincia();
      this.tipoPrestadorSelected = [];
      this.selectAllTipoPrestadorCheckbox = false;
    },
    async eliminarTipoPrestador(registro) {
      this.itemsTipoPrestadorDelFinanciador =
        this.itemsTipoPrestadorDelFinanciador.filter(
          (item) => item !== registro
        );
      this.filtrarTipoPreXProvincia();
    },
    async eliminarTodosTiposPrestadores() {
      this.itemsTipoPrestadorDelFinanciador = [];
      this.filtrarTipoPreXProvincia();
    },
    async guardarTiposPrestadoresByFinanciadores() {
      this.isGuardarTipoPrestadorLoading = true;
      const prestadores = this.itemsTipoPrestadorDelFinanciador.map(
        (prestador) => {
          return {
            provincia: prestador.provincia,
            tipoPreId: prestador.tipoPreId,
          };
        }
      );
      const payload = {
        financiadoresTiposPre: prestadores,
        financiadorId: this.financiadorItem.financiadorId,
      };
      try {
        const resp = await this.postTiposPrestadores(payload);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito ",
          });
          this.$emit("reloadFinanciadores");
        }
      } catch (error) {
        console.error(error);
      }
      this.isGuardarTipoPrestadorLoading = false;
      this.$emit("reloadFinanciadores");
      this.closeDialogs();
    },
    validateSameArraysTipoPrestador() {
      let isValid = true;
      // validacion por largo de elementos de un array
      if (
        this.itemsTipoPrestadorDelFinanciadorOriginal.length !==
        this.itemsTipoPrestadorDelFinanciador.length
      )
        return (isValid = false);
      // validacion por contenido de registro
      this.itemsTipoPrestadorDelFinanciadorOriginal.forEach((registro) => {
        let objeto = this.itemsTipoPrestadorDelFinanciador.find(
          (x) =>
            x.provincia.id === registro.provincia.id &&
            x.tipoPreId == registro.tipoPreId
        );
        if (!objeto) isValid = false;
      });
      return isValid;
    },
    validateBeforeClose() {
      if (!this.validateSameArraysTipoPrestador()) {
        this.openUnsavedChangesDialog();
      } else {
        this.$emit("cerrarVerTipoPrestadoresDialog");
      }
    },
    closeDialogs() {
      this.$emit("cerrarVerTipoPrestadoresDialog");
      this.closeUnsavedChangesDialog();
    },
    closeUnsavedChangesDialog() {
      this.dialogUnsavedChanges = false;
    },
    openUnsavedChangesDialog() {
      this.dialogUnsavedChanges = true;
    },
  },
};
</script>
