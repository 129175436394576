import axios from "axios";

export default {
  updateFinanciadorId({ commit }, id) {
    commit("setFinanciadorId", id);
  },
  async getFinanciadores() {
    try {
      const response = await axios.get(`api/financiadores/get-financiadores`);
      return response?.data;
    } catch (error) {
      console.error("Error fetching financiadores:", error);
    }
  },
  async postFinanciador(context, payload) {
    try {
      const response = await axios.post(
        "api/financiadores/save-financiador",
        payload
      );
      return response;
    } catch (error) {
      console.error("Error al guardar cambios financiador", error);
    }
  },
  async getFinanciadoresReportes(context, payload) {
    try {
      const response = await axios.get(
        `api/financiadores/reportes-config/${payload}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error fetching financiadores:", error);
    }
  },
  async postFinanciadoresReportes(context, payload) {
    try {
      const response = await axios.post(
        "api/financiadores/reportes-config",
        payload
      );
      return response;
    } catch (error) {
      console.error("Error al guardar cambios financiador", error);
    }
  },
  async deleteFinanciador(context, payload) {
    try {
      const response = await axios.delete(
        `api/financiadores/delete-financiador/${payload}`
      );
      return response;
    } catch (error) {
      console.error("Error al eliminar financiador", error);
    }
  },
  async getValidations(_, payload) {
    try {
      return (await axios.get(`/api/financiadores/validations/${payload}`))
        ?.data;
    } catch (error) {
      console.error("Error al traer los datos del prestador", error);
    }
  },
};
