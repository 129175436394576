const permisos = Object.freeze({
  HOME: { path: "/", name: "home", code: "", desc: "" },
  FINANCIADORES: { path: "/financiadores", name: "Financiadores", code: "71FE5DFC-F612-40C4-AABD-A3981BB7C287", desc: "Gestionar usuarios y otros datos del financiador." },
  EMISORES: { path: "/emisores", name: "Entidades Emisoras", code: "7337FA44-37B9-43B5-8488-EB2DCF5297AC", desc: "Gestionar entidades emisoras del sistema agregando, editando y/o asociando a financiadores." },
  PRESCRIPCIONES: { path: "/prescripciones", name: "Prescripciones", code: "77F172EE-1AA7-461F-8621-CAB4BC76C587", desc: "Consultar prescripciones captadas por el sistema." },
  USUARIOS: { path: "/usuarios", name: "Usuarios", code: "3261E798-0E1A-4A77-9ACE-E48E786011FD", desc: "Gestionar usuarios del sistema." },
  PERMISOS: { path: "/permisos", name: "Permisos", code: "4040C785-45B5-4A57-A1EA-74F118873ED2", desc: "Gestionar permisos" },
  GRUPOSDEUSUARIOS: { path: "/grupos-de-usuarios", name: "Grupos de usuarios", code: "F01AA2B9-BFD0-4EC3-B5D7-D46D13997A5E", desc: "Gestionar grupos de usuarios del sistema." },
  MANUALUSUARIO: { path: "/manual-usuario", name: "Manual de usuario", code: "AB879E23-2524-481A-B915-986F3EF0E5F7", desc: "Consulta de instructivos para el uso del sistema." },
  CREAR_FINANCIADOR: { path: null, name: "Crear financiador", code: "0D607518-F8E2-483C-A78C-5DA49F8A3439", desc: "" },
  EDITAR_FINANCIADOR: { path: null, name: "Editar financiador", code: "8C92F613-F71B-4948-83B8-9841D315B419", desc: "" },
  ELIMINAR_FINANCIADOR: { path: null, name: "Eliminar financiador", code: "6C979092-613E-4A7B-8697-E97B7443CFA9", desc: "" },
  CREAR_EMISOR: { path: null, name: "Crear emisor", code: "27496A80-CC3C-43BD-9932-35EA98B6ACC6", desc: "" },
  EDITAR_EMISOR: { path: null, name: "Editar emisor", code: "E42AC68A-DF8B-4038-B73E-88E42478EECA", desc: "" },
  ELIMINAR_EMISOR: { path: null, name: "Eliminar emisor", code: "53803117-0020-4E0C-A7AB-4884AFB06B07", desc: "" },
  VER_FINANCIADORES_EMISOR: { path: null, name: "Ver financiadores de emisor", code: "5430C19A-E49E-4035-BAF1-5FEF481D419D", desc: "" },
  CREAR_USUARIO: { path: null, name: "Crear usuario", code: "0B710891-0EF6-48AF-AF84-F5F0A0A568B1", desc: "" },
  EDITAR_USUARIO: { path: null, name: "Editar usuario", code: "F4D98EA2-641C-427D-B416-443617384F62", desc: "" },
  ELIMINAR_USUARIO: { path: null, name: "Eliminar usuario", code: "20F4B62B-D451-428D-976B-68FD1997749E", desc: "" },
  CREAR_PERMISO: { path: null, name: "Crear permiso", code: "28A50218-9D60-4E16-A357-7F4356A49ABB", desc: "" },
  EDITAR_PERMISO: { path: null, name: "Editar permiso", code: "7868DA98-D664-4229-95C2-C9A664DFD1E0", desc: "" },
  ELIMINAR_PERMISO: { path: null, name: "Eliminar permiso", code: "C14F131B-4DF7-4317-913B-0B6A7472FD0F", desc: "" },
  ASIGNAR_PERMISOS: { path: null, name: "Asignar permisos", code: "DF6393F6-92A5-4615-9449-E0ECA3BA1029", desc: "" },
  VER_HISTORIAL: { path: null, name: "Ver historial de estados", code: "13BC2D93-823F-4A6C-BE98-29AA4EFEEA40", desc: "" },
  VER_LOG: { path: null, name: "Ver log", code: "191A6461-01CC-4EBA-9940-51BB91F7C062", desc: "" },
  RESTORE_PASSWORD: { path: null, name: "Restaurar contraseña", code: "DAE31A43-61D9-4DD1-9B3E-5D92FC670C9A", desc: "" },
  VER_TIPOS_PRESTADORES: { path: null, name: "Ver tipos prestadores", code: "1CCCA0A2-5230-4254-9E89-BB6AECCA22B9", desc: "" },
  GUARDAR_CONFIGURACION_TIPOS_PRESTADORES: { path: null, name: "Guardar configuración tipos prestadores", code: "6E8D7BE0-0F30-495F-A5CE-D4B8C612B4E0", desc: "" },
  DISPENSAR_RECETA: { path: null, name: "Dispensar receta", code: "55D75EFF-1892-412E-A057-FFF2A1F71BCA", desc: "" },
  CREAR_RECETA: { path: null, name: "Crear receta", code: "A9F68312-6A6D-4EC9-B69E-82FC6F656EBC", desc: "" },
  ANULAR_RECETA: { path: null, name: "Anular receta", code: "8DEC5830-8214-44DC-997D-1C9A8B443779", desc: "" },
  CREAR_FARMACIA: { path: null, name: "Crear farmacia", code: "6D88B540-38B7-48E0-B4D7-02C453D39DF6", desc: "" },
  EDITAR_FARMACIA: { path: null, name: "Editar farmacia", code: "A9D98FD5-EC8E-4991-9756-303FFD91C836", desc: "" },
  ELIMINAR_FARMACIA: { path: null, name: "Eliminar farmacia", code: "6B75BE15-5503-407C-94C9-9DBA8003D89F", desc: "" },
  CREAR_GRUPOUSUARIO: { path: null, name: "Crear grupo de usuario", code: "D0AEAAD8-FBFD-47D1-B99D-F5E2BEFCA0AE", desc: "" },
  EDITAR_GRUPOUSUARIO: { path: null, name: "Editar grupo de usuario", code: "7B4C40D6-9A17-40B3-ADDE-0417463CCCBD", desc: "" },
  ELIMINAR_GRUPOUSUARIO: { path: null, name: "Eliminar grupo de usuario", code: "59142693-A4AB-4098-AF36-D6FDBAE0644F", desc: "" },
  GESTION_USUARIOS_EMISORES: { path: null, name: "Gestión de usuarios emisores", code: "9301D7BA-2259-4EE0-8C55-A0F2652FFD14", desc: "" },
  VER_REPORTES_CONFIGURACION: { path: null, name: "Ver reportes configuración", code: "C8B25C7F-563C-4C02-9EC5-84BE957BFF07", desc: "" },
  GESTION_FARMACIAS: { path: null, name: "Gestión de farmacias", code: "CF6CDA77-3E49-4E7B-B390-0C23367AB0EA", desc: "" },
  REMOVE_QR: { path: null, name: "Generar código QR", code: "DD8995B4-64A6-4486-ACFA-E0DBD56DC39A", desc: "" },
});

export default permisos;