import axios from "@/axios/axios-api";

export default {
  async getFarmacias(_, payload) {
    try {
      return (await axios.get(`/api/farmacias/get-farmacias/${payload}`))?.data;
    } catch (error) {
      console.error(`No se pudo traer los datos, ${error}`);
    }
  },
  async saveFarmacia(_, payload) {
    try {
      const res = await axios.post("/api/Farmacias/save-farmacia", payload);
      return res;
    } catch (error) {
      console.error(`No se pudo guardar datos, ${error}`);
    }
  },
  async deleteFarmacia(_, payload) {
    try {
      const res = await axios.delete(
        `/api/farmacias/delete-farmacia?farmaciaId=${payload.farmaciaId}&farmaciaXFinId=${payload.farmaciaXFinId}&usuarioId=${payload.usuarioId}`
      );
      return res;
    } catch (error) {
      console.error(`No se pudo elimiar este registro, ${error}`);
    }
  },
  async searchFarmaciaByCuit(context, payload) {
    try {
      let url = `api/farmacias/search-farmacia?cuit=${payload.cuit}&financiadorId=${payload.financiadorId}`;

      if (payload.farmaciaId != null) {
        url += `&farmaciaId=${payload.farmaciaId}`;
      }

      const response = await axios.get(url);
      return response;

    } catch (error) {
      console.error("Error al traer farmacias", error);
    }
  },
  async changeUserEnabledStatus(context, payload) {
    try {
      const response = await axios.get(
        `api/farmacias/change-user-status/${payload}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error al cambiar el estado del usuario", error);
    }
  },
};
