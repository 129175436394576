import axios from "axios";

export default {
  async getEmisores() {
    try {
      const response = await axios.get(`api/taxonomy/emisores`);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los emisores", error);
    }
  },
  async getEmisoresByUser() {
    try {
      const response = await axios.get(`api/taxonomy/emisores-by-user`);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los emisores", error);
    }
  },
  async getFinanciadores() {
    try {
      const response = await axios.get(`api/taxonomy/financiadores`);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los financiadores", error);
    }
  },
  async getGruposUsuarios() {
    try {
      const response = await axios.get(`api/taxonomy/grupos-usuarios`);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los grupos de usuario", error);
    }
  },
  async getGruposUsuariosByUser() {
    try {
      const response = await axios.get(`api/taxonomy/grupos-usuarios-by-user`);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los grupos de usuario", error);
    }
  },
  async getFinanciadoresByUser() {
    try {
      return (await axios.get("api/Taxonomy/financiadores-by-user"))?.data;
    } catch (error) {
      console.error("Error al traer los financiadores", error);
    }
  },
  async getEstados() {
    try {
      return (await axios.get("/api/taxonomy/estados"))?.data;
    } catch (error) {
      console.error("Error al traer los estados", error);
    }
  },
  async fetchAfiliadosSolicitudesByQuery(context, payload) {
    const response = await axios.get(
      `api/taxonomy/afiliados-solicitudes-by-query?request=${payload.input}`
    );
    return response.data;
  },
  async getMatriculas() {
    try {
      return (await axios.get("/api/Taxonomy/tipos-matricula"))?.data;
    } catch (error) {
      console.error("Error al traer las matriculas", error);
    }
  },
  async getProvincias() {
    try {
      return (await axios.get("/api/Taxonomy/provincias"))?.data;
    } catch (error) {
      console.error("Error al traer las provincias", error);
    }
  },
  async getFarmacias() {
    try {
      return (await axios.get("/api/Taxonomy/farmacias"))?.data;
    } catch (error) {
      console.error("Error al traer las farmacias", error);
    }
  },
  async getFarmaciasByUser() {
    try {
      return (await axios.get("/api/Taxonomy/farmacias-by-user"))?.data;
    } catch (error) {
      console.error("Error al traer las farmacias", error);
    }
  },
  async getTiposPrestadores(_, financiadorId) {
    try {
      return (
        await axios.get(`/api/Taxonomy/tipos-prestadores/${financiadorId}`)
      )?.data;
    } catch (error) {
      console.error("Error al traer los Prestadores", error);
    }
  },
  async getProvinciasTiposPrestadores(_, payload) {
    try {
      return (
        await axios.get(
          `/api/Taxonomy/provincias-tipos-prestadores/${payload.financiadorId}-${payload.tipoId}`
        )
      )?.data;
    } catch (error) {
      console.error("Error al traer los Prestadores", error);
    }
  },
  async getReportes(_, financiadorId) {
    try {
      return (await axios.get(`/api/Taxonomy/reportes/${financiadorId}`))?.data;
    } catch (error) {
      console.error("Error al traer los Prestadores", error);
    }
  },
  async getTiposSolicitudes() {
    try {
      return (await axios.get(`/api/Taxonomy/tipos-solicitudes`))?.data;
    } catch (error) {
      console.error("Error al traer los Prestadores", error);
    }
  },
  async postTiposPrestadores(_, payload) {
    try {
      const response = await axios.post(
        "/api/Financiadores/tipos-prestadores-financiador",
        payload
      );
      return response;
    } catch (error) {
      console.error("Error al guardar los Prestadores al financiador", error);
    }
  },
  async getServicios() {
    try {
      return (await axios.get("/api/Taxonomy/servicios"))?.data;
    } catch (error) {
      console.error("Error al traer los servicios", error);
    }
  },
  async getTipoPermisos() {
    try {
      return (await axios.get("/api/Taxonomy/tipos-permiso"))?.data;
    } catch (error) {
      console.error("Error al traer los tipos permisos", error);
    }
  },
  async getPlanesByFinanciador(context, payload) {
    try {
      const response = await axios.get(
        `api/taxonomy/planes-by-financiador?financiadorId=${payload.financiadorId}&fecha=${payload.fecha}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los planes del financiador", error);
    }
  },
  async getTiposDocumentos() {
    try {
      return (await axios.get("/api/Taxonomy/tipos-documentos"))?.data;
    } catch (error) {
      console.error("Error al traer los tipos documentos", error);
    }
  },
  async getTiposDocumentoByFinanciador(context, payload) {
    try {
      return (
        await axios.get(
          `/api/taxonomy/tipos-documento-by-financiador?financiadorId=${payload.financiadorId}`
        )
      )?.data;
    } catch (error) {
      console.error(
        "Error al traer los tipos documentos del financiador",
        error
      );
    }
  },
  async fetchPrestadoresByQuery(context, payload) {
    const response = await axios.get(
      `api/taxonomy/prestadores-by-query?request=${payload.input}&tipoMatricula=${payload.tipoMatricula}&financiadorId=${payload.financiadorId}`
    );
    return response.data;
  },
  async fetchMedicamentosByParams(context, payload) {
    const response = await axios.post(
      `api/taxonomy/medicamentos-by-params?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
      payload
    );
    return response.data;
  },
  async fetchAfiliadosFinanciadorByQuery(context, payload) {
    const response = await axios.get(
      `api/taxonomy/afiliados-financiador-by-query?fecha=${payload.fecha}&request=${payload.input}&financiadorId=${payload.financiadorId}`
    );
    return response.data;
  },
  async getAfiliadoByNroAndFinanciador(context, payload) {
    const response = await axios.get(
      `api/taxonomy/afiliado-by-numero-and-financiador?numero=${payload.numero}&financiadorId=${payload.financiadorId}`
    );
    if (response.status === 204) return null;
    else return response.data;
  },
};
