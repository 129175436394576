<template>
  <v-app-bar app elevate-on-scroll dark color="primary">
    <div class="ml-8">
      <v-row>
        <v-col cols="1">
          <v-tooltip
            :text="inHome ? enums.titles.PRODUCT : enums.messages.GO_TO_HOME"
            location="start"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                icon
                class="v-btn v-btn--text theme--dark v-size--default"
                @click="clickHome"
                v-bind="props"
              >
                <v-icon class="logo">
                  <img
                    src="/favicon.ico"
                    :alt="enums.titles.PRODUCT"
                    class="custom-icon"
                  />
                </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-col
          cols="11"
          class="d-flex align-center"
          style="justify-content: end"
        >
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class="v-btn v-btn--text theme--dark v-size--default pr-1"
              >
                {{ userName }}
                <v-icon size="22px" class="ma-2">{{ userIcon }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in userMenu"
                :key="i"
                :id="'v-btn-' + item"
              >
                <v-list-item-title>
                  <v-btn
                    class="v-btn v-btn--text theme--light"
                    flat
                    @click="handleItemClick(item)"
                    id="v-btn-item-menu-navbar"
                  >
                    {{ item }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
  </v-app-bar>
  <change-password-popup
    v-if="showChangePassword"
    @close="closeModalChangePassword"
  />
</template>

<script>
import ChangePasswordPopup from "../layout/ChangePassword.vue";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index";

export default {
  components: {
    ChangePasswordPopup,
  },
  props: {
    inHome: {
      type: Boolean,
    },
  },
  data() {
    return {
      homeIcon: enums.icons.HOME_ICON,
      userIcon: enums.icons.USER_ICON,
      userMenu: ["Cambiar contraseña", "Cerrar sesión"],
      showChangePassword: false,
      enums: enums,
    };
  },
  computed: {
    ...mapGetters("logIn", ["userName"]),
  },
  created() {
    const storedUserName = sessionStorage.getItem("userName");
    if (storedUserName) {
      this.$store.commit("logIn/setUserName", storedUserName);
    }
    const changePassword = sessionStorage.getItem("changePassword") === "true";
    if (changePassword) {
      this.setAlert({
        type: "warning",
        message: "Es necesario cambiar la contraseña para continuar.",
      });
      this.showChangePassword = true;
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
    }),
    handleItemClick(item) {
      if (item === "Cambiar contraseña") {
        this.openModalChangePassword();
      } else if (item === "Cerrar sesión") {
        this.logOut();
      }
    },
    openModalChangePassword() {
      this.showChangePassword = true;
    },
    closeModalChangePassword() {
      this.showChangePassword = false;
    },
    logOut() {
      sessionStorage.setItem("token", "");
      sessionStorage.removeItem("userName");
      this.$router.push("/login");
      this.$store.commit("menu/setPermisos", []);
    },
    clickHome() {
      if (this.inHome) location.reload();
      else this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.custom-icon {
  width: 40px;
  height: 40px;
  filter: contrast(100%) grayscale(100%);
}
.ml-8,
.v-row {
  width: 100%;
}
</style>
