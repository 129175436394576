<template class="bloqueado">
  <div>
    <v-overlay
      :model-value="true"
      persistent
      class="align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        width="10"
        size="100"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "LoaderProgress",
  loading: true,
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  mounted() {
    setTimeout(() => {
      this.destroyLoader()
    }, 60000);
  },
  watch: {},
  methods: {
    destroyLoader() {
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
      });
    },
  },
};
</script>
<style scoped>
.bloqueado {
  pointer-events: none;
}
.custom-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.custom-progress {
  margin-top: -20vh;
  padding: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>