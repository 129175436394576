import axios from "@/axios/axios-api";

export default {
  async getEmisores(_, payload) {
    try {
      const res = await axios.get(`/api/emisores/get-emisores/${payload}`);
      return res?.data;
    } catch (error) {
      console.error(`No se pudo traer los datos, ${error}`);
    }
  },
  async searchEmisorByCuit(_, payload) {
    try {
      let url = `api/emisores/search-emisor?cuit=${payload.cuit}&financiadorId=${payload.financiadorId}`;

      if (payload.emisorId != null) {
        url += `&emisorId=${payload.emisorId}`;
      }

      const response = await axios.get(url);
      return response;

    } catch (error) {
      console.error(`No se pudo traer los datos, ${error}`);
      return undefined;
    }
  },
  async saveEmisor(_, payload) {
    try {
      const res = await axios.post("/api/Emisores/save-emisor", payload);
      return res;
    } catch (error) {
      console.error(`No se pudo guardar datos, ${error}`);
    }
  },
  async deleteEmisor(_, payload) {
    try {
      const res = await axios.delete(
        `/api/emisores/delete-emisor?emisorId=${payload.emisorId}&emisorXFinId=${payload.emisorXFinId}&usuarioId=${payload.usuarioId}`
      );
      return res;
    } catch (error) {
      console.error(`No se pudo elimiar este registro, ${error}`);
    }
  },
  async postFinanciadorEmisor(_, payload) {
    try {
      const response = await axios.post(
        "api/Emisores/save-financiadores-emisor",
        payload
      );
      return response;
    } catch (error) {
      console.error("Error al guardar los financiadores al emisor", error);
    }
  },
  async changeUserEnabledStatus(_, payload) {
    try {
      const response = await axios.post(
        `api/emisores/change-user-status/${payload}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error al cambiar el estado del usuario", error);
    }
  },
};
