<template>
  <v-col xl="6" lg="6" md="6" sm="6" xs="12" class="align-center justify-center">
    <li>
      <router-link :to="link" class="no-underline" @click="setAppLoading">
        <v-card outlined class="home-cards" elevation="5">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="isLoading"
          ></v-progress-linear>
          <v-card-title class="text-button">
            {{ title }}
          </v-card-title>
          <v-card-subtitle class="text-body-1">
            {{ description }}
          </v-card-subtitle>
        </v-card>
      </router-link>
    </li>
  </v-col>
</template>

<script>

export default {
  methods:{
    setAppLoading(){
      this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
    });
    }
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    link: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.home-cards {
  border: 1px solid #333333;
  border-radius: 1rem;
  padding: 10px;
  height: 150px;
  width: auto;
  margin: 5px;
}
.home-cards:hover {
  background-color: #e0f2f1;
  border-color: #006666;
  color: #006666;
}
.no-underline {
  text-decoration: none;
}

.text-body-1 {
  white-space: normal;
  font-size: 18px;
}
.text-button {
  font-size: 20px !important;
}
</style>
