<template>
  <v-app>
    Main
    <NavBar :inHome="false" />
    <v-main>
      <LoaderProgress
        v-if="appLoading.status"
        :text="appLoading.text"
        :opaque="appLoading.opaque"
      />

      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
import NavBar from "@/components/layout/NavBar.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import LoaderProgress from "./LoaderProgress.vue";
import { mapGetters } from "vuex";

export default {
  name: "main-page",
  components: {
    NavBar,
    TheFooter,
    LoaderProgress,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      appLoading: "user/appLoading",
    }),
  },
  beforeCreate() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style></style>
