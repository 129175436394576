<template>
  <v-container class="py-0 my-0">
    <PageHeader :title="pageTitle" :path="goToPath" />
    <v-card elevation="1">
      <v-container class="mobile-container">
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="search"
              :append-icon="searchIcon"
              label="Buscar"
              single-line
              hide-details
              variant="underlined"
              clearable
              id="search-bar"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            class="d-flex justify-end"
            :class="isMobile ? 'mt-4' : 'mt-2'"
          >
            <v-btn
              v-if="permisosEmisores.canCreate"
              color="primary"
              variant="flat"
              @click="openEditDialog()"
              id="v-btn-nuevo"
              >Nuevo</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-card-text class="py-0 my-0">
        <v-row>
          <v-col cols="12" class="px-0 mx-0">
            <v-data-table
              :items-per-page="itemsPerPage"
              item-value="emisorId"
              :headers="isMobile ? headersMobile : headers"
              :mobile="isMobile"
              :hide-default-header="isMobile"
              class="pa-3"
              :items="emisores"
              :search="search"
              :loading="isDataTableLoading"
            >
              <template v-slot:[`item.deshabilitado`]="{ item }">
                <v-icon small :color="item.deshabilitado ? 'red' : 'green'">
                  {{ item.deshabilitado ? crossIcon : checkIcon }}
                </v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="changeUserStatus(item)"
                      id="v-icon-edit-state"
                    >
                      {{
                        item.deshabilitado ? enableUserIcon : disableUserIcon
                      }}
                    </v-icon>
                  </template>
                  <span>{{
                    item.deshabilitado
                      ? "Habilitar usuario"
                      : "Deshabilitar usuario"
                  }}</span>
                </v-tooltip>
                <v-tooltip text="Ver financiadores" location="start">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      v-bind="props"
                      @click="openVerFinanciadores(item)"
                      id="v-icon-ver-financiador"
                      v-if="permisosEmisores.canVerFinanciadores"
                    >
                      {{ bookIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip text="Editar emisor" location="start">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openEditDialog(item)"
                      v-bind="props"
                      v-if="permisosEmisores.canEdit"
                      id="v-icon-edit-emisor"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip text="Eliminar emisor" location="start">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openDeleteDialog(item)"
                      v-bind="props"
                      v-if="permisosEmisores.canDelete"
                      id="v-icon-delete-emisor"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    Observación: {{ item.observaciones }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      :max-width="isMobile ? '95%' : '50%'"
      v-model="dialogEmisor"
      persistent
    >
      <EditEmisor
        :emisorItem="emisorItem"
        :financiadorId="financiadorId"
        @cerrarDialogo="closeEditDialog"
        @reloadEmisores="loadEmisores"
      />
    </v-dialog>
    <v-dialog
      :max-width="isMobile ? '95%' : '60%'"
      v-model="dialogVerFinanciadores"
      persistent
    >
      <VerFinanciadores
        :emisorItem="emisorItem"
        @reloadEmisores="loadEmisores"
        @cerrarVerFinanciadoresDialog="closeVerFinanciadoresDialog"
      />
    </v-dialog>
    <v-dialog
      :max-width="isMobile ? '95%' : '30%'"
      v-model="dialogDelete"
      persistent
    >
      <DeleteDialog
        v-if="dialogDelete"
        title="emisor"
        :isLoading="isLoadingDelete"
        :subtitle="deleteSubtitle"
        @eliminar-confirmado="borrarEmisor"
        @eliminar-cancelado="closeDeleteDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import EditEmisor from "@/components/modules/emisores/EditEmisor.vue";
import VerFinanciadores from "@/components/modules/emisores/VerFinanciadores.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "emisores-page",
  components: {
    EditEmisor,
    PageHeader,
    VerFinanciadores,
    DeleteDialog,
  },
  data() {
    return {
      deleteSubtitle: enums.messages.EMISORES_DELETE_SUBTITLE,
      disableUserIcon: enums.icons.DISABLE_USER_ICON,
      enableUserIcon: enums.icons.ENABLE_USER_ICON,
      checkIcon: enums.icons.CHECK_ICON,
      crossIcon: enums.icons.CROSS_ICON,
      itemsPerPage: 10,
      search: "",
      headers: [
        {
          text: "",
          width: "2%",
          value: "data-table-expand",
          sortable: false,
        },
        {
          title: "Nombre comercial",
          sortable: false,
          key: "emisorNom",
        },
        {
          title: "Razón social",
          sortable: false,
          key: "razonSocial",
        },
        {
          title: "CUIT",
          sortable: false,
          key: "cuit",
        },
        {
          title: "Email",
          sortable: false,
          key: "emisorEmail",
        },
        {
          title: "Teléfono",
          sortable: false,
          key: "telefono",
        },
        {
          title: "Habilitado",
          sortable: false,
          key: "deshabilitado",
          align: "center",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
      headersMobile: [
        
        {
          title: "Nombre comercial",
          sortable: false,
          key: "emisorNom",
        },
        {
          title: "Razón social",
          sortable: false,
          key: "razonSocial",
        },
        {
          title: "CUIT",
          sortable: false,
          key: "cuit",
        },
        {
          title: "Email",
          sortable: false,
          key: "emisorEmail",
        },
        {
          title: "Teléfono",
          sortable: false,
          key: "telefono",
        },
        {
          title: "Habilitado",
          sortable: false,
          key: "deshabilitado",
          align: "center",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
        {
          text: "",
          width: "2%",
          value: "data-table-expand",
          sortable: false,
        },
      ],
      emisores: [],
      emisorItem: null,
      itemToDelete: null,
      dialogEmisor: false,
      dialogDelete: false,
      dialogVerFinanciadores: false,
      isDataTableLoading: false,
      isLoadingDelete: false,
      pageTitle: enums.permises.EMISORES.name,
      goToPath: enums.permises.FINANCIADORES.path,
      searchIcon: enums.icons.SEARCH_ICON,
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      bookIcon: enums.icons.BOOK_ICON,
      financiadorId: null,
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile",
      getFinanciadorId: "financiador/getFinanciadorId",
    }),
    permisosEmisores() {
      let canCreate = false;
      let canEdit = false;
      let canDelete = false;
      let canVerFinanciadores = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.CREAR_EMISOR.code:
              canCreate = true;
              break;
            case enums.permises.EDITAR_EMISOR.code:
              canEdit = true;
              break;
            case enums.permises.ELIMINAR_EMISOR.code:
              canDelete = true;
              break;
            case enums.permises.VER_FINANCIADORES_EMISOR.code:
              canVerFinanciadores = true;
              break;
            default:
              break;
          }
        });
      }
      return { canCreate, canEdit, canDelete, canVerFinanciadores };
    },
  },
  created() {
    this.financiadorId = this.getFinanciadorId; // Asigno el dato con el financiador cargado en el state
    this.loadEmisores();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
  },
  methods: {
    ...mapActions({
      getEmisores: "emisor/getEmisores",
      deleteEmisor: "emisor/deleteEmisor",
      changeUserEnabledStatus: "emisor/changeUserEnabledStatus",
      setAlert: "user/setAlert",
    }),
    async loadEmisores() {
      this.isDataTableLoading = true;
      this.emisores = await this.getEmisores(this.financiadorId);
      this.isDataTableLoading = false;
    },
    async borrarEmisor() {
      this.isLoadingDelete = true;
      try {
        const data = {
          emisorId: this.itemToDelete.emisorId,
          emisorXFinId: this.itemToDelete.emisorXFinId,
          usuarioId: this.itemToDelete.usuarioId,
        };
        const resp = await this.deleteEmisor(data);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Emisor eliminado con éxito.",
          });
          this.loadEmisores();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
      this.dialogDelete = false;
    },
    async changeUserStatus(item) {
      try {
        const response = await this.changeUserEnabledStatus(item.emisorXFinId);
        if (response) {
          this.setAlert({
            type: "success",
            message: `Usuario ${
              item.deshabilitado ? "habilitado" : "deshabilitado"
            } con éxito.`,
          });
          this.loadEmisores();
        }
      } catch (error) {
        console.error(error);
      }
    },
    openEditDialog(item) {
      this.dialogEmisor = true;
      this.emisorItem = item;
    },
    closeEditDialog() {
      this.dialogEmisor = false;
    },
    openVerFinanciadores(item) {
      this.dialogVerFinanciadores = true;
      this.emisorItem = item;
    },
    closeVerFinanciadoresDialog() {
      this.dialogVerFinanciadores = false;
    },
    openDeleteDialog(item) {
      this.dialogDelete = true;
      this.itemToDelete = item;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
