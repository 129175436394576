import axios from "@/axios/axios-api";

export default {
  async getGruposDeUsuarios() {
    try {
      return (await axios.get('/api/usuarios/grupos'))?.data
    } catch (error) {
      console.error(`No se pudo traer los grupos de usuarios, ${error}`)
    }
  },
  async postGrupoDeUsuario(_, payload) {
    try {
      const res = await axios.post('/api/usuarios/grupos', payload)
      return res
    } catch (error) {
      console.error(`No se pudo guardar el grupo de usuario, ${error}`)
    }
  },
  async deleteGrupoDeUsuario(_, grupoUsuId) {
    try {
      const res = await axios.delete(`/api/usuarios/grupos/${grupoUsuId}`)
      return res
    } catch (error) {
      console.error(`No se pudo borrar el grupo de usuario, ${error}`)
    }
  }
};
