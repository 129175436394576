<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="pt-0 pl-1 mb-3 text-h5">
        {{ mensajeAnularSolicitud }}
      </v-card-title>
      <v-card class="pt-3">
        <v-card-subtitle
          class="pl-5 mb-3"
          :style="isMobile ? 'font-size: 10px' : ''"
        >
          <b>Prescripción N° - {{ solicitudNum }}</b> <br />
          Nombre de afiliado - {{ afiliadoNom }} <br />
          Número afiliado - {{ afiliadoNumero }} <br />
          Número Documento - {{ numeroDocumento }} <br />
          Emisor - {{ emisorName }} <br />
          Número Matrícula - {{ matriculaNum }} <br />
          Financiador - {{ financidaorName }} <br />
          Fecha creada - {{ fechaCreada }}
        </v-card-subtitle>
      </v-card>
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cancelAnularSolicitudDialog"
          id="v-btn-cancel-anular-solicitud"
        >
          Cerrar
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          @click="confirmAnularSolicitud()"
          :disabled="isLoadingDelete"
          :loading="isLoadingDelete"
          id="v-btn-confirm-anular-solicitud"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    solicitudItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      solicitudNum: null,
      afiliadoNom: null,
      emisorName: null,
      financidaorName: null,
      afiliadoNumero: null,
      numeroDocumento: null,
      matriculaNum: null,
      mensajeAnularSolicitud: enums.messages.ANULAR_SOLICITUD_MESSAGE,
      isLoadingDelete: false,
    };
  },
  created() {
    this.setSolicitudDetails();
  },
  computed: {
    ...mapGetters({ isMobile: "menu/isMobile" }),
  },
  methods: {
    ...mapActions({
      anularSolicitud: "solicitud/anularSolicitud",
      setAlert: "user/setAlert",
    }),
    setSolicitudDetails() {
      this.solicitudNum = `${this.solicitudItem.numero}`;
      this.afiliadoNom = `${this.solicitudItem.apeNomAfi}`;
      this.emisorName = `${this.solicitudItem.emisor.value}`;
      this.fechaCreada = `${this.solicitudItem.fechaEmision}`;
      this.financidaorName = `${this.solicitudItem.financiador.value}`;
      this.afiliadoNumero = `${this.solicitudItem.nroAfi}`;
      this.numeroDocumento = `${this.solicitudItem.nroDoc}`;
      this.matriculaNum = `${this.solicitudItem.nroMatricula}`;
    },
    async confirmAnularSolicitud() {
      this.isLoadingDelete = true;
      try {
        const resp = await this.anularSolicitud(this.solicitudNum);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Anulado con éxito.",
          });
          this.cancelAnularSolicitudDialog();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
    cancelAnularSolicitudDialog() {
      this.$emit("anular-cancelado");
    },
  },
};
</script>
