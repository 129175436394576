const helperDate = {
  formatDate(date) {
    if (!date) return null;
    if (typeof date !== "string") {
      date = date.toString();
    }
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const [month, day, year] = date
      .substring(4, 15)
      .replace(/([a-zA-Z]+)/, (match) => months[match])
      .replaceAll(" ", "-")
      .split("-");
    return `${day}/${month}/${year}`;
  },

  // Puse en funcionamiento el otro formatDate ya que este le quita la fecha completa y el v-mask tira error
  
  // formatDate(date) {
  //   return date ? date.toLocaleDateString("es-AR") : null;
  // },

  convertDateFormat(inputDate) {
    if (!inputDate) {
      return null;
    }
    // Split the input date into day, month, and year parts
    var parts = inputDate.split("/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];

    // Create a new Date object with the input date parts
    var newDate = new Date(year, month - 1, day);

    // Get the new date parts in the desired format
    var newYear = newDate.getFullYear();
    var newMonth = ("0" + (newDate.getMonth() + 1)).slice(-2);
    var newDay = ("0" + newDate.getDate()).slice(-2);

    // Concatenate the parts to get the result in "yyyy-MM-dd" format
    var result = newYear + "-" + newMonth + "-" + newDay;

    return result;
  },
};

export default helperDate;
