<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          form="submit-form"
          id="submit-form"
          ref="submit-form"
          v-model="isFormValid"
          @submit.prevent="guardarEmisor()"
        >
          <v-row v-if="!emisorSearched" :no-gutters="isMobile">
            <v-col :cols="isMobile ? 12 : 4" :sm="isMobile ? 12 : 5">
              <v-text-field
                v-model="cuit"
                label="CUIT"
                variant="outlined"
                density="compact"
                v-mask="['##-########-#', '##-#######-#']"
                :rules="
                  cuit == null
                    ? rules.required
                    : rules.required.concat(rules.validateCUITCUIL(cuit))
                "
                id="cuit-text-field"
                @update:model-value="setUserName(cuit)"
              ></v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 3" :md="isMobile ? 12 : 6">
              <v-btn
                color="primary"
                variant="flat"
                @click="searchEmisor()"
                :disabled="cuit == null || cuit == '' || !isFormValid"
              >
                Buscar
              </v-btn>
              <v-tooltip location="right">
                <template v-slot:activator="{ props }">
                  <v-icon color="primary" size="25" class="pl-5" v-bind="props">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>Buscar emisor en el sistema</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <div v-if="emisorSearched">
            <v-row :no-gutters="isMobile">
              <v-col cols="12" class="pb-0">
                <v-card elevation="1">
                  <v-card-title class="text-button"
                    >Datos del emisor</v-card-title
                  >
                  <v-card-text>
                    <v-row :no-gutters="isMobile">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pb-0"
                        :class="isMobile ? 'pb-0' : 'pb-1'"
                      >
                        <v-text-field
                          v-model="nombre"
                          label="Nombre comercial"
                          variant="outlined"
                          density="compact"
                          :rules="
                            [rules.required, rules.maxLength(nombre, 50)].flat()
                          "
                          id="name-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        :cols="isMobile ? 12 : 6"
                        class="py-0"
                        :class="isMobile ? 'pb-0' : 'pb-1'"
                      >
                        <v-text-field
                          v-model="razonSocial"
                          label="Razón social"
                          variant="outlined"
                          density="compact"
                          :rules="
                            [
                              rules.required,
                              rules.maxLength(razonSocial, 100),
                            ].flat()
                          "
                          id="razonSocial-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        :cols="isMobile ? 12 : 6"
                        class="py-0"
                        :class="isMobile ? 'pb-0' : 'pb-1'"
                      >
                        <v-text-field
                          label="CUIT"
                          v-model="cuit"
                          readonly
                          filled
                          density="compact"
                          variant="outlined"
                          v-mask="['##-########-#', '##-#######-#']"
                          :rules="
                            cuit == null
                              ? rules.required
                              : rules.required.concat(
                                  rules.validateCUITCUIL(cuit)
                                )
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col
                        :cols="isMobile ? 12 : 6"
                        class="py-0"
                        :class="isMobile ? 'pb-0' : 'pb-1'"
                      >
                        <v-text-field
                          v-model="telefono"
                          label="Teléfono"
                          variant="outlined"
                          density="compact"
                          :rules="
                            [rules.required, rules.telephoneRules]
                              .concat(rules.maxLength(telefono, 15))
                              .flat()
                          "
                          id="telephone-text-field"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        :cols="isMobile ? 12 : 6"
                        class="py-0"
                        :class="isMobile ? 'pb-0' : 'pb-1'"
                      >
                        <v-textarea
                          v-model="observaciones"
                          label="Observación"
                          auto-grow
                          rows="1"
                          variant="outlined"
                          density="compact"
                          :rules="[rules.maxLength(observaciones, 250)].flat()"
                          id="observacion-text-area"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-card elevation="1">
                  <v-card-title class="text-button"
                    >Datos de usuario</v-card-title
                  >
                  <v-card-text>
                    <v-row :no-gutters="isMobile">
                      <v-col :cols="isMobile ? 12 : 6" sm="6" class="pb-0">
                        <v-text-field
                          readonly
                          label="Nombre de usuario"
                          v-model="usuNom"
                          :rules="rules.required"
                          id="usuario-emisor-userNom"
                          density="compact"
                          variant="outlined"
                          autocomplete="usuario-emisor-userNom"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        :cols="isMobile ? 12 : 6"
                        v-if="emisorNotFound"
                        class="pb-0"
                      >
                        <v-text-field
                          type="email"
                          v-model="email"
                          variant="outlined"
                          density="compact"
                          label="Email"
                          :rules="[rules.required, rules.emailRules].flat()"
                          id="email-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cerrarDialogo"
          id="v-btn-close-edit"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          variant="flat"
          form="submit-form"
          :disabled="!isFormValid || isLoadingSave"
          id="v-btn-save-edit"
        >
          <template v-if="isLoadingSave">
            <v-progress-circular
              indeterminate
              color="white"
              size="25"
            ></v-progress-circular>
          </template>
          <template v-else> Confirmar </template>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules.js";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditEmisor",
  emits: ["cerrarDialogo", "reloadEmisores"],
  props: {
    emisorItem: {
      type: Object,
      required: false,
    },
    financiadorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      nombre: "",
      email: "",
      telefono: "",
      razonSocial: "",
      observaciones: "",
      isFormValid: false,
      infoIcon: enums.icons.SNB_INFO,
      usuNom: null,
      formTitle: "",
      rules: rules,
      isLoadingSave: false,
      cuit: null,
      emisorSearched: false,
      emisorId: null,
      disableUser: false,
      emisorFounded: false,
      emisorNotFound: false,
    };
  },
  async created() {
    if ((this.emisorItem ?? null) != null) {
      this.cuit = this.emisorItem.cuit;
      this.searchEmisor();
      this.formTitle = `Editar emisor – ${this.emisorItem.emisorNom}`;
    } else {
      this.formTitle = "Nuevo emisor";
    }
  },
  computed: {
    ...mapGetters({ isMobile: "menu/isMobile" }),
  },
  methods: {
    ...mapActions({
      saveEmisor: "emisor/saveEmisor",
      searchEmisorByCuit: "emisor/searchEmisorByCuit",
      setAlert: "user/setAlert",
      getFiltersByUser: "solicitud/getFiltersByUser",
      getFinanciadores: "taxonomy/getFinanciadores",
    }),
    async searchEmisor() {
      const data = {
        cuit: this.cuit.replaceAll("-", ""),
        financiadorId: this.financiadorId,
        emisorId: this.emisorItem != null ? this.emisorItem.emisorId : null,
      };
      try {
        const response = await this.searchEmisorByCuit(data);

        if (response.status === 200) {
          this.emisorSearched = true;
          this.emisorFounded = true;
          if (this.emisorItem == null) {
            this.setAlert({
              type: "success",
              message:
                "Se ha encontrado un emisor existente en el sistema con los datos ingresados.",
            });
          }
          this.nombre = response.data.emisorNom;
          this.razonSocial = response.data.razonSocial;
          this.usuNom = response.data.usuarioNombre;
          this.cuit = response.data.cuit;
          this.telefono = response.data.telefono;
          this.observaciones = response.data.observaciones;
          this.emisorId = response.data.emisorId;
          this.emisorXFinId = response.data.emisorXFinId;
          this.usuarioId = response.data.usuarioId;
        } else if (response.status === 204) {
          this.emisorSearched = true;
          this.emisorNotFound = true;
          this.setAlert({
            type: "info",
            message:
              "No se ha encontrado un emisor existente en el sistema con los datos ingresados. A continuación cargue los datos de la pantalla para crear la farmacia.",
          });
        } else return;
      } catch (error) {
        console.error("Error", error);
      }
    },
    setUserName(cuit) {
      this.usuNom = cuit.replaceAll("-", "");
    },
    async guardarEmisor() {
      this.isLoadingSave = true;
      const payload = {
        emisorId: this.emisorId,
        usuarioId: this.usuarioId,
        usuarioNombre: this.usuNom,
        emisorXFinId: this.emisorXFinId,
        emisorNom: this.nombre,
        razonSocial: this.razonSocial,
        cuit: this.cuit.replaceAll("-", ""),
        emisorEmail: this.email,
        telefono: this.telefono,
        observaciones: this.observaciones,
      };
      try {
        const resp = await this.saveEmisor(payload);
        if (resp.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito.",
          });
          this.cerrarDialogo();
          this.reloadEmisores();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingSave = false;
    },
    cerrarDialogo() {
      this.$emit("cerrarDialogo");
    },
    reloadEmisores() {
      this.$emit("reloadEmisores");
    },
  },
};
</script>
<style></style>
