<template>
  <v-card>
    <v-card-title>¡Alerta de inactividad!</v-card-title>
    <v-card-text>
      <p>
        {{ text }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SessionTimeoutDialog",
  data() {
    return {
      time: 10000,
    };
  },
  computed: {
    text() {
      return `Se cerrará la sesión en ${this.second} segundos.`;
    },
    second() {
      return this.time / 1000;
    },
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) {
        clearInterval(timerId);
      }
      if (this.time < 1) {
        clearInterval(timerId);
        this.closeSession();
      }
    }, 1000);
  },

  methods: {
    closeSession() {
      sessionStorage.setItem("token", "");
      sessionStorage.removeItem("userName");
      this.$router.push("/login");
      this.$store.commit("menu/setPermisos", []);
    },
  },
};
</script>

<style scoped></style>
