<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-container>
      <v-form
        form="submit-form"
        id="submit-form"
        ref="submit-form"
        v-model="isFormValid"
        @submit.prevent="guardarGrupoDeUsuario()"
      >
        <v-row>
          <v-col cols="12" sm="12" md="12" class="py-0">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              variant="outlined"
              density="compact"
              :rules="
                [
                  rules.required,
                  rules.nameRulesWithNumber,
                  rules.maxLength(nombre, 50),
                ].flat()
              "
              id="name-text-field-edit-gruposDeUsuarios"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="py-0">
            <v-textarea
              v-model="descripcion"
              label="Descripción"
              auto-grow
              variant="outlined"
              density="compact"
              rows="1"
              :rules="[rules.maxLength(descripcion, 250)].flat()"
              id="descripcion-text-area-edit-gruposDeUsuarios"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="py-0 pr-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cerrarDialogo"
          id="v-btn-close-edit-gruposDeUsuarios"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          variant="flat"
          form="submit-form"
          :disabled="!isFormValid || isLoadingSave"
          id="v-btn-save-edit-gruposDeUsuarios"
        >
          <template v-if="isLoadingSave">
            <v-progress-circular
              indeterminate
              color="white"
              size="25"
            ></v-progress-circular>
          </template>
          <template v-else> Confirmar </template>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules.js";
import { mapActions } from "vuex";
export default {
  props: {
    grupoDeUsuarioItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      nombre: "",
      descripcion: "",
      isFormValid: false,
      formTitle: "",
      rules: rules,
      isLoadingSave: false,
    };
  },
  created() {
    this.grupoDeUsuarioItem
      ? this.setearGrupoDeUsuario()
      : (this.formTitle = "Nuevo - Grupo de usuario");
  },
  methods: {
    ...mapActions({
      postGrupoDeUsuario: "gruposDeUsuarios/postGrupoDeUsuario",
      setAlert: "user/setAlert",
    }),
    setearGrupoDeUsuario() {
      this.formTitle = `Editar grupo de usuario – ${this.grupoDeUsuarioItem.grupoUsuNom}`;
      this.nombre = this.grupoDeUsuarioItem.grupoUsuNom;
      this.descripcion = this.grupoDeUsuarioItem?.grupoDesc;
    },
    async guardarGrupoDeUsuario() {
      this.isLoadingSave = true;
      const payload = {
        grupoUsuId: this.grupoDeUsuarioItem?.grupoUsuId ?? null,
        grupoUsuNom: this.nombre,
        grupoDesc: this.descripcion,
      };
      try {
        const resp = await this.postGrupoDeUsuario(payload);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito",
          });
          this.$emit("loadGruposDeUsuarios");
          this.cerrarDialogo();
        }
      } catch (error) {
        console.error("No se pudo guardar con exito", error);
      }
      this.isLoadingSave = false;
    },
    cerrarDialogo() {
      this.$emit("cerrarDialogo");
    },
  },
};
</script>
