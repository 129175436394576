<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="pt-0 pl-1">
        <span class="text-h5"
          >{{ enums.messages.DELETE_DIALOG_TITLE }} {{ title }}</span
        >
      </v-card-title>
      <v-card-text class="pb-0">{{ subtitle }}</v-card-text>
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cancelDelete"
          size="small"
          id="v-btn-cancel-delete"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          @click="confirmDelete"
          size="small"
          :disabled="isLoading"
          :loading="isLoading"
          id="v-btn-acept-delete"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: enums.messages.DELETE_DIALOG_SUBTITLE,
    },
  },
  data() {
    return {
      enums: enums,
    };
  },
  created() {
  },
  watch: {},
  methods: {
    cancelDelete() {
      this.$emit("eliminar-cancelado");
    },
    confirmDelete() {
      this.$emit("eliminar-confirmado");
    },
  },
};
</script>
