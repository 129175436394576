<template>
  <v-menu
    :close-on-content-click="false"
    location="center"
    v-model="localDateMenu"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        v-model="selectedDate"
        :label="label"
        :append-icon="calendarIcon"
        v-bind="props"
        v-mask="'##/##/####'"
        ref="textFieldFecha"
        variant="outlined"
        density="compact"
        :clearable="clearable"
        autocomplete="off"
        hint="Formato DD/MM/AAAA"
        :disabled="disabled"
        :rules="rules"
        @input="setDatePicker(), (localDateMenu = false)"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @update:model-value="onDateSelected"
      hide-header
      :min="minDate"
      :max="maxDate"
      class="custom-datepicker"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import helperDate from "@/utils/helpers/dateTimePicker";

export default {
  props: {
    minDate: {
      type: String,
      required: false,
      default: "2001",
    },
    maxDate: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateMenu: {
      type: Boolean,
      required: true,
      default: false,
    },
    defaultDate: {
      type: Date,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {},
    clearFecha: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetDatePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: null,
      date: null,
      calendarIcon: "mdi-calendar",
      localDateMenu: this.dateMenu,
    };
  },
  watch: {
    clearFecha(val) {
      if (val) this.clearDateValues();
    },
    rules() {
      setTimeout(async () => {
        await this.$refs.textFieldFecha?.validate().then((x) => {
          x.rules = this.rules;
        });
      }, 1);
    },
    selectedDate(val) {
      if (val == null || val == "") this.clearDateValues();
    },
    defaultDate() {
      this.setDefaultDate();
    },
  },
  mounted() {
    this.setDefaultDate();
  },
  methods: {
    onDateSelected(date) {
      const now = new Date();
      date.setHours(now.getHours() - 3, now.getMinutes(), now.getSeconds());

      this.selectedDate = this.formatDate(date);
      const dateValues = {
        dateString: this.selectedDate,
        date: date,
      };

      this.$emit("selectFecha", dateValues);
      this.$emit("update:dateMenu", false);

      this.localDateMenu = false;
    },
    formatDate(date) {
      return helperDate.formatDate(date);
    },
    setDatePicker() {
      if (this.selectedDate?.length == 10) {
        this.date = new Date(helperDate.convertDateFormat(this.selectedDate));

        this.date.setHours(this.date.getHours() + 3);


        const dateValues = {
          dateString: this.selectedDate,
          date: this.date,
        };
        this.$emit("selectFecha", dateValues);
        this.$emit("update:dateMenu", false);
      }
    },
    setDefaultDate() {
      try {
        if (this.defaultDate) {
          this.date = this.defaultDate;
          this.onDateSelected(this.date);
        }
      } catch (error) {
        console.error(error);
      }
    },
    clearDateValues() {
      this.selectedDate = null;
      this.date = null;
      const dateValues = {
        dateString: null,
        date: null,
      };
      this.$emit("selectFecha", dateValues);
      this.$emit("update:dateMenu", false);
    },
  },
};
</script>

<style>
.custom-datepicker .v-date-picker-month__days {
  row-gap: 0px !important;
  justify-content: center;
  flex: 0 1;
}
.custom-datepicker .v-date-picker-month__day {
  height: auto;
}
.custom-datepicker .v-date-picker-month {
  padding: 12px 0px 2px;
}
.custom-datepicker .v-date-picker-controls {
  padding: 0px 14px;
  background-color: #180364;
  color: white;
}
.custom-datepicker .v-date-picker-month__day--selected .v-btn {
  background-color: #180364;
}
</style>
