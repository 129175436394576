//import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ForgottedPassword from "../views/ForgottedPassword.vue";
import ManualesUsuario from "../views/modules/manualUsuario/ManualesUsuario.vue";
import Main from "../components/layout/Main.vue";
import Financiadores from "../views/modules/financiadores/FinanciadoresView.vue";
import GestionUsuariosEmisores from "../components/modules/financiadores/GestionUsuariosEmisores.vue";
import Emisores from "../views/modules/emisores/EmisoresView.vue";
import Usuarios from "../views/modules/usuarios/UsuariosView.vue";
import Farmacias from "../views/modules/farmacias/FarmaciasView.vue";
import Permisos from "../views/modules/permisos/PermisosView.vue";
import Solicitudes from "../views/modules/solicitudes/SolicitudesView.vue";
import GruposDeUsuarios from "../views/modules/gruposDeUsuarios/GruposDeUsuariosView.vue";
import { createRouter, createWebHistory } from "vue-router";
import permisos from "@/utils/enums/permises";
import { useStore } from "vuex";

//Vue.use(VueRouter);

const routes = [
  {
    path: permisos.HOME.path, // ruta destino
    name: permisos.HOME.name, // nombre de ruta
    component: Home, // componente a cargar
    meta: { requiresAuth: true }, // si necesita token para entrar
    beforeEnter(to, from, next) {
      // Antes de entrar a la ruta revisa si existe el token y posee cookies
      const store = useStore();
      store.dispatch("menu/obtainPermisos", { code: permisos.HOME.code });
      next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },
    beforeEnter(to, from, next) {
      // Antes de entrar a la ruta revisa si existe el token y posee cookies
      const token = sessionStorage.getItem("token");
      //const cookies = helper.getCookie("geclisacookie");
      if (token /*&& cookies*/) {
        next("/");
      }
      next();
    },
  },
  {
    path: "/restaurar-contrasena",
    name: "forgottedPassword",
    component: ForgottedPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    name: "main",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      // hijos de esa ruta
      /* Configuracion Permisos */
      /*{
        //OPTION
        path: "",
        name: "",
        meta: { requiresAuth: false },
        beforeEnter(to, from, next) {
          // store.dispatch("User/changeActiveMenu", INTERNADO);
          // cdo ingresa por url debe actualizar el menu y datos
          if (!from.name) {
            // store
            //   .dispatch(
            //     "User/updateFrequencyByOptionCode",
            //     webSiteOptions.CONFIGURACION_INTERNADO
            //   )
            //   .then(() => {
                next();
              // });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(""),
      },*/
      //FINANCIADORES
      {
        path: permisos.FINANCIADORES.path, // ruta destino
        name: permisos.FINANCIADORES.name, // nombre de ruta
        component: Financiadores, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.FINANCIADORES.code,
          });
          next();
        },
      },
      {
        path: permisos.EMISORES.path, // ruta destino
        name: permisos.EMISORES.name, // nombre de ruta
        component: Emisores, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.EMISORES.code,
          });
          next();
        },
      },
      {
        path: permisos.USUARIOS.path, // ruta destino
        name: permisos.USUARIOS.name, // nombre de ruta
        component: Usuarios, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.USUARIOS.code,
          });
          next();
        },
      },
      {
        path: permisos.PRESCRIPCIONES.path, // ruta destino
        name: permisos.PRESCRIPCIONES.name, // nombre de ruta
        component: Solicitudes, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.PRESCRIPCIONES.code,
          });
          next();
        },
      },
      {
        path: permisos.PERMISOS.path, // ruta destino
        name: permisos.PERMISOS.name, // nombre de ruta
        component: Permisos, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.PERMISOS.code,
          });
          next();
        },
      },
      {
        path: permisos.GRUPOSDEUSUARIOS.path, // ruta destino
        name: permisos.GRUPOSDEUSUARIOS.name, // nombre de ruta
        component: GruposDeUsuarios, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.GRUPOSDEUSUARIOS.code,
          });
          next();
        },
      },
      {
        path: permisos.MANUALUSUARIO.path, // ruta destino
        name: permisos.MANUALUSUARIO.name, // nombre de ruta
        component: ManualesUsuario, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.MANUALUSUARIO.code,
          });
          next();
        },
      },
      {
        path: "financiadores/gestion-usuarios-emisores", // ruta destino
        name: "GestionUsuariosEmisores", // nombre de ruta
        component: GestionUsuariosEmisores, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.GESTION_USUARIOS_EMISORES.code,
          });
          next();
        },
      },
      {
        path: "financiadores/gestion-farmacias", // ruta destino
        name: "GestionFarmacias", // nombre de ruta
        component: Farmacias, // componente a cargar
        meta: { requiresAuth: true }, // si necesita token para entrar
        beforeEnter(to, from, next) {
          // Antes de entrar a la ruta revisa si existe el token y posee cookies
          const store = useStore();
          store.dispatch("menu/obtainPermisos", {
            code: permisos.GESTION_FARMACIAS.code,
          });
          next();
        },
      },
      /* ERROR 404 */
      {
        path: "/:catchAll(.*)",
        name: "E404",
        component: () => import("../views/errors/E404.vue"),
      },
    ],
  },
];

const router = createRouter({
  //mode: "history",
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  // linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // requiere auntenticacion
    if (
      /*!helper.getCookie("geclisacookie") && */ !sessionStorage.getItem(
        "token"
      )
    ) {
      next({
        path: "/login", // usuario no loggeado
      });
    } else {
      next(); // usuario loggeado
    }
  } else {
    next(); // Pagina Pública
  }
});

export default router;
