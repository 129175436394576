<template>
  <v-card>
    <v-container class="pa-0">
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form v-model="isFormValid" ref="form" @submit.prevent="save()">
          <v-row class="pb-0" :no-gutters="isMobile">
            <v-col cols="12" class="pb-0">
              <v-select
                :items="tipoSolicitudItems"
                label="Tipo de solicitud"
                v-model="tipoSolicitudSelected"
                return-object
                @update:model-value="loadReporte()"
                variant="outlined"
                density="compact"
                item-value="id"
                item-title="value"
                :rules="rules.required"
                :loading="isLoadingTipoSoli"
                :disabled="disableTipoSoli"
              ></v-select>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6">
              <v-autocomplete
                :items="reportesItems"
                label="Reporte encabezado"
                v-model="reporteSelected"
                return-object
                variant="outlined"
                density="compact"
                item-value="id"
                clearable
                item-title="value"
                :loading="isLoadingReporte"
                :disabled="disableReporte"
              ></v-autocomplete>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6">
              <v-autocomplete
                :items="reportesItems"
                label="Reporte detalle"
                v-model="reporteSelectedDetalle"
                return-object
                variant="outlined"
                density="compact"
                item-value="id"
                clearable
                item-title="value"
                :loading="isLoadingReporte"
                :disabled="disableReporte"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="closeModal()">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              :disabled="!isFormValid"
              :loading="isLoading"
              @click="save()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportFinanciadores",
  props: {
    financiadorProp: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      formTitle: "Reportes de - ",
      correo: null,
      rules: rules,
      isFormValid: false,
      isLoading: false,
      isLoadingTipoSoli: false,
      disableTipoSoli: false,
      isLoadingReporte: false,
      disableReporte: false,
      reportesItems: [],
      tipoSolicitudItems: [],
      tipoSolicitudSelected: null,
      reporteSelected: null,
      reporteSelectedDetalle: null,
      configFinanciador: [],
    };
  },
  async created() {
    await this.setSelects();
    if (this.tipoSolicitudItems.length > 0) {
      this.tipoSolicitudSelected = this.tipoSolicitudItems[0];
      await this.loadReporte();
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      getTipoSolicitudes: "taxonomy/getTiposSolicitudes",
      getConfigFinanciadores: "financiador/getFinanciadoresReportes",
      postConfigFinanciadores: "financiador/postFinanciadoresReportes",
      getReportesFinanciador: "taxonomy/getReportes",
      setAlert: "user/setAlert",
    }),
    async loadReporte() {
      this.reporteSelected = this.reporteSelectedDetalle = null;
      const config = this.configFinanciador.find(
        (x) => x.tipoSoliId === this.tipoSolicitudSelected.id
      );
      if (config) {
        this.reporteSelected = this.reportesItems.find(
          (x) => x.value === config.reporteValue
        );
        this.reporteSelectedDetalle = this.reportesItems.find(
          (x) => x.value === config.reporteDetalleValue
        );
      }
    },
    async setSelects() {
      this.isLoadingReporte = this.isLoadingTipoSoli = true;
      this.formTitle += this.financiadorProp?.financiadorNom;
      this.tipoSolicitudItems = await this.getTipoSolicitudes();
      this.configFinanciador = await this.getConfigFinanciadores(
        this.financiadorProp?.financiadorId
      );
      this.reportesItems = await this.getReportesFinanciador(
        this.financiadorProp?.financiadorId
      );
      this.isLoadingReporte = this.isLoadingTipoSoli = false;
    },
    async save() {
      this.isLoading = true;
      const data = {
        FxrId: 0,
        FinanciadorId: this.financiadorProp.financiadorId,
        TipoSoliId: this.tipoSolicitudSelected.id,
        ReporteValue: this.reporteSelected?.value ?? "",
        ReporteDetalleValue: this.reporteSelectedDetalle?.value ?? "",
      };
      this.configFinanciador = await this.getConfigFinanciadores(
        this.financiadorProp?.financiadorId
      );
      const config = this.configFinanciador.find(
        (x) => x.tipoSoliId === this.tipoSolicitudSelected.id
      );
      if (config) data.FxrId = config.fxrId;
      try {
        const res = await this.postConfigFinanciadores(data);
        if (res.status === 200)
          this.setAlert({ type: "success", message: "Guardado con éxito." });
      } catch (error) {
        console.error("Error al guardar cambios", error);
      } finally {
        this.isLoading = false;
      }
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
