<template>
  <v-chip variant="text" @click="goTo" :class=" isMobile ? 'px-1 mt-0 mb-5 text-button' : 'px-1 py-0'">
    <v-icon size="25px" class="pr-2">{{ goBackIcon }}</v-icon>
    {{ isMobile ? title : '' }}
  </v-chip>
  <v-container v-if="!isMobile" class="text-button pl-1 pt-0">{{ title }}</v-container>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapGetters } from "vuex";


export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      goBackIcon: enums.icons.BACK_ARROW_ICON,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile"
    }),
  },
  methods: {
    goTo() {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
      });
      this.$router.push(this.path);
    },
  },
};
</script>

<style scoped>
.text-button {
  font-size: 18px !important;
}
</style>
