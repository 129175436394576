<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="400">
      <v-form
        ref="form"
        form="form"
        v-model="isFormValid"
        @submit.prevent="changePassword"
      >
        <v-card>
          <v-card-title class="text-button">
            <span>Cambiar contraseña </span>
          </v-card-title>
          <v-card-text class="py-0">
            <v-container>
              <v-row>
                <!-- Contraseña actual -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Contraseña actual"
                    id="current-password"
                    autocomplete="current-password"
                    :type="showLastPassword ? 'text' : 'password'"
                    v-model.trim="lastPassword"
                    :rules="rules.required.concat(rules.whiteSpaceNotAllowed)"
                    tabindex="1"
                    variant="outlined"
                    density="compact"
                  >
                    <v-icon
                      class="eye-icon"
                      @click="showLastPassword = !showLastPassword"
                      id="v-icon-eye-show-password"
                      tabindex="-1"
                    >
                      {{ showLastPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-text-field>
                </v-col>
                <!-- Contraseña nueva -->
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Contraseña nueva"
                    id="new-password"
                    autocomplete="new-password"
                    :type="showNewPassword ? 'text' : 'password'"
                    v-model.trim="newPassword"
                    :rules="rules.password.concat(rules.whiteSpaceNotAllowed)"
                    tabindex="2"
                    ref="textFieldNueva"
                    variant="outlined"
                    density="compact"
                  >
                    <v-icon
                      class="eye-icon"
                      @click="showNewPassword = !showNewPassword"
                      id="v-icon-eye-show-password"
                      tabindex="-1"
                    >
                      {{ showNewPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-text-field>
                </v-col>
                <!-- Repetir contraseña -->
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Repetir contraseña nueva"
                    ref="textFieldRepeat"
                    :type="showRepeatPassword ? 'text' : 'password'"
                    required
                    v-model.trim="repeatPassword"
                    id="new-password2"
                    tabindex="3"
                    autocomplete="new-password"
                    variant="outlined"
                    density="compact"
                    :rules="
                      newPassword != null
                        ? rules.required.concat([
                            rules.samePassword(newPassword, repeatPassword),
                          ])
                        : rules.required
                    "
                  >
                    <v-icon
                      class="eye-icon"
                      @click="showRepeatPassword = !showRepeatPassword"
                      id="v-icon-eye-show-password"
                      tabindex="-1"
                    >
                      {{ showRepeatPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="v-card-actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              variant="text"
              @click="closeModal"
              tabindex="4"
              id="v-btn-close-change-password"
              v-if="!changePasswordRequired"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              variant="text"
              @click="logOut"
              tabindex="4"
              id="v-btn-logOut-change-password"
              v-else
            >
              Cerrar sesión
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              form="form"
              type="submit"
              tabindex="5"
              :disabled="!isFormValid"
              @click="confirm"
              id="v-btn-confirm-change-password"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  created() {
    this.userName = sessionStorage.getItem("userName");
    this.changePasswordRequired =
      sessionStorage.getItem("changePassword") === "true";
  },
  data() {
    return {
      dialog: true,
      showLastPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      lastPassword: "",
      newPassword: "",
      repeatPassword: "",
      rules: rules,
      buttonDisabled: true,
      isFormValid: false,
      userName: null,
      changePasswordRequired: false,
    };
  },
  watch: {
    newPassword() {
      setTimeout(async () => {
        await this.$refs.textFieldRepeat?.validate();
      }, 1);
    },
  },
  methods: {
    ...mapActions({
      postPassword: "password/savePassword",
      setAlert: "user/setAlert",
    }),
    async confirm() {
      const passwordData = {
        ActualPassword: this.lastPassword,
        NewPassword: this.newPassword,
      };
      try {
        const response = await this.postPassword(passwordData);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Contraseña guardada con éxito",
          });
          sessionStorage.setItem("changePassword", "false");
          this.closeModal();
        }
      } catch (error) {
        console.error("Error al guardar el cambio de contraseña", error);
      }
    },
    logOut() {
      sessionStorage.setItem("token", "");
      sessionStorage.removeItem("userName");
      this.$router.push("/login");
      this.$store.commit("menu/setPermisos", []);
    },
    closeModal() {
      this.$emit("close");
    },
    changePassword() {},
  },
};
</script>

<style scoped>
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.text-button {
  font-size: 18px !important;
}
</style>
