<template>
  <v-container class="pa-0">
    <v-chip
      v-for="(filter, key) in filtersApplied"
      class="mr-2"
      label
      outlined
      color="primary"
      :key="key"
      @click="handleChipClick(filter)"
      :id="'v-chip-'+ key"
    >
      {{
        `${filter.label}: ${
          Array.isArray(filter.model)
            ? getTextWhenFilterIsArray(filter)
            : filter.model
        }`
      }}
    </v-chip>
  </v-container>
</template>
<script>
export default {
    name: "FiltersSelected",
    data(){
        return{}
    },
    props: {
        filtersApplied: {
            type: Array,
        },
    },
    methods: {
        getTextWhenFilterIsArray(currentFilter) {
      if (currentFilter.model.length > 0 && currentFilter.model.length <= 3) {
        return currentFilter.model
          .map((m) => (m.value ? m.value : m))
          .join(", ");
      }
      if (currentFilter.model.length > 3) {
        let totalFiltros = currentFilter.model.length - 3;
        let firstThree = currentFilter.model.slice(0, 3).map((f) => {
          return f.value ? f.value : f;
        });
        return { firstThree, totalFiltros };
      }
    },
    handleChipClick(filter) {
      this.$emit('chip-click', filter);
    },
    }}
</script>
<style scoped></style>
