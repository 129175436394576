import axios from "@/axios/axios-api";

export default {
  async postSesion(context, payload) {
    try {
      const response = await axios.post("/api/Auth/login", {
        UserName: payload.userName,
        Password: payload.userPassword,
      });
      return response;
    } catch (error) {
      console.error("Error al iniciar sesión", error);
    }
  },
  async validateAndReturnUser(context, payload) {
    const response = await axios.get(
      `api/auth/validate-return-user?userName=${payload.userName}&password=${payload.password}`
    );
    return response;
  },
  async userRequireTwoFactorAuthentication(context, payload) {
    const response = await axios.get(`api/auth/user-require-2fa/${payload}`);
    return response.data;
  },
  async generateUserQR(context, payload) {
    const response = await axios.get(`api/auth/generate-qr/${payload}`, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([response.data], { type: "image/png" });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  },
  async verifyTwoFactorAuthentication(context, payload) {
    const response = await axios.get(
      `api/auth/verify-2fa/${payload.userId}/${payload.code}`
    );
    return response.data;
  },
  async saveLoginWithTwoFactorAuthentication(context, payload) {
    const response = await axios.post(
      `api/auth/save-login-2fa/${payload.userId}`
    );
    return response.data;
  },
};
