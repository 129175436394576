<template>
  <v-card v-if="datosEnvio">
    <v-container class="pb-0">
      <v-card-title class="pt-0 pl-1 mb-3">
        <span class="text-h5">
          {{ mensajeDatosEnvio }}
        </span>
      </v-card-title>
      <v-card class="pt-3">
        <v-card-text class="pl-5 mb-3">
          {{ datosEnvio }}
        </v-card-text>
      </v-card>
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cancelDatosEnvioDialog"
          id="v-btn-cancel-datos-envio"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapActions } from "vuex";

export default {
  props: {
    logSolicitudId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      mensajeDatosEnvio: enums.messages.DATOS_ENVIO_MESSAGE,
      datosEnvio: null,
    };
  },
  created() {
    this.setDatosEnvio();
  },
  methods: {
    ...mapActions({
      getDatosEnvioLog: "solicitud/getDatosEnvioLog",
      setAlert: "user/setAlert",
    }),
    async setDatosEnvio() {
      const logSolicitud = this.logSolicitudId;
      try {
        const response = await this.getDatosEnvioLog(logSolicitud);
        this.datosEnvio = response;
      } catch (error) {
        console.error(error);
      }
    },
    cancelDatosEnvioDialog() {
      this.$emit("anular-cancelado");
    },
  },
};
</script>
