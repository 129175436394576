<template>
  <div>
    <v-snackbar
      v-if="snackBar"
      v-model="openSnackbar"
      :color="snackBar['type']"
      :timeout="snackBar['timeout'] ? snackBar['timeout'] : 4000"
      location="top"
    >
      <v-row>
        <v-col class="my-auto" cols="2">
          <v-icon size="28"> {{ getIcon(snackBar["type"]) }}</v-icon>
        </v-col>
        <v-col class="my-auto" cols="10">
          <!-- Para insertar saltos de linea, agregar \n en el string que se pasa en el message -->
          <div
            class="msg"
            v-for="(text, index) in snackBar['message'].split('\n')"
            :key="index"
          >
            {{ text }}
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn variant="text" @click="openSnackbar = false"> Cerrar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index.js";

export default {
  name: "SnackBar",
  computed: {
    ...mapGetters({
      snackBar: "user/alert",
    }),
  },
  watch: {
    snackBar() {
      this.openSnackbar = true;
    },
  },
  data() {
    return {
      openSnackbar: this.snackBar,
    };
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
    }),
    closeAlert() {
      this.setAlert(null);
      this.openSnackbar = false;
    },
    getIcon(type) {
      switch (type) {
        case "warning":
          return enums.icons.SNB_WARNING;
        case "success":
          return enums.icons.SNB_SUCCESS;
        case "info":
          return enums.icons.SNB_INFO;
        case "error":
          return enums.icons.SNB_ERROR;
        default:
          return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.msg {
  font-weight: 400;
}
</style>
