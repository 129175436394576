<template>
  <v-footer dark class="pa-0">
    <v-card flat tile class="footer-card text-center">
      <v-card-text>
        <strong>{{enums.titles.PRODUCT}}</strong> un producto de
        <a href="https://www.macena.com.ar/" target="_blank" color="primary">
          Macena
        </a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import enums from "@/utils/enums/index";

export default {
  name: "TheFooter",
  data() {
    return {
      enums: enums
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-card {
  width: 100%;
}
</style>