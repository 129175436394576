<template>
  <v-container>
    <PageHeader :title="pageTitle" :path="goToPath" />
    <v-card elevation="1">
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="search"
              :append-icon="searchIcon"
              label="Buscar"
              variant="underlined"
              single-line
              hide-details
              clearable
              id="search-bar-gruposDeUsuarios"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            class="d-flex justify-end"
            :class="isMobile ? 'mt-4' : 'mt-2'"
            v-if="permisosGrupoUsuario.canCreate"
          >
            <v-btn
              color="primary"
              variant="flat"
              @click="openEditGruposDeUsuariosDialog()"
              id="v-btn-nuevo"
              >Nuevo</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-card-text class="py-0 my-0">
        <v-row>
          <v-col cols="12" class="px-0 mx-0">
            <v-data-table
              :items-per-page="10"
              item-value="grupoUsuId"
              :headers="headers"
              class="pa-3"
              :items="gruposDeUsuarios"
              :search="search"
              :loading="isDataTableLoading"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip
                  text="Editar grupo de usuario"
                  location="start"
                  v-if="permisosGrupoUsuario.canEdit"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openEditGruposDeUsuariosDialog(item)"
                      v-bind="props"
                      id="v-icon-edit-grupo-de-usuario"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip
                  text="Eliminar grupo de usuario"
                  location="start"
                  v-if="permisosGrupoUsuario.canDelete && !item.nonDeletableGroup"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openDeleteDialog(item.grupoUsuId)"
                      v-bind="props"
                      id="v-icon-delete-grupo-de-usuario"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog :max-width="isMobile ? '95%' : '50%'" v-model="editGruposDeUsuariosDialog" persistent>
      <EditGruposDeUsuarios
        :grupoDeUsuarioItem="grupoDeUsuarioItem"
        @loadGruposDeUsuarios="loadGruposDeUsuarios"
        @cerrarDialogo="closeEditDialog"
      />
    </v-dialog>
    <v-dialog :max-width="isMobile ? '95%' : '30%'" v-model="dialogDelete" persistent>
      <DeleteDialog
        v-if="dialogDelete"
        title="grupo de usuario"
        :isLoading="isLoadingDelete"
        :subtitle="deleteSubtitle"
        @eliminar-confirmado="eliminarGrupoDeUsuario"
        @eliminar-cancelado="closeDeleteDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index";
import PageHeader from "@/components/layout/PageHeader.vue";
import EditGruposDeUsuarios from "@/components/modules/gruposDeUsuarios/EditGruposDeUsuarios.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GruposDeUsuarioView",
  components: {
    PageHeader,
    EditGruposDeUsuarios,
    DeleteDialog,
  },
  data() {
    return {
      pageTitle: enums.permises.GRUPOSDEUSUARIOS.name,
      deleteSubtitle: enums.messages.GRUPOS_DE_USUARIOS_DELETE_SUBTITLE,
      goToPath: enums.permises.HOME.path,
      searchIcon: enums.icons.SEARCH_ICON,
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      editGruposDeUsuariosDialog: false,
      dialogDelete: false,
      isDataTableLoading: false,
      isLoadingDelete: false,
      search: "",
      grupoDeUsuarioItem: null,
      idToDelete: null,
      gruposDeUsuarios: [],
      headers: [
        {
          title: "Nombre",
          sortable: false,
          key: "grupoUsuNom",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile"
    }),
    permisosGrupoUsuario() {
      let canCreate = false;
      let canEdit = false;
      let canDelete = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.CREAR_GRUPOUSUARIO.code:
              canCreate = true;
              break;
            case enums.permises.EDITAR_GRUPOUSUARIO.code:
              canEdit = true;
              break;
            case enums.permises.ELIMINAR_GRUPOUSUARIO.code:
              canDelete = true;
              break;
            default:
              break;
          }
        });
      }
      return { canCreate, canEdit, canDelete };
    },
  },
  created() {
    this.loadGruposDeUsuarios();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
  },
  methods: {
    ...mapActions({
      getGruposDeUsuarios: "gruposDeUsuarios/getGruposDeUsuarios",
      deleteGrupoDeUsuario: "gruposDeUsuarios/deleteGrupoDeUsuario",
      setAlert: "user/setAlert",
    }),
    async loadGruposDeUsuarios() {
      this.isDataTableLoading = true;
      this.gruposDeUsuarios = await this.getGruposDeUsuarios();
      this.isDataTableLoading = false;
    },
    openEditGruposDeUsuariosDialog(item) {
      this.editGruposDeUsuariosDialog = true;
      this.grupoDeUsuarioItem = item;
    },
    closeEditDialog() {
      this.editGruposDeUsuariosDialog = false;
    },
    openDeleteDialog(id) {
      this.dialogDelete = true;
      this.idToDelete = id;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    async eliminarGrupoDeUsuario() {
      this.isLoadingDelete = true;
      try {
        const resp = await this.deleteGrupoDeUsuario(this.idToDelete);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Grupo de usuario eliminado con éxito ",
          });
          this.dialogDelete = false;
          this.loadGruposDeUsuarios();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
  },
};
</script>
