function focusComponent(id, ms) {
    if (ms ?? 0)
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) element.focus();
    }, ms);
    else {
      const element = document.getElementById(id);
      if (element) element.focus();
    }
  }
export { focusComponent };
