import axios from "@/axios/axios-api";

export default {
  async savePassword(context, payload) {
    try {
      const res = await axios.post("api/usuarios/change-password", payload);
      return res;
    } catch (error) {
      console.error(`No se pudo guardar datos, ${error}`);
    }
  },
  async forgotedPassword(context, payload) {
    try {
      let res;
      if (payload.matricula != null) {
        res = await axios.get(
          `api/auth/forgoted-password?tipoUsuario=${payload.tipoUsuario}&matricula=${payload.matricula}&cuil=${payload.cuil}&email=${payload.email}`
        );
      } else {
        res = await axios.get(
          `api/auth/forgoted-password?tipoUsuario=${payload.tipoUsuario}&cuil=${payload.cuil}&email=${payload.email}`
        );
      }
      return res;
    } catch (error) {
      console.error(`No se pudo recuperar la contraseña, ${error}`);
    }
  },
  async changePassword(context, payload) {
    // Este es distinto al savePassword ya que es para cambiar la contraseña cuando la reestablece por olvidarsela
    try {
      const res = await axios.get(
        `api/auth/change-password?userId=${payload.userId}&newPassword=${payload.newPassword}`
      );
      return res;
    } catch (error) {
      console.error(`No se pudo cambiar la contraseña, ${error}`);
    }
  },
};
