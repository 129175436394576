import axios from "@/axios/axios-api";
import router from "@/router";
import store from "@/store";

export default {
    async obtainPermisos(context, payload) {
        try {
            store.dispatch("user/changeAppLoading", {
                status: true,
                text: "",
            });
            const response = await axios.post("/api/Menu/permisos", {
                code: payload.code,
            });
            context.commit('setPermisos', response?.data);
            store.dispatch("user/changeAppLoading", {
                status: false,
                text: "",
            });
        } catch (error) {
            if (`${error}`.includes("(reading 'status')") || `${error?.response?.data}`.includes("UnauthorizedAccessException")) {
                sessionStorage.removeItem("token");
                location.reload(true);
            }
            if (`${error?.response?.data}`.includes("Exceptions.ForbiddenException")) {
                router.push("/");
            }
        }
    },
    async setConfigMobile(context,payload){
        context.commit('setMobile', payload);
    },
}