<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="pt-0 pl-1">
        <span class="text-h5"> ¿Regenerar QR? </span></v-card-title
      >
      <v-card-text class="pb-0"
        >Se volverá a pedir el QR en el inicio de sesión para asociarlo a la
        aplicación elegida por el usuario.</v-card-text
      >
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          color="primary"
          size="small"
          id="v-btn-cancel-remove-qr"
          @click="closeModal"
          :disabled="isLoadingRemoveQRBtn"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          variant="flat"
          size="small"
          id="v-btn-confirm-remove-qr"
          @click="removeUserQRKey()"
          :loading="isLoadingRemoveQRBtn"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapActions } from "vuex";

export default {
  name: "RemoveQr",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      enums: enums,
      isLoadingRemoveQRBtn: false,
    };
  },
  methods: {
    ...mapActions({
      removeUserQRSecretKey: "user/removeUserQRSecretKey",
      setAlert: "user/setAlert",
    }),
    closeModal() {
      this.$emit("closeDialog");
    },

    async removeUserQRKey() {
      this.isLoadingRemoveQRBtn = true;
      try {
        const response = await this.removeUserQRSecretKey(this.userId);
        if (response)
          this.setAlert({
            type: "success",
            message:
              "QR regenerado con éxito. En el siguiente inicio de sesión se mostrará para volver a escanear.",
          });
        this.closeModal();
      } catch (error) {
        console.error(error);
      }
      this.isLoadingRemoveQRBtn = false;
    },
  },
};
</script>

<style></style>
