import axios from "axios";
import getAPIUrl from "@/utils/helpers/getApiUrl";

export default {
  async getSolicitudes(context, payload) {
    try {
      const response = await axios.post(
        "api/Solicitudes/get-solicitudes",
        payload
      );
      return response?.data;
    } catch (error) {
      console.error("Error al obtener solicitudes", error);
    }
  },
  async getFiltersByUser() {
    try {
      const response = await axios.get(`api/Solicitudes/get-filters-by-user`);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los datos del usuario", error);
    }
  },

  async printReceta(context, solicitudHash) {
    try {
      let res = await openFileInNewTab(
        `api/Solicitudes/report/${solicitudHash}`
      );
      return res;
    } catch (error) {
      console.error("Error al cargar los datos del usuario", error);
    }
  },
  async getEstadoSolicitud(_, solicitudId) {
    try {
      return (
        await axios.get(
          `api/Solicitudes/get-solicitud-cambios-estados/${solicitudId}`
        )
      )?.data;
    } catch (error) {
      console.error("Error al traer los Prestadores", error);
    }
  },
  async getMedicamentoADispensar(_, solicitudId) {
    try {
      return (
        await axios.get(
          `/api/solicitudes/medicamentos-a-dispensar/${solicitudId}`
        )
      )?.data;
    } catch (error) {
      console.error("Error al traer los medicamentos a dispensar", error);
    }
  },
  async postMedicamentoDispensado(context, payload) {
    try {
      const response = await axios.post(
        `api/Solicitudes/dispensar-medicamentos/${payload.solicitudHash}`,
        payload.medicamentos
      );
      return response;
    } catch (error) {
      console.error("Error al dispensar medicamentos", error);
    }
  },
  async anularSolicitud(context, payload) {
    try {
      const response = await axios.delete(
        `/api/solicitudes/anular-solicitud/${payload}`
      );
      return response;
    } catch (error) {
      console.error("Error al anular la solicitud", error);
    }
  },
  async getLogSolicitud(_, solicitudId) {
    try {
      return (await axios.get(`/api/solicitudes/log/${solicitudId}`))?.data;
    } catch (error) {
      console.error("Error al traer los datos", error);
    }
  },
  async getDatosEnvioLog(_, solicitudId) {
    try {
      const response = await axios.get(
        `/api/solicitudes/log-payload/${solicitudId}`
      );
      return response;
    } catch (error) {
      console.error("Error al traer los datos", error);
    }
  },
  async createRecetaElectronica(context, payload) {
    try {
      const response = await axios.post(
        "api/solicitudes/create-receta",
        payload
      );
      return response;
    } catch (error) {
      console.error("Error al crear receta electrónica", error);
    }
  },
  async sendRecipeByEmail(context, payload) {
    try {
      const response = await axios.post("api/email/send-email", payload);
      return response;
    } catch (error) {
      console.error("Error al enviar email", error);
    }
  },
  async getPrestadorDataByUserIdAndFinanciadorId(_, payload) {
    try {
      return (await axios.get(`/api/solicitudes/prestador-data/${payload}`))
        ?.data;
    } catch (error) {
      console.error("Error al traer los datos del prestador", error);
    }
  },
  async copiarReceta(_, payload) {
    try {
      return (await axios.get(`/api/solicitudes/copiar-receta/${payload}`));
    } catch (error) {
      console.error("Error al traer los datos de la receta", error);
    }
  },
};

async function openFileInNewTab(endpoint) {
  try {
    const token = sessionStorage.getItem("token");
    const urlAPI = await getAPIUrl();

    await axios({
      method: "get",
      url: `${urlAPI.endsWith("/") ? urlAPI : urlAPI + "/"}${endpoint}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      responseType: "blob", // Especifica que esperamos un blob como respuesta
    })
      .then((response) => {
        // Manejar la respuesta exitosa
        const blob = new Blob([response.data], { type: response.data.type });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((error) => {
        // Manejar errores
        console.error("Error al obtener el archivo:", error);
      });
    return true;
  } catch (error) {
    console.error("Error en la función:", error);
    return false;
  }
}
