<template>
  <v-card>
    <v-container class="pb-0 mobile-container">
      <v-form
        form="submit-form"
        id="submit-form"
        ref="submit-form"
        @submit.prevent="saveFinanciadoresByEmisor()"
      >
        <v-card-title class="px-0">
          <p class="text-h5">Financiadores y configuración de {{ emisorName }}</p>
        </v-card-title>
        <v-card>
          <v-row>
            <v-col :cols="isMobile ? 11 : 8" align-self="center">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                variant="underlined"
                hide-details
                clearable
                class="ml-5"
                style="width: auto"
                id="v-text-field-search"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-card-text class="py-0 my-0">
            <v-row>
              <v-col cols="12" class="px-0 mx-0">
                <v-data-table
                  :items-per-page="10"
                  :headers="headers"
                  :mobile="isMobile"
                  :hide-default-header="isMobile"
                  class="pa-3"
                  item-value="financiadorId"
                  :items="financiadoresDelEmisor"
                  :search="search"
                  :loading="isDataTableFinanciadorLoading"
                >
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    Observaciones: {{ item.emisorObservacion }}
                  </td>
                </tr>
              </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-actions class="pb-0 px-0">
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            variant="text"
            @click="cerrarDialogo"
            id="v-btn-close-ver-financiadores"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    emisorItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      emisorName: "",
      financiadoresSelected: [],
      financiadores: [],
      financiadoresDelEmisor: [],
      search: "",
      searchIcon: enums.icons.SEARCH_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      isDataTableFinanciadorLoading: false,
      isSelectLoading: false,
      isLoadingSave: false,
      openDeleteFinanciadorDialog: false,
      headers: [
        {
          text: "",
          width: "2%",
          value: "data-table-expand",
          sortable: false,
        },
        {
          title: "Número",
          sortable: false,
          key: "financiadorId",
        },
        {
          title: "Nombre",
          sortable: false,
          key: "financiadorNom",
        },
        {
          title: "Código",
          sortable: false,
          key: "financiadorCodigo",
        },
        {
          title: "Teléfono",
          sortable: false,
          key: "emisorTelefono",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile"
    }),
    permisosEmisores() {
      let canAdd = false;
      let canDelete = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.CREAR_EMISOR.code:
              canAdd = true;
              break;
            case enums.permises.ELIMINAR_EMISOR.code:
              canDelete = true;
              break;
            default:
              break;
          }
        });
      }
      return { canAdd, canDelete };
    },
  },
  created() {
    this.emisorName = `${this.emisorItem.emisorNom}`;
    this.getFinanciadoresDelEmisor();
    this.setearFinanciadores();
  },
  methods: {
    ...mapActions({
      getFinanciadores: "financiador/getFinanciadores",
      postFinanciadorEmisor: "emisor/postFinanciadorEmisor",
      setAlert: "user/setAlert",
    }),
    getFinanciadoresDelEmisor() {
      this.isDataTableFinanciadorLoading = true;
      const financiadores = this.emisorItem?.financiadoresEmisor;
      this.financiadoresDelEmisor = financiadores.map((obj) => {
        return {
          financiadorId: obj.financiadorId,
          financiadorNom: obj.financiadorNombre,
          financiadorCodigo: obj.financiadorCodigo,
          emisorTelefono: obj.emisorTelefono,
          emisorObservacion: obj.emisorObservacion
        };
      });

      this.isDataTableFinanciadorLoading = false;
    },
    async setearFinanciadores() {
      this.isSelectLoading = true;
      const allFinanciadores = await this.getFinanciadores();
      const financiadorIdsExistentes = this.financiadoresDelEmisor.map(
        (item) => item.financiadorId
      );
      this.financiadores = allFinanciadores.filter(
        (item) => !financiadorIdsExistentes.includes(item.financiadorId)
      );
      this.isSelectLoading = false;
    },
    async saveFinanciadoresByEmisor() {
      this.isLoadingSave = true;
      const financiadoresIds = this.financiadoresDelEmisor.map(
        (obj) => obj.financiadorId
      );
      const payload = {
        financiadoresIds: financiadoresIds,
        emisorId: this.emisorItem?.emisorId,
      };
      try {
        const resp = await this.postFinanciadorEmisor(payload);
        if (resp.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito ",
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingSave = false;
      this.$emit("reloadEmisores");
      this.cerrarDialogo();
    },
    agregarFinanciadores() {
      this.financiadoresDelEmisor.push(...this.financiadoresSelected);
      this.financiadoresSelected = [];
      this.setearFinanciadores();
    },
    eliminarFinanciadores(id) {
      this.financiadoresDelEmisor = this.financiadoresDelEmisor.filter(
        (item) => item.financiadorId !== id
      );
      this.setearFinanciadores();
    },
    cerrarDialogo() {
      this.$emit("cerrarVerFinanciadoresDialog");
    },
  },
};
</script>
