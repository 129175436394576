import Image from "@tiptap/extension-image";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import ResizableImageTemplate from "./ResizableImageTemplate.vue";

export default Image.extend({
  name: "ResizableImage",

  addAttributes() {
    return {
      ...this.parent?.(),

      width: {
        default: "auto",
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
        parseHTML: (element) => {
          let width;
          if (element.getAttribute("width")) {
            width = element.getAttribute("width");
          }
          if (!width && element.style?.width) {
            width = element.style.width;
          }
          return width;
        },
      },

      height: {
        default: "auto",
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
        parseHTML: (element) => {
          let height;
          if (element.getAttribute("height")) {
            height = element.getAttribute("height");
          }
          if (!height && element.style?.height) {
            height = element.style.height;
          }
          return height;
        },
      },

      isDraggable: {
        default: true,
        renderHTML: () => {
          return {};
        },
      },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      toggleResizable:
        () =>
        ({ tr }) => {
          const { node } = tr?.selection;

          if (node?.type?.name === "ResizableImage") {
            node.attrs.isDraggable = !node.attrs.isDraggable;
          }
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ResizableImageTemplate);
  },
});
