<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="pt-0 pl-1">
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cancelConfirm"
          size="small"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          @click="confirm"
          size="small"
          :disabled="isLoading"
          :loading="isLoading"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
  
  <script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
      required: true,
    },
  },
  methods: {
    cancelConfirm() {
      this.$emit("confirmar-cancelado");
    },
    confirm() {
      this.$emit("confirmar");
    },
  },
};
</script>