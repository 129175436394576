<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="mb-3 text-button pt-0">{{ formTitle }}</v-card-title>
      <v-card-text class="px-0">
        <v-form v-model="isFormValid" ref="form" @submit.prevent="save()">
          <v-row :no-gutters="isMobile">
            <v-col :cols="isMobile ? 12 : 8" class="py-1">
              <v-text-field
                label="Nombre"
                variant="outlined"
                density="compact"
                v-model="financiador.name"
                :rules="
                  rules.required.concat([rules.maxLength(financiador.name, 50)])
                "
              ></v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4" class="py-1">
              <v-text-field
                label="Código"
                density="compact"
                variant="outlined"
                v-model="financiador.code"
                :rules="
                  rules.required.concat([rules.maxLength(financiador.code, 50)])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="URL"
                variant="outlined"
                density="compact"
                v-model="financiador.url"
                :rules="
                  rules.required.concat(
                    [rules.maxLength(financiador.url, 150)],
                    rules.validUrl
                  )
                "
              ></v-text-field>
            </v-col>

            <v-col :cols="isMobile ? 12 : 4" class="pb-0">
              <v-select
                v-model="financiador.valUniFechaVenc"
                label="Unidad validación fecha vencimiento"
                hint="Unidad validación fecha vencimiento"
                persistent-hint
                :items="unidadFechaVencList"
                variant="outlined"
                clearable
                density="compact"
                @update:model-value="financiador.valCantFechaVenc = null"
                item-title="desc"
                item-value="value"
                id="v-select-unidad"
              ></v-select>
            </v-col>

            <v-col :cols="isMobile ? 12 : 4" class="pb-0">
              <v-text-field
                v-if="financiador.valUniFechaVenc"
                v-model="financiador.valCantFechaVenc"
                label="Cantidad validación fecha vencimiento"
                hint="Cantidad validación fecha vencimiento"
                persistent-hint
                type="number"
                variant="outlined"
                :disabled="!financiador.valUniFechaVenc"
                density="compact"
                min="1"
                :rules="
                  financiador.valUniFechaVenc
                    ? rules.required.concat(rules.validNumberRule)
                    : []
                "
              >
              </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4" class="pb-0">
              <v-text-field
                v-model="financiador.valDiasEmisionFutura"
                label="Días de emisión a futuro"
                type="number"
                persistent-hint
                hint="Días de emisión a futuro"
                variant="outlined"
                density="compact"
                min="0"
                :rules="rules.validNumberRule"
              >
              </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 pt-2">
              <v-switch
                v-model="financiador.valAfiliado"
                color="primary"
                label="Valida afiliado"
              >
              </v-switch>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 pt-2">
              <v-switch
                v-model="financiador.valPrestador"
                color="primary"
                label="Valida prestador"
              >
              </v-switch>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 mt-n4">
              <v-switch
                v-model="financiador.valPrestadorSisa"
                color="primary"
                label="Valida prestador en SISA"
              >
              </v-switch>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 mt-n4">
              <v-switch
                v-model="financiador.usaAlfaBetaPropio"
                color="primary"
                label="Usar Alfa Beta propio"
              >
              </v-switch>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 mt-n4">
              <v-switch
                v-model="financiador.valCobertura"
                color="primary"
                label="Valida cobertura"
              >
              </v-switch>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 mt-n4">
              <v-switch
                v-model="financiador.valDroga"
                @update:model-value="financiador.valPot = financiador.valDroga ? financiador.valPot : false"
                color="primary"
                label="Valida dispensa droga"
              >
              </v-switch>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="py-0 mt-n4">
              <v-switch
                v-model="financiador.valPot"
                :disabled="!financiador.valDroga"
                color="primary"
                label="Valida dispensa potencia"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-card-actions class="py-0 pr-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeModal(false)" variant="text">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!isFormValid || lockButton"
              :loading="isLoading"
              variant="flat"
              >Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditFinanciadores",
  props: {
    financiadorItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      financiador: {
        id: null,
        name: "",
        code: "",
        url: "",
        valDroga: false,
        valPot: false,
        valCantFechaVenc: null,
        valUniFechaVenc: null,
        valDiasEmisionFutura: 0,
        valPrestador: false,
        valAfiliado: false,
        valPrestadorSisa: false,
        usaAlfaBetaPropio: false,
        valCobertura: false,
      },
      unidadFechaVencList: [
        {
          id: 2,
          value: "D",
          desc: "Días",
        },
        {
          id: 1,
          value: "M",
          desc: "Meses",
        },
      ],
      rules: rules,
      isFormValid: false,
      formTitle: "",
      isLoading: false,
      lockButton: false,
    };
  },
  created() {
    if (this.financiadorItem != null) {
      this.setFinanciador();
      this.formTitle = "Editar financiador";
    } else {
      this.formTitle = "Nuevo financiador";
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      postFinanciador: "financiador/postFinanciador",
      setAlert: "user/setAlert",
    }),
    setFinanciador() {
      this.financiador.id = this.financiadorItem.financiadorId;
      this.financiador.name = this.financiadorItem.financiadorNom;
      this.financiador.code = this.financiadorItem.financiadorCodigo;
      this.financiador.url = this.financiadorItem.financiadorUrl;
      this.financiador.valDroga =
        this.financiadorItem.financiadorValidaDroga ?? false;
      this.financiador.valPot =
        this.financiadorItem.financiadorValidaPotencia ?? false;
      this.financiador.valUniFechaVenc =
        this.financiadorItem.financiadorValidaUnidadFechaVencimiento === ""
          ? null
          : this.financiadorItem.financiadorValidaUnidadFechaVencimiento;
      this.financiador.valCantFechaVenc =
        this.financiadorItem.financiadorValidaCantidadFechaVencimiento;
      this.financiador.valDiasEmisionFutura =
        this.financiadorItem.financiadorValidaDiasEmisionFutura == null
          ? 0
          : this.financiadorItem.financiadorValidaDiasEmisionFutura;
      this.financiador.valPrestador =
        this.financiadorItem.financiadorValidaPrestador ?? false;
      this.financiador.valAfiliado =
        this.financiadorItem.financiadorValidaAfiliado ?? false;
      this.financiador.valPrestadorSisa =
        this.financiadorItem.financiadorValidaPrestadorSISA ?? false;
      this.financiador.usaAlfaBetaPropio =
        this.financiadorItem.financiadorUsaAlfaBetaPropio ?? false;
      this.financiador.valCobertura =
        this.financiadorItem.validaCobertura ?? false;
    },
    async save() {
      this.lockButton = true;
      this.isLoading = true;
      const data = {
        FinanciadorId: this.financiador.id,
        FinanciadorNom: this.financiador.name,
        FinanciadorCodigo: this.financiador.code,
        FinanciadorUrl: this.financiador.url,
        financiadorValidaPotencia: this.financiador.valPot,
        FinanciadorValidaDroga: this.financiador.valDroga,
        FinanciadorValidaUnidadFechaVencimiento:
          this.financiador.valUniFechaVenc,
        FinanciadorValidaCantidadFechaVencimiento:
          this.financiador.valCantFechaVenc,
        FinanciadorValidaDiasEmisionFutura:
          this.financiador.valDiasEmisionFutura,
        FinanciadorValidaPrestador: this.financiador.valPrestador,
        FinanciadorValidaAfiliado: this.financiador.valAfiliado,
        FinanciadorUsaAlfaBetaPropio: this.financiador.usaAlfaBetaPropio,
        FinanciadorValidaPrestadorSISA: this.financiador.valPrestadorSisa,
        FinanciadorValidaCobertura: this.financiador.valCobertura
      };
      try {
        const response = await this.postFinanciador(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal(true);
        }
      } catch (error) {
        console.error("Error al guardar cambios financiador", error);
      } finally {
        this.isLoading = false;
        this.lockButton = false;
      }
    },
    closeModal(reload) {
      this.$emit("closeAndReload", reload);
    },
  },
};
</script>

<style scoped>
.actions {
  justify-content: end;
  padding: 0px 10px 10px;
  margin-right: 5px;
}
.text {
  padding: 0px 24px;
}
.text-button {
  font-size: 18px !important;
}
</style>
