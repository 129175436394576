import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state() {
      return {};
    },
  actions: actions,
  getters: getters,
  mutations: mutations
}