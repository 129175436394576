<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          form="submit-form"
          id="submit-form"
          ref="submit-form"
          v-model="isFormValid"
          @submit.prevent="guardarFarmacia()"
        >
          <v-row v-if="!farmaciaSearched" :no-gutters="isMobile">
            <v-col :cols="isMobile ? 12 : 4" :sm="isMobile ? 12 : 5">
              <v-text-field
                v-model="cuit"
                label="CUIT"
                variant="outlined"
                density="compact"
                v-mask="['##-########-#', '##-#######-#']"
                :rules="
                  cuit == null
                    ? rules.required
                    : rules.required.concat(rules.validateCUITCUIL(cuit))
                "
                id="cuit-text-field"
                @update:model-value="setUserName(cuit)"
              ></v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 3" :md="isMobile ? 12 : 6">
              <v-btn
                color="primary"
                variant="flat"
                @click="searchFarmacia()"
                :disabled="cuit == null || cuit == '' || !isFormValid"
              >
                Buscar
              </v-btn>
              <v-tooltip location="right">
                <template v-slot:activator="{ props }">
                  <v-icon color="primary" size="25" class="pl-5" v-bind="props">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>Buscar farmacia en el sistema</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <div v-if="farmaciaSearched">
            <v-row :no-gutters="isMobile">
              <v-col cols="12" class="pb-0">
                <v-card elevation="1">
                  <v-card-title class="text-button">
                    Datos de farmacia
                  </v-card-title>
                  <v-card-text>
                    <v-row :no-gutters="isMobile">
                      <v-col cols="12" class="pb-0">
                        <v-text-field
                          v-model="nombre"
                          label="Nombre comercial"
                          variant="outlined"
                          density="compact"
                          :rules="
                            [rules.required]
                              .concat(rules.maxLength(nombre, 50))
                              .flat()
                          "
                          id="name-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                        <v-text-field
                          v-model="razonSocial"
                          variant="outlined"
                          density="compact"
                          label="Razón social"
                          :rules="
                            rules.required.concat(
                              rules.maxLength(razonSocial, 50)
                            )
                          "
                          id="razon-social-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                        <v-text-field
                          v-model="cuit"
                          label="CUIT"
                          readonly
                          variant="outlined"
                          density="compact"
                          v-mask="['##-########-#', '##-#######-#']"
                          :rules="
                            cuit == null
                              ? rules.required
                              : rules.required.concat(
                                  rules.validateCUITCUIL(cuit)
                                )
                          "
                          id="cuit-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                        <v-text-field
                          v-model="telefono"
                          variant="outlined"
                          density="compact"
                          label="Teléfono"
                          v-mask="'###############'"
                          :rules="
                            [rules.required, rules.telephoneRules]
                              .concat(rules.maxLength(telefono, 15))
                              .flat()
                          "
                          id="telephone-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                        <v-select
                          v-model="provinciaSelected"
                          label="Provincia"
                          :items="itemsProvincias"
                          variant="outlined"
                          density="compact"
                          item-title="value"
                          item-value="id"
                          clearable
                          :loading="isProvinciaSelectLoading"
                          id="v-select-ver-provincias"
                        ></v-select>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                        <v-text-field
                          v-model="direccion"
                          label="Dirección"
                          :rules="[rules.maxLength(direccion, 100)]"
                          variant="outlined"
                          clearable
                          density="compact"
                          id="direccion-text-field"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                        <v-textarea
                          v-model="observaciones"
                          label="Observación"
                          variant="outlined"
                          clearable
                          :rules="[rules.maxLength(observaciones, 250)]"
                          density="compact"
                          auto-grow
                          rows="1"
                          id="observacion-text-area"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-card elevation="1">
                  <v-card-title class="text-button"
                    >Datos de usuario</v-card-title
                  >
                  <v-card-text>
                    <v-row :no-gutters="isMobile">
                      <v-col cols="12" :sm="isMobile ? 12 : 6" class="pb-0">
                        <v-text-field
                          readonly
                          label="Nombre de usuario"
                          v-model="usuNom"
                          :rules="rules.required"
                          id="usuario-farmacia-userNom"
                          density="compact"
                          variant="outlined"
                          autocomplete="usuario-farmacia-userNom"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        :sm="isMobile ? 12 : 6"
                        :md="isMobile ? 12 : 6"
                        class="pb-0"
                        v-if="farmaciaNotFound"
                      >
                        <v-text-field
                          type="email"
                          v-model="email"
                          label="Email"
                          variant="outlined"
                          density="compact"
                          :rules="
                            [rules.required, rules.emailRules]
                              .concat(rules.maxLength(email, 100))
                              .flat()
                          "
                          id="email-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-0 px-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="cerrarDialogo"
          id="v-btn-close-edit"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          variant="flat"
          form="submit-form"
          :loading="isLoadingSave"
          :disabled="!isFormValid || !farmaciaSearched"
          id="v-btn-save-edit"
        >
          <template v-if="isLoadingSave">
            <v-progress-circular
              indeterminate
              color="white"
              size="25"
            ></v-progress-circular>
          </template>
          <template v-else> Confirmar </template>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules.js";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index";

export default {
  name: "EditFarmacia",
  props: {
    farmaciaItem: {
      type: Object,
      required: false,
    },
    financiadorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      nombre: "",
      email: "",
      telefono: "",
      provinciaSelected: null,
      itemsProvincias: [],
      direccion: "",
      observaciones: "",
      isProvinciaSelectLoading: false,
      isFormValid: false,
      formTitle: "",
      rules: rules,
      isLoadingSave: false,
      razonSocial: "",
      cuit: "",
      infoIcon: enums.icons.SNB_INFO,
      farmaciaSearched: false,
      farmaciaNotFound: false,
      farmaciaFounded: false,
      usuNom: null,
      farmaciaId: null,
      farmaciaXFinId: null,
      usuarioId: null,
    };
  },
  created() {
    this.setSelects();
    if (this.farmaciaItem != null) {
      this.cuit = this.farmaciaItem.cuit;
      this.searchFarmacia();
      this.formTitle = `Editar farmacia – ${this.farmaciaItem.nombre}`;
    } else {
      this.formTitle = "Nueva farmacia";
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      saveFarmacia: "farmacia/saveFarmacia",
      searchFarmaciaByCuit: "farmacia/searchFarmaciaByCuit",
      getProvincias: "taxonomy/getProvincias",
      setAlert: "user/setAlert",
    }),
    async searchFarmacia() {
      const data = {
        cuit: this.cuit.replaceAll("-", ""),
        financiadorId: this.financiadorId,
        farmaciaId:
          this.farmaciaItem != null ? this.farmaciaItem.farmaciaId : null,
      };
      try {
        const response = await this.searchFarmaciaByCuit(data);

        if (response.status === 200) {
          this.farmaciaSearched = true;
          this.farmaciaFounded = true;
          if (this.farmaciaItem == null) {
            this.setAlert({
              type: "success",
              message:
                "Se ha encontrado una farmacia existente en el sistema con los datos ingresados.",
            });
          }
          this.nombre = response.data.nombre;
          this.razonSocial = response.data.razonSocial;
          this.usuNom = response.data.usuarioNombre;
          this.cuit = response.data.cuit;
          this.telefono = response.data.telefono;
          this.provinciaSelected = response.data.provincia;
          this.direccion = response.data.direccion;
          this.observaciones = response.data.observaciones;
          this.farmaciaId = response.data.farmaciaId;
          this.farmaciaXFinId = response.data.farmaciaXFinId;
          this.usuarioId = response.data.usuarioId;
        } else if (response.status === 204) {
          this.farmaciaSearched = true;
          this.farmaciaNotFound = true;
          this.setAlert({
            type: "info",
            message:
              "No se ha encontrado una farmacia existente en el sistema con los datos ingresados. A continuación cargue los datos de la pantalla para crear la farmacia.",
          });
        } else return;
      } catch (error) {
        console.error("Error", error);
      }
    },
    setUserName(cuit) {
      this.usuNom = cuit.replaceAll("-", "");
    },
    async guardarFarmacia() {
      this.isLoadingSave = true;
      const payload = {
        farmaciaId: this.farmaciaId,
        usuarioId: this.usuarioId,
        usuarioNombre: this.usuNom,
        farmaciaXFinId: this.farmaciaXFinId,
        nombre: this.nombre,
        razonSocial: this.razonSocial,
        cuit: this.cuit.replaceAll("-", ""),
        mail: this.email,
        telefono: this.telefono,
        provincia: this.provinciaSelected,
        direccion: this.direccion,
        observaciones: this.observaciones,
      };
      try {
        const resp = await this.saveFarmacia(payload);
        if (resp.status == 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito.",
          });
          this.cerrarDialogo();
          this.reloadFarmacias();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingSave = false;
    },
    async setProvincias() {
      this.isProvinciaSelectLoading = true;
      this.itemsProvincias = await this.getProvincias();
      this.isProvinciaSelectLoading = false;
    },
    setSelects() {
      this.setProvincias();
    },
    cerrarDialogo() {
      this.$emit("cerrarDialogo");
    },
    reloadFarmacias() {
      this.$emit("reloadFarmacias");
    },
  },
};
</script>

<style></style>
