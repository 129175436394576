<template>
  <v-container class="mobile-container">
    <v-card>
      <v-card-title class="text-h5 mt-2">
        <v-row>
          <v-col class="pb-0">
            Logs {{ isMobile ? "" : "-" }} Prescripción Nº {{ solicitudNumber }}
          </v-col>
          <v-col class="pt-1 pb-0">
            <v-card-actions class="py-0 px-0">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="closeDialog"
                :icon="closeIcon"
                variant="text"
                id="v-btn-close-log-receta"
              >
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card
        class="elevation-1 ma-3"
        :style="isMobile ? 'overflow-y: auto' : ''"
        :max-height="isMobile ? 500 : null"
      >
        <v-card-text class="py-0 my-0">
          <v-row>
            <v-col cols="12" class="mx-0 px-0">
              <v-data-table-virtual
                v-if="isMobile"
                :headers="headersMob"
                :items="logsSolicitud"
                item-value="logSolicitud"
                :mobile="isMobile"
                :hide-default-header="isMobile"
                class="pa-3"
                :loading="isLoading"
                @update:items-per-page="updateItemsPerPage"
                :height="heightDataTable"
              >
                <template v-slot:[`item.actions`]="{ item }" v-if="esAdmin">
                  <v-tooltip text="Ver datos de envío" location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        size="20"
                        v-bind="props"
                        id="v-icon-ver-datos-envio"
                        @click="openModalVerDatosEnvio(item)"
                      >
                        {{ eyeIcon }}
                      </v-icon>
                    </template>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.logObservacion`]="{ item }">
                  <span style="font-size: 9px">
                    {{
                      (item.logObservacion ?? "").length > 0
                        ? item.logObservacion ?? ""
                        : "No posee"
                    }}</span
                  ></template
                >
              </v-data-table-virtual>
              <v-data-table
                v-else
                :headers="headers"
                :items="logsSolicitud"
                item-value="logSolicitud"
                class="pa-3"
                :loading="isLoading"
                @update:items-per-page="updateItemsPerPage"
                :height="heightDataTable"
              >
                <template v-slot:[`item.actions`]="{ item }" v-if="esAdmin">
                  <v-tooltip text="Ver datos de envío" location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        size="20"
                        v-bind="props"
                        id="v-icon-ver-datos-envio"
                        @click="openModalVerDatosEnvio(item)"
                      >
                        {{ eyeIcon }}
                      </v-icon>
                    </template>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                  <tr>
                    <td :colspan="columns.length">
                      Observaciones: {{ item.logObservacion }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-dialog
      v-if="dialogVerDatosEnvio && solicitudId"
      v-model="dialogVerDatosEnvio"
      max-width="70%"
      persistent
    >
      <VerDatosEnvio
        :logSolicitudId="solicitudItem"
        @anular-cancelado="closeVerDatosEnvio"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VerDatosEnvio from "@/components/modules/solicitudes/VerDatosEnvio.vue";
import enums from "@/utils/enums/index";

export default {
  components: {
    VerDatosEnvio,
  },
  props: {
    solicitudId: {
      type: Number,
      required: false,
    },
    esAdmin: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      eyeIcon: enums.icons.OPEN_EYE_ICON,
      logsSolicitud: [],
      solicitudNumber: "",
      isLoading: false,
      closeIcon: enums.icons.CLOSE_ICON,
      dialogVerDatosEnvio: false,
      headers: [
        {
          text: "",
          width: "2%",
          value: "data-table-expand",
          sortable: false,
        },
        {
          title: "Fecha",
          sortable: false,
          key: "logFecha",
        },
        {
          title: "Respuesta",
          sortable: false,
          key: "logResponse",
        },
        {
          title: "Servicio",
          sortable: false,
          key: "servicioNom",
        },
        {
          title: "Usuario",
          sortable: false,
          key: "logUsuarioNom",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
      headersMob: [
        {
          title: "Observaciones",
          //width: "2%",
          //value: "data-table-expand",
          key: "logObservacion",
          sortable: false,
        },
        {
          title: "Fecha",
          sortable: false,
          key: "logFecha",
        },
        {
          title: "Respuesta",
          sortable: false,
          key: "logResponse",
        },
        {
          title: "Servicio",
          sortable: false,
          key: "servicioNom",
        },
        {
          title: "Usuario",
          sortable: false,
          key: "logUsuarioNom",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
      heightDataTable: "",
    };
  },
  created() {
    this.loadVerLogSolicitud();
  },
  computed: {
    ...mapGetters({ isMobile: "menu/isMobile" }),
  },
  methods: {
    ...mapActions({
      getLogSolicitud: "solicitud/getLogSolicitud",
      setAlert: "user/setAlert",
    }),
    updateItemsPerPage(value) {
      if ((value != null && value > 10) || value < 1)
        this.heightDataTable = "450";
      else this.heightDataTable = "";
    },
    async loadVerLogSolicitud() {
      this.isLoading = true;
      const solicitudId = this.solicitudId;
      try {
        const response = await this.getLogSolicitud(solicitudId);
        this.logsSolicitud = response;
        this.solicitudNumber = this.logsSolicitud[0].solicitudHash;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    openModalVerDatosEnvio(id) {
      this.dialogVerDatosEnvio = true;
      this.solicitudItem = id.logSolicitud;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    closeVerDatosEnvio() {
      this.dialogVerDatosEnvio = false;
    },
  },
};
</script>
