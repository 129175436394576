<template>
  <v-container fluid class="pa-0">
    <v-row fill-height class="row">
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        class="common"
        :style="{ backgroundColor: $vuetify.theme.themes.light.colors.primary }"
      >
        <v-icon class="logo">
          <img
            src="/favicon.ico"
            :alt="enums.titles.PRODUCT"
            class="custom-logo"
          />
        </v-icon>
        <p class="pt-10 left-column" style="font-weight: bold; font-size: 20px">
          {{ titleLogin }}
        </p>
        <p class="left-column">{{ descLogin }}</p>
        <v-expand-transition>
          <v-container v-if="manualUsuario">
            <div
              v-if="textoManualUsuario"
              style="max-height: 450px; overflow-y: auto"
            >
              <TextEditor
                v-if="manualUsuario"
                v-model="textoManualUsuario"
                :canUseEditor="false"
              />
            </div>
          </v-container>
        </v-expand-transition>
      </v-col>
      <!-- Campos para ingresar al sistema -->
      <v-col cols="12" sm="6" md="6" class="common">
        <v-card
          outlined
          flat
          class="logIn-card common"
          v-if="loginFields && !userTokenRequest && !userGenerateQR"
        >
          <v-icon v-if="!isMobile" class="my-6">
            <img
              src="/favicon.ico"
              :alt="enums.titles.PRODUCT"
              class="custom-icon"
            />
          </v-icon>
          <p v-if="!isMobile">{{ titleLogin }}</p>
          <h3 class="pb-8">Iniciar sesión</h3>
          <v-form ref="form" v-model="valid" @submit.prevent="getIn">
            <v-row>
              <v-col cols="12" class="logIn-input" sm="12" md="12">
                <v-text-field
                  variant="outlined"
                  label="Usuario"
                  id="username-v-text-field"
                  autocomplete="username"
                  tabindex="1"
                  v-model="userNameLocal"
                  :rules="rules.required"
                  class="label-align"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="logIn-input" sm="12" md="12">
                <v-text-field
                  variant="outlined"
                  label="Contraseña"
                  tabindex="2"
                  v-model="userPassword"
                  id="password-v-text-field"
                  autocomplete="current-password"
                  @keydown.tab="
                    focusComponent(
                      valid ? 'entrar-v-btn' : 'v-icon-showPassword',
                      1
                    )
                  "
                  :type="showPassword ? 'text' : 'password'"
                  :rules="rules.required"
                  class="label-align"
                >
                  <v-icon
                    class="eye-icon"
                    id="v-icon-showPassword"
                    tabindex="3"
                    @click="showPassword = !showPassword"
                  >
                    {{ showPassword ? openEyeIcon : closeEyeIcon }}
                  </v-icon></v-text-field
                >
              </v-col>
            </v-row>
            <v-row>
              <v-scroll-y-transition>
                <v-alert
                  v-if="showAlert"
                  :color="'error'"
                  class="centered-alert"
                >
                  {{ alertText }}
                </v-alert>
              </v-scroll-y-transition>
            </v-row>
            <v-row class="btn-row common">
              <v-col cols="6" sm="6" md="6" class="btn-col">
                <v-btn
                  :disabled="!valid || isLoading"
                  type="Submit"
                  color="primary"
                  tabindex="4"
                  id="entrar-v-btn"
                  class="getIn-btn"
                >
                  <template v-if="isLoading">
                    <v-progress-circular
                      indeterminate
                      color="white"
                      size="25"
                    ></v-progress-circular>
                  </template>
                  <template v-else> Entrar </template>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="py-0">
                <p
                  class="rubic text-primary font-weight-bold title-sign"
                  style="
                    font-size: 0.8em;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  @click="changeFields()"
                >
                  Olvidé mi contraseña
                </p></v-col
              >
            </v-row>
          </v-form>
        </v-card>
        <v-card outlined flat class="logIn-card common" v-if="userTokenRequest">
          <v-icon v-if="!isMobile" class="my-6">
            <img
              src="/token-icon.svg"
              alt="TokenLock"
              class="custom-icon"
              height="80px"
              width="80px"
            />
          </v-icon>
          <h3 class="pb-8">Ingresar código de autenticación</h3>
          <v-form
            v-model="isTokenFormValid"
            ref="token-form"
            id="token-form"
            @submit.prevent="tokenForm()"
          >
            <v-row>
              <v-col cols="12" class="logIn-input" sm="12" md="12">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Token"
                  id="token-v-text-field"
                  v-mask="'######'"
                  v-model="token"
                  :rules="rules.required"
                  class="label-align"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-scroll-y-transition>
                <v-alert
                  v-if="tokenError"
                  :color="'error'"
                  class="centered-alert"
                >
                  Código incorrecto. Intente nuevamente.
                </v-alert>
              </v-scroll-y-transition>
            </v-row>
            <v-row class="btn-row common">
              <v-col cols="12" sm="12" md="12" class="btn-col">
                <v-btn
                  :disabled="!isTokenFormValid || isLoadingTokenBtn"
                  type="submit"
                  color="primary"
                  id="token-v-btn"
                  class="getIn-btn"
                >
                  <template v-if="isLoadingTokenBtn">
                    <v-progress-circular
                      indeterminate
                      color="white"
                      size="25"
                    ></v-progress-circular>
                  </template>
                  <template v-else> Confirmar </template>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="py-0">
                <p
                  class="rubic text-primary font-weight-bold title-sign"
                  style="
                    font-size: 0.8em;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  @click="changeFields()"
                >
                  Volver al inicio de sesión
                </p></v-col
              >
            </v-row>
          </v-form>
        </v-card>
        <v-card outlined flat class="logIn-card common" v-if="userGenerateQR">
          <h3 class="pb-5">
            {{
              appSelected == null
                ? "Elegir aplicación generadora de QR"
                : "Escanear QR en la aplicación de " + appSelected
            }}
          </h3>
          <v-row v-if="appSelected == null">
            <v-col cols="12" class="pb-0"
              ><v-btn
                color="blue-grey"
                dark
                rounded
                size="small"
                @click="selectApp('Microsoft')"
              >
                Microsoft Authenticator</v-btn
              ></v-col
            >
            <v-col cols="12"
              ><v-btn
                color="blue-grey"
                dark
                rounded
                size="small"
                @click="selectApp('Google')"
              >
                Google Authenticator</v-btn
              ></v-col
            ></v-row
          >
          <v-row v-if="appSelected != null">
            <v-col cols="12" class="py-0">
              <img :src="qrCode" alt="qrCode" height="200px" width="200px" />
            </v-col>
          </v-row>
          <v-row class="btn-row common" v-if="appSelected != null">
            <v-col cols="12" sm="12" md="12" class="btn-col">
              <v-btn
                @click="goToAuthenticateCode()"
                color="primary"
                class="getIn-btn"
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="btn-row common pb-0">
            <v-col cols="12" sm="12" md="12" class="pb-0">
              <v-btn
                color="dark-grey"
                dark
                rounded
                size="small"
                @click="manualUsuario ? (manualUsuario = null, textoManualUsuario = '') : setManualDeUsuario()"
              >
                {{manualUsuario ? 'Cerrar Ayuda Inicio de sesión' : 'Ayuda Inicio de sesión'}}</v-btn
              ></v-col
            >
          </v-row>
          <v-row class="btn-row common pb-0">
            <v-col cols="12" sm="12" md="12" class="pb-0">
              <p
                class="rubic text-primary font-weight-bold title-sign"
                style="
                  font-size: 0.8em;
                  text-align: center;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="changeFields()"
              >
                Volver al inicio de sesión
              </p></v-col
            >
          </v-row>
        </v-card>
        <v-card
          outlined
          flat
          class="logIn-card common"
          v-if="forgotedPasswordFields"
        >
          <v-icon v-if="!isMobile" class="my-6">
            <img src="/favicon.ico" class="custom-icon" />
          </v-icon>
          <p v-if="!isMobile">{{ titleLogin }}</p>
          <h3 class="pb-8">Restablecer contraseña</h3>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="restorePassword()"
          >
            <v-row>
              <v-col cols="12" class="forgottedPassword-input" sm="12" md="12">
                <v-select
                  variant="outlined"
                  density="compact"
                  label="Tipo usuario"
                  id="tipo-usuario-v-select"
                  item-title="value"
                  item-value="id"
                  v-model="tipoUsuarioSelected"
                  :items="tiposUsuario"
                  @update:model-value="matricula = null"
                  :rules="rules.required"
                  class="label-align"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                class="forgottedPassword-input"
                sm="12"
                md="12"
                v-if="tipoUsuarioSelected === 3"
              >
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Matrícula"
                  id="matricula-v-text-field"
                  v-model="matricula"
                  :rules="rules.required"
                  class="label-align"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="forgottedPassword-input" sm="12" md="12">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="CUIT"
                  v-model="cuil"
                  id="cuil-v-text-field"
                  v-mask="['##-########-#', '##-#######-#']"
                  :rules="
                    cuil == null
                      ? rules.required
                      : rules.required.concat(rules.validateCUITCUIL(cuil))
                  "
                  class="label-align"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="forgottedPassword-input" sm="12" md="12">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Email"
                  v-model="email"
                  id="email-v-text-field"
                  :rules="rules.required.concat(rules.emailRules)"
                  class="label-align"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-scroll-y-transition>
                <v-alert
                  v-if="showAlertRestorePassword"
                  :color="'error'"
                  class="centered-alert"
                >
                  {{ alertRestorePasswordText }}
                </v-alert>
              </v-scroll-y-transition>
            </v-row>
            <v-row class="btn-row common">
              <v-col cols="6" sm="6" md="6" class="btn-col">
                <v-btn
                  :disabled="!valid || isLoading"
                  type="Submit"
                  color="primary"
                  id="entrar-v-btn"
                  class="getIn-btn"
                >
                  <template v-if="isLoading">
                    <v-progress-circular
                      indeterminate
                      color="white"
                      size="25"
                    ></v-progress-circular>
                  </template>
                  <template v-else> Confirmar </template>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="py-0">
                <p
                  class="rubic text-primary font-weight-bold title-sign"
                  style="
                    font-size: 0.8em;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  @click="changeFields()"
                >
                  Volver al inicio de sesión
                </p></v-col
              >
            </v-row>
          </v-form>
        </v-card>
        <p class="mt-4">
          {{ enums.titles.PRODUCT }} un producto de
          <a href="https://www.macena.com.ar/" target="_blank" color="primary">
            Macena
          </a>
        </p>
      </v-col>
      <!-- Campos para restrablecer contraseña -->
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index";
import TextEditor from "@/components/shared/TextEditor.vue";
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";
import { focusComponent } from "@/utils/helpers/htmlHelpers";

export default {
  name: "LogIn-page",
  components: {
    TextEditor,
  },
  data() {
    return {
      descLogin: enums.modules.login.messages.DESC_LOGIN,
      titleLogin: enums.titles.PRODUCT,
      manualUsuario: null,
      textoManualUsuario: "",
      valid: false,
      userNameLocal: "",
      enums: enums,
      userPassword: "",
      isLoading: false,
      showPassword: false,
      showAlert: false,
      showAlertRestorePassword: false,
      alertText: enums.modules.login.messages.ERROR_LOGIN,
      alertRestorePasswordText:
        enums.modules.login.messages.ERROR_RESTORE_PASSWORD,
      rules: rules,
      openEyeIcon: enums.icons.OPEN_EYE_ICON,
      closeEyeIcon: enums.icons.CLOSE_EYE_ICON,
      loginFields: true,
      forgotedPasswordFields: false,
      cuil: "",
      email: "",
      matricula: "",
      tiposUsuario: [
        { id: 1, value: "Emisor" },
        { id: 2, value: "Farmacia" },
        { id: 3, value: "Médico" },
      ],
      tipoUsuarioSelected: null,
      isLoadingTokenBtn: false,
      validatedToken: false,
      token: null,
      tokenError: false,
      userGenerateQR: false,
      qrCode: null,
      userTokenRequest: false,
      isTokenFormValid: false,
      appSelected: null,
      userId: null,
    };
  },
  computed: {
    ...mapGetters({
      userName: "login/userName",
      isMobile: "menu/isMobile",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      postSesion: "logIn/postSesion",
      validateAndReturnUser: "logIn/validateAndReturnUser",
      generateUserQR: "logIn/generateUserQR",
      verifyTwoFactorAuthentication: "logIn/verifyTwoFactorAuthentication",
      saveLoginWithTwoFactorAuthentication:
        "logIn/saveLoginWithTwoFactorAuthentication",
      forgotedPassword: "password/forgotedPassword",
      setAlert: "user/setAlert",
      getManualesUsuario: "manualUsuario/getManualUsuarioLogin",
    }),
    setUserName(userNameLocal) {
      this.$store.commit("logIn/setUserName", userNameLocal);
    },
    focusComponent(refName, timeout) {
      focusComponent(refName, timeout);
    },
    async tokenForm() {
      this.isLoadingTokenBtn = true;
      try {
        const request = {
          userId: this.userId,
          code: this.token,
        };
        const response = await this.verifyTwoFactorAuthentication(request);
        if (response) {
          // Si ingresa el token correctamente seteo en true la bandera de validación y lo hago pasar por el método de login
          this.validatedToken = true;
          this.getIn();
        } else {
          // Si el token es incorrecto lanzo la alerta y no lo dejo ingresar
          this.isLoadingTokenBtn = false;
          this.tokenError = true;
        }
      } catch (error) {
        this.isLoadingTokenBtn = false;
      }
    },
    async getIn() {
      this.isLoading = true;
      this.setUserName(this.userNameLocal);
      try {
        const validateUser = await this.validateAndReturnUser({
          userName: this.userNameLocal,
          password: this.userPassword,
        });
        if (validateUser?.status === 200) {
          this.userId = validateUser.data.usuarioId;
          if (!this.validatedToken) {
            if (validateUser.data.tokenRequest == "None") {
              const response = await this.postSesion({
                userName: this.userNameLocal,
                userPassword: this.userPassword,
              });
              if (response?.status === 200) {
                sessionStorage.setItem("token", response.data.token);
                sessionStorage.setItem("userName", this.userNameLocal);
                sessionStorage.setItem(
                  "changePassword",
                  response.data.changePassword
                );
                this.$router.push("/");
              }
            }
            if (validateUser.data.tokenRequest == "QR") {
              // Genero el QR para que escanee
              this.userGenerateQR = true;
              const qrCode = await this.generateUserQR(
                validateUser.data.usuarioId
              );
              this.qrCode = qrCode;
            }
            if (validateUser.data.tokenRequest == "QRCode") {
              // QR ya está generado y escaneado por lo que redirijo directamente a que ingrese el código de autenticación
              this.userTokenRequest = true;
            }
          } else {
            const response = await this.postSesion({
              userName: this.userNameLocal,
              userPassword: this.userPassword,
            });
            if (response?.status === 200) {
              sessionStorage.setItem("token", response.data.token);
              sessionStorage.setItem("userName", this.userNameLocal);
              sessionStorage.setItem(
                "changePassword",
                response.data.changePassword
              );
              await this.saveLoginWithTwoFactorAuthentication({
                userId: this.userId,
              });
              this.$router.push("/");
            }
          }
        }
      } catch (error) {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 2000);
        console.error("Error al iniciar sesión:", error);
        this.resetFields();
      }
    },
    selectApp(app) {
      this.appSelected = app;
    },
    goToAuthenticateCode() {
      this.userTokenRequest = true;
      this.userGenerateQR = false;
    },
    resetFields() {
      this.userNameLocal =
        this.cuil =
        this.matricula =
        this.email =
        this.userPassword =
          "";
      this.tipoUsuarioSelected = null;
      this.isLoadingTokenBtn = false;
      this.validatedToken = false;
      this.token = null;
      this.tokenError = false;
      this.userGenerateQR = false;
      this.qrCode = null;
      this.userTokenRequest = false;
      this.isTokenFormValid = false;
      this.appSelected = null;
      this.userId = null;
      this.isLoading = false;
    },
    changeFields() {
      if (this.userTokenRequest || this.userGenerateQR) {
        this.resetFields();
        return;
      } else {
        this.loginFields = !this.loginFields;
        this.forgotedPasswordFields = !this.forgotedPasswordFields;
        this.resetFields();
      }
    },
    async restorePassword() {
      this.isLoading = true;
      const data = {
        matricula: this.matricula,
        cuil: this.cuil.replaceAll("-", ""),
        email: this.email,
        tipoUsuario: this.tipoUsuarioSelected,
      };
      try {
        const response = await this.forgotedPassword(data);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message:
              "La contraseña ha sido restaurada con éxito. Se ha enviado al mail ingresado la nueva contraseña.",
          });
          this.changeFields();
        }
      } catch (error) {
        this.showAlertRestorePassword = true;
        setTimeout(() => {
          this.showAlertRestorePassword = false;
        }, 2000);
        console.error("Error al restaurar contraseña: ", error);
      }
      this.isLoading = false;
    },
    async setManualDeUsuario() {
      let manualUsuarios = await this.getManualesUsuario();
      this.manualUsuario = manualUsuarios?.data[0] ?? null;
      this.textoManualUsuario = this.manualUsuario
        ? this.manualUsuario.manualUsuarioTexto
        : "";
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.custom-icon {
  width: 65px;
  height: 65px;
  filter: grayscale(100%);
}
.custom-logo {
  width: 150px;
  height: 150px;
}
.logo {
  font-size: 90px;
}
.row {
  height: 102vh;
  justify-content: center;
}
.common {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.left-column {
  color: white;
  padding-top: 30px;
}
.logIn-card {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 40px 40px 30px;
}
.logIn-input {
  padding: 0px;
  width: 150px;
  margin-bottom: 10px;
}
.forgottedPassword-input {
  padding: 0px;
  width: 100px;
  margin-bottom: 10px;
}
.btn-row {
  margin-bottom: 2px;
}
.getIn-btn {
  min-width: 110px;
  height: 40px;
  padding: 2px;
}
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.centered-alert {
  width: auto;
  text-align: center;
  padding: 10px;
}

.label-align {
  text-align: left;
}
</style>
