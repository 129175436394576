import axios from "axios";
import getAPIUrl from "@/utils/helpers/getApiUrl";
import router from '../router';
import store from '../store';
import enums from "../utils/enums/index.js";


function cutBusinessException(pString){
  let respuesta = null;
  let indiceDeCorte = pString.indexOf("BusinessException:") !== -1 ? pString.indexOf("BusinessException:") + 19 : -1;
  let indiceDeCorteFinal = pString.indexOf("at GePreSalud");
  if(indiceDeCorte === -1 ||  indiceDeCorteFinal === -1) respuesta = enums.messages.SYSTEM_ERROR;
  else respuesta = pString.slice(indiceDeCorte, indiceDeCorteFinal);
  return respuesta;
}

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = await getAPIUrl();
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    sessionStorage.removeItem('token');
    router.push('/login');
    return;
  }
  if (error.response.status === 403) {
    router.push('/');
    alert(enums.messages.ACCESS_DENIED);
  }
  else if (error.response.status >= 400 && error.response.status < 500) {
    if (Array.isArray(error.response.data.errors) && error.response.data !== null && error.response.data !== 'undefined' && error.response.data !== "") {
      store.commit('user/setAlertMessage', {
        type: 'warning',
        message: Array.isArray(error.response.data.errors) ? error.response.data.errors[0] : error.response.data
      }, { root: true });
    } else {
      store.commit('user/setAlertMessage', {
        type: 'warning',
        message: enums.messages.SYSTEM_ERROR,
      }, { root: true });
    }
  }
  else if (error.response.status >= 500) {
    store.commit('user/setAlertMessage', {
      type: 'error',
      message: cutBusinessException(error.response.data),
    }, { root: true });
  }
  return Promise.reject(error);
});

export default axios;
