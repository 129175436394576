<template>
  <v-container>
    <NavBar :inHome="true" />
    <v-row :class="isMobile ? 'v-row-mobile' : 'v-row-desk'">
      <ul :class="isMobile ? 'ul-mobile' : 'ul-desk'">
        <CardsHome
          v-for="card in cardsFiltered"
          :key="card.code"
          :id="card.code + '-CardsHome'"
          :title="card.title"
          :description="card.description"
          :link="card.link"
          :isLoading="card.isLoading"
        />
      </ul>
    </v-row>
    <TheFooter />
    <LoaderProgress
      v-if="appLoading.status"
      :text="appLoading.text"
      :opaque="appLoading.opaque"
    />
  </v-container>
</template>
<script>
import NavBar from "@/components/layout/NavBar.vue";
import enums from "@/utils/enums/index";
import CardsHome from "@/components/layout/CardsHome.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import { mapGetters } from "vuex";
import LoaderProgress from "@/components/layout/LoaderProgress.vue";

export default {
  name: "home-page",
  components: {
    NavBar,
    CardsHome,
    TheFooter,
    LoaderProgress,
  },
  data() {
    return {
      cards: [
        {
          title: enums.permises.PRESCRIPCIONES.name,
          description: enums.permises.PRESCRIPCIONES.desc,
          code: enums.permises.PRESCRIPCIONES.code,
          link: enums.permises.PRESCRIPCIONES.path,
          isLoading: false,
        },
        {
          title: enums.permises.FINANCIADORES.name,
          description: enums.permises.FINANCIADORES.desc,
          code: enums.permises.FINANCIADORES.code,
          link: enums.permises.FINANCIADORES.path,
          isLoading: false,
        },
        {
          title: enums.permises.USUARIOS.name,
          description: enums.permises.USUARIOS.desc,
          code: enums.permises.USUARIOS.code,
          link: enums.permises.USUARIOS.path,
          isLoading: false,
        },
        {
          title: enums.permises.PERMISOS.name,
          description: enums.permises.PERMISOS.desc,
          code: enums.permises.PERMISOS.code,
          link: enums.permises.PERMISOS.path,
          isLoading: false,
        },
        {
          title: enums.permises.GRUPOSDEUSUARIOS.name,
          description: enums.permises.GRUPOSDEUSUARIOS.desc,
          code: enums.permises.GRUPOSDEUSUARIOS.code,
          link: enums.permises.GRUPOSDEUSUARIOS.path,
          isLoading: false,
        },
        {
          title: enums.permises.MANUALUSUARIO.name,
          description: enums.permises.MANUALUSUARIO.desc,
          code: enums.permises.MANUALUSUARIO.code,
          link: enums.permises.MANUALUSUARIO.path,
          isLoading: false,
        },
      ],
      cardsFiltered: [],
    };
  },
  computed: {
    ...mapGetters({
      permisosState: "menu/permisos",
      appLoading: "user/appLoading",
      isMobile: "menu/isMobile",
    }),
  },
  watch: {
    permisosState() {
      this.loadCards();
    },
  },
  methods: {
    async loadCards() {
      const permisos = await this.permisosState?.map((p) => p.permisoCodigo);
      this.cardsFiltered = this.cards.filter((x) => permisos?.includes(x.code));
      // if (this.cardsFiltered.length > 0 || permisos?.length <= 0 ) {
      //   this.$store.dispatch("user/changeAppLoading", {
      //     status: false,
      //     text: "",
      //   });
      // }
    },
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: true,
      text: "",
    });
    setTimeout(() => {
      this.loadCards();
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.ul-desk {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ul-mobile {
  display: flex;
  flex-wrap: wrap;
}
.v-row-desk {
  margin: 60px 80px 40px;
  display: block;
  min-height: 65vh;
}
.v-row-mobile {
  display: block;
  min-height: 65vh;
}
.v-container {
  width: 100%;
  margin: 0px;
  padding: 0px;
  max-width: none;
}
</style>
