import logIn from "./modules/LogIn/index.js";
import menu from "./modules/Menu/index.js";
import user from "./modules/User/index.js";
import taxonomy from "./modules/Taxonomy/index.js";
import emisor from "./modules/Emisores/index.js";
import farmacia from "./modules/Farmacias/index.js";
import permisos from "./modules/Permisos/index.js";
import financiador from "./modules/Financiadores/index.js";
import password from "./modules/ChangePassword/index.js";
import solicitud from "./modules/Solicitudes/index.js";
import gruposDeUsuarios from "./modules/GruposDeUsuarios/index.js";
import usuariosEmisores from "./modules/UsuariosEmisores/index.js";
import manualUsuario from "./modules/ManualUsuario/index.js";
import { createStore } from "vuex";

const store = createStore({
  modules: {
    logIn,
    menu,
    user,
    taxonomy,
    emisor,
    farmacia,
    permisos,
    financiador,
    password,
    solicitud,
    gruposDeUsuarios,
    usuariosEmisores,
    manualUsuario
  },
});

export default store;
