<template>
  <v-container class="py-0 my-0">
    <PageHeader :title="pageTitle" :path="goToPath" />
    <v-card elevation="1">
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="search"
              :append-icon="searchIcon"
              label="Buscar"
              single-line
              variant="underlined"
              hide-details
              clearable
              id="search-bar"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            class="d-flex justify-end"
            :class="isMobile ? 'mt-4' : 'mt-2'"
          >
            <v-btn
              color="primary"
              variant="flat"
              @click="openEditDialog()"
              id="v-btn-nuevo"
              v-if="permisosFarmacias.canCreate"
              >Nuevo</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-card-text class="py-0 my-0">
        <v-row>
          <v-col cols="12" class="px-0 mx-0">
            <v-data-table
              :items-per-page="itemsPerPage"
              item-value="farmaciaId"
              :headers="isMobile ? headersMobile : headers"
              :mobile="isMobile"
              :hide-default-header="isMobile"
              class="pa-3"
              :items="farmacias"
              :search="search"
              :loading="isDataTableLoading"
            >
              <template v-slot:[`item.deshabilitado`]="{ item }">
                <v-icon small :color="item.deshabilitado ? 'red' : 'green'">
                  {{ item.deshabilitado ? crossIcon : checkIcon }}
                </v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      id="v-icon-edit-state"
                      @click="changeUserStatus(item)"
                    >
                      {{
                        item.deshabilitado ? enableUserIcon : disableUserIcon
                      }}
                    </v-icon>
                  </template>
                  <span>{{
                    item.deshabilitado
                      ? "Habilitar usuario"
                      : "Deshabilitar usuario"
                  }}</span>
                </v-tooltip>
                <v-tooltip
                  text="Editar farmacia"
                  location="start"
                  v-if="permisosFarmacias.canEdit"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openEditDialog(item)"
                      v-bind="props"
                      id="v-icon-edit-farmacia"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip
                  text="Eliminar farmacia"
                  location="start"
                  v-if="permisosFarmacias.canDelete"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openDeleteDialog(item)"
                      v-bind="props"
                      id="v-icon-delete-farmacia"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    Observación: {{ item.observaciones }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      :max-width="isMobile ? '95%' : '50%'"
      v-model="dialogFarmacia"
      persistent
    >
      <EditFarmacia
        :farmaciaItem="farmaciaItem"
        :financiadorId="financiadorId"
        @cerrarDialogo="closeEditDialog"
        @reloadFarmacias="loadFarmacias"
      />
    </v-dialog>
    <v-dialog
      :max-width="isMobile ? '95%' : '30%'"
      v-model="dialogDelete"
      persistent
    >
      <DeleteDialog
        v-if="dialogDelete"
        title="farmacia"
        :isLoading="isLoadingDelete"
        :subtitle="deleteSubtitle"
        @eliminar-confirmado="borrarFarmacia"
        @eliminar-cancelado="closeDeleteDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import EditFarmacia from "@/components/modules/farmacias/EditFarmacia.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "GestionFarmacias",
  components: {
    EditFarmacia,
    PageHeader,
    DeleteDialog,
  },
  data() {
    return {
      deleteSubtitle: enums.messages.FARMACIAS_DELETE_SUBTITLE,
      itemsPerPage: 10,
      financiadorId: null,
      search: "",
      checkIcon: enums.icons.CHECK_ICON,
      crossIcon: enums.icons.CROSS_ICON,
      disableUserIcon: enums.icons.DISABLE_USER_ICON,
      enableUserIcon: enums.icons.ENABLE_USER_ICON,
      headers: [
        {
          text: "",
          width: "2%",
          value: "data-table-expand",
          sortable: false,
        },
        {
          title: "Nombre comercial",
          sortable: false,
          key: "nombre",
        },
        {
          title: "Razón social",
          sortable: false,
          key: "razonSocial",
        },
        {
          title: "CUIT",
          sortable: false,
          key: "cuit",
        },
        {
          title: "Email",
          sortable: false,
          key: "mail",
        },
        {
          title: "Teléfono",
          sortable: false,
          key: "telefono",
        },
        {
          title: "Habilitado",
          sortable: false,
          key: "deshabilitado",
          align: "center",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
      ],
      headersMobile: [
        
        {
          title: "Nombre comercial",
          sortable: false,
          key: "nombre",
        },
        {
          title: "Razón social",
          sortable: false,
          key: "razonSocial",
        },
        {
          title: "CUIT",
          sortable: false,
          key: "cuit",
        },
        {
          title: "Email",
          sortable: false,
          key: "mail",
        },
        {
          title: "Teléfono",
          sortable: false,
          key: "telefono",
        },
        {
          title: "Habilitado",
          sortable: false,
          key: "deshabilitado",
          align: "center",
        },
        {
          title: "Acciones",
          align: "end",
          sortable: false,
          key: "actions",
        },
        {
          text: "",
          width: "2%",
          value: "data-table-expand",
          sortable: false,
        },
      ],
      farmacias: [],
      farmaciaItem: null,
      itemToDelete: null,
      dialogFarmacia: false,
      dialogDelete: false,
      isDataTableLoading: false,
      isLoadingDelete: false,
      pageTitle: enums.permises.GESTION_FARMACIAS.name,
      goToPath: enums.permises.FINANCIADORES.path,
      searchIcon: enums.icons.SEARCH_ICON,
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
    };
  },
  computed: {
    ...mapGetters({
      getFinanciadorId: "financiador/getFinanciadorId",
      permisos: "menu/permisos",
      isMobile: "menu/isMobile",
    }),
    permisosFarmacias() {
      let canCreate = false;
      let canEdit = false;
      let canDelete = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.CREAR_FARMACIA.code:
              canCreate = true;
              break;
            case enums.permises.EDITAR_FARMACIA.code:
              canEdit = true;
              break;
            case enums.permises.ELIMINAR_FARMACIA.code:
              canDelete = true;
              break;
            default:
              break;
          }
        });
      }
      return { canCreate, canEdit, canDelete };
    },
  },
  created() {
    this.financiadorId = this.getFinanciadorId; // Asigno el dato con el financiador cargado en el state
    this.loadFarmacias();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
  },
  methods: {
    ...mapActions({
      getFarmacias: "farmacia/getFarmacias",
      deleteFarmacia: "farmacia/deleteFarmacia",
      changeUserEnabledStatus: "farmacia/changeUserEnabledStatus",
      setAlert: "user/setAlert",
    }),
    sendHome() {
      this.$router.push("/");
    },
    openEditDialog(item) {
      this.dialogFarmacia = true;
      this.farmaciaItem = item;
    },
    closeEditDialog() {
      this.dialogFarmacia = false;
    },
    openDeleteDialog(item) {
      this.dialogDelete = true;
      this.itemToDelete = item;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    async loadFarmacias() {
      this.isDataTableLoading = true;
      try {
        const response = await this.getFarmacias(this.financiadorId);
        this.farmacias = response;
      } catch (error) {
        console.error(error);
      }
      this.isDataTableLoading = false;
    },
    async borrarFarmacia() {
      this.isLoadingDelete = true;
      try {
        const data = {
          farmaciaId: this.itemToDelete.farmaciaId,
          farmaciaXFinId: this.itemToDelete.farmaciaXFinId,
          usuarioId: this.itemToDelete.usuarioId,
        };
        const resp = await this.deleteFarmacia(data);
        if (resp?.status == 200) {
          this.setAlert({
            type: "success",
            message: "Farmacia eliminada con éxito.",
          });
          this.loadFarmacias();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
      this.dialogDelete = false;
    },
    async changeUserStatus(item) {
      try {
        const response = await this.changeUserEnabledStatus(
          item.farmaciaXFinId
        );
        if (response)
          this.setAlert({
            type: "success",
            message: `Usuario ${
              item.deshabilitado ? "habilitado" : "deshabilitado"
            } con éxito.`,
          });
        this.loadFarmacias();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
