<template>
  <v-card>
    <v-container class="pa-0">
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form v-model="isFormValid" ref="form" @submit.prevent="save()">
          <v-container>
            <v-row v-if="!prestadorSearched">
              <v-col
                :cols="isMobile ? '12' : '3'"
                :sm="isMobile ? 12 : 4"
                class="py-0"
              >
                <v-text-field
                  label="CUIT"
                  v-model="cuitBusqueda"
                  density="compact"
                  variant="outlined"
                  v-mask="['##-#######-#', '##-########-#']"
                  :rules="
                    cuitBusqueda !== null &&
                    cuitBusqueda !== undefined &&
                    cuitBusqueda !== ''
                      ? rules.required.concat(
                          rules.validateCUITCUIL(cuitBusqueda)
                        )
                      : []
                  "
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 3"
                :md="isMobile ? 12 : 6"
                class="py-0"
              >
                <v-btn
                  color="primary"
                  variant="flat"
                  @click="searchPrestador()"
                  :disabled="cuitBusqueda == null || cuitBusqueda == ''"
                >
                  Buscar
                </v-btn>
                <v-tooltip location="right">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      color="primary"
                      size="25"
                      class="pl-5"
                      v-bind="props"
                    >
                      {{ infoIcon }}
                    </v-icon>
                  </template>
                  <span>Buscar usuario en el sistema</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <div v-if="prestadorSearched">
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card elevation="1">
                    <v-card-title class="text-button pt-0">
                      Datos personales
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" :sm="isMobile ? 12 : 6" class="pb-0">
                          <v-text-field
                            label="Apellido/s y nombre/s"
                            v-model="apeNom"
                            density="compact"
                            variant="outlined"
                            :rules="
                              rules.required.concat([
                                rules.maxLength(apeNom, 60),
                              ])
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col
                          :cols="isMobile ? 12 : 3"
                          :sm="isMobile ? 12 : 4"
                          class="pb-0"
                        >
                          <v-select
                            :items="tiposDoc"
                            label="Tipo documento"
                            v-model="tipoDocSelected"
                            :rules="rules.required"
                            density="compact"
                            variant="outlined"
                            return-object
                            item-value="id"
                            item-title="value1"
                            clearable
                          ></v-select>
                        </v-col>
                        <v-col
                          :cols="isMobile ? 12 : 3"
                          :sm="isMobile ? 12 : 5"
                          class="pb-0"
                        >
                          <v-text-field
                            label="N° documento"
                            v-model="nroDoc"
                            density="compact"
                            variant="outlined"
                            v-mask="['#.###.###', '##.###.###', '########']"
                            :rules="
                              nroDoc !== null &&
                              nroDoc !== undefined &&
                              prestadorSearched
                                ? rules.required.concat([
                                    rules.minLength(
                                      nroDoc.replace('/./g', ''),
                                      7
                                    ),
                                  ])
                                : []
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" :sm="isMobile ? 12 : 3" class="pb-0">
                          <v-text-field
                            label="CUIT/CUIL"
                            v-model="cuil"
                            density="compact"
                            variant="outlined"
                            readonly
                            v-mask="['##-########-#', '##-#######-#']"
                            :rules="
                              cuil == null
                                ? rules.required
                                : rules.required.concat(
                                    rules.validateCUITCUIL(cuil)
                                  )
                            "
                            @update:model-value="setUserName(cuil)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card elevation="1">
                    <v-card-title class="text-button"
                      >Datos del prestador</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col :cols="isMobile ? 12 : 5" class="pb-0">
                          <v-autocomplete
                            v-model="tipoPrestadorSelected"
                            label="Tipo prestador"
                            :items="tiposPrestador"
                            item-title="value"
                            item-value="id"
                            density="compact"
                            variant="outlined"
                            :rules="rules.required"
                            @update:model-value="
                              setProvincias(provinciaSelected?.id ?? null)
                            "
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col :cols="isMobile ? 12 : 3" class="pb-0">
                          <v-text-field
                            label="Matrícula"
                            v-model="matriculaProv"
                            density="compact"
                            v-mask="'#########'"
                            variant="outlined"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="isMobile ? 12 : 4" class="pb-0">
                          <v-select
                            v-model="provinciaSelected"
                            label="Provincia"
                            variant="outlined"
                            density="compact"
                            :items="provincias"
                            item-title="value"
                            item-value="id"
                            :loading="isProvinciaSelectLoading"
                            :model-value="provinciaSelected"
                            :rules="rules.required"
                            clearable
                            id="v-select-ver-provincias"
                            return-object
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card elevation="1">
                    <v-card-title class="text-button"
                      >Datos de usuario</v-card-title
                    >
                    <v-card-text>
                      <v-row :no-gutters="isMobile">
                        <v-col :cols="isMobile ? 12 : 3" class="pb-0">
                          <v-text-field
                            readonly
                            label="Nombre de usuario"
                            v-model="usuNom"
                            :rules="rules.required"
                            id="usuario-emisor-userNom"
                            density="compact"
                            variant="outlined"
                            autocomplete="usuario-emisor-userNom"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          :cols="isMobile ? 12 : 6"
                          class="pb-0"
                          v-if="prestadorNotFound"
                        >
                          <v-text-field
                            label="Email"
                            density="compact"
                            variant="outlined"
                            v-model="correo"
                            :rules="
                              rules.required.concat(
                                [rules.maxLength(apeNom, 100)],
                                rules.emailRules
                              )
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="closeModal(false)">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              :disabled="!isFormValid || !prestadorSearched"
              :loading="isLoading"
              @click="save()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index";

export default {
  name: "EditUsuarioEmisor",
  props: {
    usuarioEmisorItem: {
      type: Object,
      required: false,
    },
    financiadorId: {
      type: Number,
      required: true,
    },
    tiposDocItems: {
      type: Array,
      required: true,
    },
    tiposPrestadorItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formTitle: "",
      cuitBusqueda: "",
      apeNom: null,
      tiposDoc: [],
      tipoDocSelected: null,
      nroDoc: null,
      matriculaProv: null,
      provincias: [],
      provinciaSelected: null,
      usuNom: null,
      telefono: null,
      correo: null,
      rules: rules,
      isFormValid: false,
      isLoading: false,
      showPassword: false,
      isProvinciaSelectLoading: false,
      tiposPrestador: [],
      tipoPrestadorSelected: null,
      infoIcon: enums.icons.SNB_INFO,
      prestadorSearched: false,
      cuil: null,
      prestadorNotFound: false,
      prestadorFounded: false,
      usuarioId: null,
      preId: null,
      preXFinId: null,
    };
  },
  created() {
    this.setSelects();
    if (this.usuarioEmisorItem != null) {
      this.cuitBusqueda = this.usuarioEmisorItem.preCuit.toString();
      this.searchPrestador();
      this.formTitle = "Editar usuario emisor";
    } else {
      this.formTitle = "Nuevo usuario emisor";
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      searchPrestadorByCuit: "usuariosEmisores/searchPrestadorByCuit",
      saveUsuarioEmisor: "usuariosEmisores/saveUsuarioEmisor",
      getProvinciasAll: "taxonomy/getProvincias",
      getProvincias: "taxonomy/getProvinciasTiposPrestadores",
      setAlert: "user/setAlert",
    }),
    setSelects() {
      this.tiposPrestador = this.tiposPrestadorItems;
      this.tiposDoc = this.tiposDocItems;
      this.setProvincias();
    },
    async setProvincias(provId) {
      this.provinciaSelected = null;
      this.isProvinciaSelectLoading = true;
      if (this.tipoPrestadorSelected != null) {
        this.provincias = await this.getProvincias({
          financiadorId: this.financiadorId,
          tipoId: this.tipoPrestadorSelected.id,
        });
      } else this.provincias = await this.getProvinciasAll();
      if (provId) {
        this.provinciaSelected = this.provincias.find((x) => x.id == provId);
      }
      this.isProvinciaSelectLoading = false;
    },
    async searchPrestador() {
      const data = {
        cuit: this.cuitBusqueda.toString().replaceAll("-", ""),
        financiadorId: this.financiadorId,
        preId: this.usuarioEmisorItem != null ? this.usuarioEmisorItem.preId : null
      };
      try {
        const response = await this.searchPrestadorByCuit(data);
        if (response.status === 200) {
          this.prestadorSearched = true;
          this.prestadorFounded = true;
          if (this.usuarioEmisorItem == null) {
            this.setAlert({
              type: "success",
              message:
                "Se ha encontrado un usuario existente en el sistema con los datos ingresados.",
            });
          }
          this.apeNom = response.data.preNomApe;
          this.cuil = response.data.preCuit;
          this.correo = response.data.email;
          this.usuarioId = response.data.usuarioId;
          this.preId = response.data.preId;
          this.usuNom = response.data.usuarioNombre;
          this.preXFinId = response.data.preXFinId;
          this.tipoPrestadorSelected = this.tiposPrestador.find(
            (x) => x.id == response.data.tipoPreId
          );
          this.matriculaProv = response.data.matriculaProv;
          this.tipoDocSelected = this.tiposDocItems.find(
            (x) => x.id === response.data.preTipoDoc.id
          );
          this.nroDoc = response.data.preNroDoc.toString();
          this.setProvincias(response.data.provId);
        } else if (response.status === 204) {
          this.prestadorSearched = true;
          this.prestadorNotFound = true;
          this.cuil = this.cuitBusqueda;
          this.usuNom = this.cuitBusqueda.replaceAll("-", "");
          this.setAlert({
            type: "info",
            message:
              "No se ha encontrado un usuario existente en el sistema con los datos ingresados. A continuación cargue los datos de la pantalla para crear el usuario.",
          });
        } else return;
      } catch (error) {
        console.error("Error", error);
      }
    },
    setUserName(cuil) {
      this.usuNom = cuil.replaceAll("-", "");
    },
    async save() {
      this.isLoading = true;
      const data = {
        usuarioId: this.usuarioId,
        usuarioNombre: this.usuNom,
        email: this.correo,
        financiador: { id: this.financiadorId, value: "" },
        preId: this.preId,
        preNomApe: this.apeNom,
        preTipoDoc: this.tipoDocSelected,
        preNroDoc: this.nroDoc.replaceAll(".", ""),
        preCuit: this.cuil.replaceAll("-", ""),
        preXFinId: this.preXFinId,
        tipoPreId: this.tipoPrestadorSelected?.id,
        matriculaProv: this.matriculaProv,
        provId: this.provinciaSelected?.id,
      };
      try {
        const response = await this.saveUsuarioEmisor(data);
        if (response.status === 200) {
          if (this.usuarioId == null)
            this.setAlert({
              type: "success",
              message:
                "Guardado con éxito. La contraseña del usuario se ha enviado al mail ingresado.",
            });
          else
            this.setAlert({ type: "success", message: "Guardado con éxito." });
        }

        this.closeModal(true);
      } catch (error) {
        console.error("Error al guardar cambios", error);
      } finally {
        this.isLoading = false;
      }
    },
    closeModal(reload) {
      this.$emit("closeDialog", reload);
    },
  },
};
</script>

<style scoped>
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
